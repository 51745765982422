import React from 'react'
import _ from 'lodash'
import strip from 'strip'
import { decode as HtmlEntitiesDecode } from 'html-entities'
import pluralize from 'pluralize'
import { notification } from 'antd'
import {
  APP_TYPE_CODE,
  I18NFormatMessage,
  TEXT_NOTIFICATION_ERROR,
  TEXT_UI_NOTIFICATION,
} from 'config'
import MiscUtils from './MiscUtils'
import URLUtils from './URLUtils'

export default class DisplayUtils {
  static showNotification(message, description, setting) {
    notification.success({ message, description, placement: 'topRight', ...setting })
  }

  static showNotificationError(message, intl, description, setting) {
    // in Case 3 of ErrorHandlerUtils.js
    // if "Network error" (when BE stopped) then we need to slugify and map to the other message
    notification.error({
      message: intl
        ? I18NFormatMessage(
            intl,
            _.get(TEXT_NOTIFICATION_ERROR, MiscUtils.slugify(message), message),
          )
        : _.get(TEXT_NOTIFICATION_ERROR, MiscUtils.slugify(message), message),
      description,
      placement: 'topRight',
      ...setting,
    })
  }

  // using jQuery: https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
  // jquery free: https://stackoverflow.com/questions/16308037/detect-when-elements-within-a-scrollable-div-are-out-of-view
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
  // should have classname: "scroller-to-element"
  static scrollToElement(isMoveToTop = true, elementId = URLUtils.getParamsInURL().scrollTo) {
    // sleep 0.5s before scrolling to element
    setTimeout(() => {
      const options = isMoveToTop
        ? { behavior: 'smooth' } // will scroll until this component on top of page
        : { behavior: 'smooth', block: 'nearest', inline: 'nearest' }
      if (elementId) {
        const element = document.getElementById(elementId)
        element?.scrollIntoView(options)
      }
    }, 100)
  }

  static scrollToTop(callback) {
    this.scrollToElement(true, 'root')
    if (callback) setTimeout(callback, 1000)
  }

  static scrollToBottom() {
    this.scrollToElement(true, 'footerContentId')
  }

  static stripHtmlTag(htmlContent) {
    return strip(htmlContent)
  }

  static decodeHtmlEntities(content) {
    return HtmlEntitiesDecode(content)
  }

  static truncateContinuousSpace(content) {
    return content
      .replace(/&nbsp;+/g, ' ')
      .replace(/\s+/g, ' ')
      .trim()
  }

  static formatHTMLToDisplay(htmlContent) {
    return this.truncateContinuousSpace(this.decodeHtmlEntities(this.stripHtmlTag(htmlContent)))
  }

  // check App.js
  static isMobileOrTablet(breakpoint) {
    return ['mobileLow', 'mobileUp', 'tabletLow', 'tabletUp'].includes(breakpoint)
  }

  static isMobile(breakpoint) {
    return ['mobileLow', 'mobileUp'].includes(breakpoint)
  }

  static isTablet(breakpoint) {
    return ['tabletLow', 'tabletUp'].includes(breakpoint)
  }

  static isDesktopLow(breakpoint) {
    return ['desktopLow', 'desktopUp'].includes(breakpoint)
  }

  static isUnderMaxWidthBody(breakpoint) {
    return [
      'mobileLow',
      'mobileUp',
      'tabletLow',
      'tabletUp',
      'desktopLow',
      'maxWidthBodyLow',
    ].includes(breakpoint)
  }

  static pluralize(num, text) {
    pluralize.addUncountableRule('Trả lời') // locale:vi  -  id:button.reply
    return pluralize(text, num, true)
  }

  static formatToUserProfileUrl({ id, username }) {
    return (
      <a
        href={URLUtils.buildUserProfileURL(id)}
        className="color-black"
        target="_blank"
        rel="noreferrer"
      >
        {username}
      </a>
    )
  }

  static formatNotificationContent(intl, { actionTypeCode, targetTypeCode }) {
    let action = ''
    let obj = ''

    if (actionTypeCode === APP_TYPE_CODE.ACTION_REPLY) action = TEXT_UI_NOTIFICATION.ACTION.REPLY
    else if (actionTypeCode === APP_TYPE_CODE.ACTION_POST) action = TEXT_UI_NOTIFICATION.ACTION.POST
    else if (actionTypeCode === APP_TYPE_CODE.ACTION_LIKE) action = TEXT_UI_NOTIFICATION.ACTION.LIKE
    else if (actionTypeCode === APP_TYPE_CODE.ACTION_DISLIKE)
      action = TEXT_UI_NOTIFICATION.ACTION.DISLIKE
    else if (actionTypeCode === APP_TYPE_CODE.ACTION_NEWS) action = TEXT_UI_NOTIFICATION.ACTION.NEWS

    if (targetTypeCode === APP_TYPE_CODE.NEWS_COMMENT)
      obj = TEXT_UI_NOTIFICATION.OBJECT.YOUR_COMMENT
    else if (targetTypeCode === APP_TYPE_CODE.NEWS_REPLY_TO_COMMENT)
      obj = TEXT_UI_NOTIFICATION.OBJECT.YOUR_REPLY
    else if (targetTypeCode === APP_TYPE_CODE.FORUM_TOPIC)
      obj = TEXT_UI_NOTIFICATION.OBJECT.YOUR_TOPIC
    else if (targetTypeCode === APP_TYPE_CODE.FORUM_POST)
      obj = TEXT_UI_NOTIFICATION.OBJECT.YOUR_POST
    else if (targetTypeCode === APP_TYPE_CODE.NEWS) obj = TEXT_UI_NOTIFICATION.OBJECT.NEWS

    return `${I18NFormatMessage(intl, action)} ${I18NFormatMessage(intl, obj)}`
  }
}
