import React from 'react'
import classnames from 'classnames'
import CountDown from 'ant-design-pro/lib/CountDown'
import { useCurrentBreakpointName } from 'react-socks'
import { DateTimeUtils, DisplayUtils } from 'utils'
import { TEXT_UI_CONTEST } from 'config'
import './style.module.scss'

export default ({ targetTime, isDisabled }) => {
  const isMobileOrTablet = DisplayUtils.isMobileOrTablet(useCurrentBreakpointName())
  return (
    <CountDown
      target={DateTimeUtils.toMiliseconds(targetTime)}
      format={(currentTime) => {
        const seconds = Math.floor((currentTime / 1000) % 60)
        const minutes = Math.floor((currentTime / (1000 * 60)) % 60)
        const hours = Math.floor((currentTime / (1000 * 60 * 60)) % 24)
        const days = Math.floor(currentTime / (1000 * 60 * 60 * 24))

        const secondTxt = `0${seconds.toString()}`.slice(-2)
        const minuteTxt = `0${minutes.toString()}`.slice(-2)
        const hourTxt = `0${hours.toString()}`.slice(-2)
        const dayTxt = days < 100 ? `0${days.toString()}`.slice(-2) : days.toString()
        return (
          <div
            className={classnames('display-flex align-items-baseline', { disabled: isDisabled })}
          >
            <div className="element">
              <div className="typography-h3-regular-01 bold">{dayTxt}</div>
              <div className="text">
                {isMobileOrTablet ? TEXT_UI_CONTEST.DAY_SHORT : TEXT_UI_CONTEST.DAY}
              </div>
            </div>
            <div className="typography-h3-regular-01 bold separator">:</div>
            <div className="element">
              <div className="typography-h3-regular-01 bold">{hourTxt}</div>
              <div className="typography-menu-subtext text">
                {isMobileOrTablet ? TEXT_UI_CONTEST.HOUR_SHORT : TEXT_UI_CONTEST.HOUR}
              </div>
            </div>
            <div className="typography-h3-regular-01 bold separator">:</div>
            <div className="element">
              <div className="typography-h3-regular-01 bold">{minuteTxt}</div>
              <div className="typography-menu-subtext text">
                {isMobileOrTablet ? TEXT_UI_CONTEST.MINUTE_SHORT : TEXT_UI_CONTEST.MINUTE}
              </div>
            </div>
            <div className="typography-h3-regular-01 bold separator">:</div>
            <div className="element">
              <div className="typography-h3-regular-01 bold">{secondTxt}</div>
              <div className="typography-menu-subtext text">
                {isMobileOrTablet ? TEXT_UI_CONTEST.SECOND_SHORT : TEXT_UI_CONTEST.SECOND}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
