import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class NewsService {
  static getNews(search) {
    const url = URLUtils.buildBeURL(BE_ROUTE.NEWS.BASE, null)
    return HttpService.get(url, search)
  }

  static getNewsDetails(newsId) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.DETAIL.replace(':id', newsId),
    )
    return HttpService.get(url)
  }

  static getComments(newsId, search) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.COMMENT.replace(':id', newsId),
    )
    return HttpService.get(url, search)
  }

  static postComment(newsId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.COMMENT.replace(':id', newsId),
    )
    return HttpService.post(url, data)
  }

  static postCommentReply(newsId, commentId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.REPLY_TO_COMMENT.replace(':id', newsId).replace(':commentId', commentId),
    )
    return HttpService.post(url, data)
  }

  static deleteCommentOrReply(newsId, commentId) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.REPLY_TO_COMMENT.replace(':id', newsId).replace(':commentId', commentId),
    )
    return HttpService.delete(url)
  }

  static postCommentLike(newsId, commentId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.LIKE_COMMENT_OR_REPLY.replace(':id', newsId).replace(
        ':commentId',
        commentId,
      ),
    )
    return HttpService.post(url, data)
  }

  static deleteCommentLike(newsId, commentId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NEWS.BASE,
      BE_ROUTE.NEWS.PATH.LIKE_COMMENT_OR_REPLY.replace(':id', newsId).replace(
        ':commentId',
        commentId,
      ),
    )
    return HttpService.delete(url, data)
  }

  static getTagList() {
    const url = URLUtils.buildBeURL(BE_ROUTE.NEWS.BASE, BE_ROUTE.NEWS.PATH.TAGS)
    return HttpService.get(url)
  }
}
