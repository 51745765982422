import React from 'react'
import { Spin } from 'antd'
import styles from './style.module.scss'

export default ({ children, delay = 200, isHidden = true, isFull = true }) => (
  <Spin
    size="large"
    delay={delay}
    // tip="Loading..."
    spinning={!isHidden}
    className={{
      [styles.full]: isFull,
      [styles.hidden]: isHidden,
    }}
  >
    {children}
  </Spin>
)
