import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import $ from 'jquery'
import 'styles/overwrite-richtexteditor.scss'

// alternatives: https://github.com/hdwong/antd-tinymce
// react: https://www.tiny.cloud/docs/integrations/react/
// simple: https://www.tiny.cloud/docs/demo/basic-example/
// full: https://www.tiny.cloud/docs/demo/full-featured/
// quickbar: https://www.tiny.cloud/docs/plugins/opensource/quickbars/#quickbars_insert_toolbar
//            https://stackoverflow.com/questions/67757989/how-do-i-remove-image-and-table-option-from-tinymce-textarea
// wordcount: https://www.tiny.cloud/docs/plugins/opensource/wordcount/
//            custom plugin: https://stackoverflow.com/questions/34481959/tinymce-show-character-count-instead-of-word-count
// language locale: https://www.tiny.cloud/docs/configure/localization/

export default ({ editorRef, value, readOnly, errorMessage, locale }) => {
  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

  return (
    <>
      <Editor
        onInit={(evt, editor) => {
          editorRef.current = editor
        }}
        initialValue={value}
        disabled={readOnly}
        // value={}
        // onEditorChange={this.handleEditorChange}
        // --- simple ----
        // init={{
        //   height: 500,
        //   menubar: true,
        //   plugins: [
        //     'advlist autolink lists link image charmap print preview anchor',
        //     'searchreplace visualblocks code fullscreen',
        //     'insertdatetime media table paste code help wordcount'
        //   ],
        //   toolbar: 'undo redo | formatselect | ' +
        //     'bold italic backcolor | alignleft aligncenter ' +
        //     'alignright alignjustify | bullist numlist outdent indent | ' +
        //     'removeformat | help',
        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        // }}
        init={{
          height: 350,
          plugins:
            'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists imagetools textpattern noneditable help charmap quickbars emoticons wordcount',
          imagetools_cors_hosts: ['picsum.photos'],
          menubar: 'file edit view insert format tools table help',
          toolbar:
            'undo redo | bold italic underline strikethrough wordcount | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          toolbar_sticky: true,
          autosave_ask_before_unload: true,
          autosave_interval: '30s',
          autosave_prefix: '{path}{query}-{id}-',
          autosave_restore_when_empty: false,
          autosave_retention: '2m',
          image_advtab: true,
          link_list: [
            { title: 'My page 1', value: 'https://www.tiny.cloud' },
            { title: 'My page 2', value: 'http://www.moxiecode.com' },
          ],
          image_list: [
            { title: 'My page 1', value: 'https://www.tiny.cloud' },
            { title: 'My page 2', value: 'http://www.moxiecode.com' },
          ],
          image_class_list: [
            { title: 'None', value: '' },
            { title: 'Some class', value: 'class-name' },
          ],
          importcss_append: true,
          // file_picker_callback: function (callback, value, meta) {
          //   /* Provide file and text for the link dialog */
          //   if (meta.filetype === 'file') {
          //     callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
          //   }
          //
          //   /* Provide image and alt text for the image dialog */
          //   if (meta.filetype === 'image') {
          //     callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
          //   }
          //
          //   /* Provide alternative source and posted for the media dialog */
          //   if (meta.filetype === 'media') {
          //     callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
          //   }
          // },
          templates: [
            {
              title: 'New Table',
              description: 'creates a new table',
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
              title: 'Starting my story',
              description: 'A cure for writers block',
              content: 'Once upon a time...',
            },
            {
              title: 'New list with dates',
              description: 'New List with dates',
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
          template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
          image_caption: true,
          quickbars_selection_toolbar:
            'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          noneditable_noneditable_class: 'mceNonEditable',
          toolbar_mode: 'sliding',
          contextmenu: 'link image imagetools table',
          // skin: useDarkMode ? 'oxide-dark' : 'oxide',
          // content_css: useDarkMode ? 'dark' : 'default',
          // https://www.tiny.cloud/docs/configure/content-appearance/#content_css
          content_css: '/tinymce/my-tinymce-styles.css',
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; font-size: 18px; line-height: 32px;} p { margin-block-start: 0; margin-block-end: 0; }",
          font_formats:
            'Arial Black=arial black,avant garde; Courier New=courier new,courier; Roboto=roboto;',
          fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
          formats: {
            // Changes the default format for blockquote to have a class of 'my-custom-blockquote'
            blockquote: { block: 'blockquote', classes: 'custom-blockquote' },
          },
          language: locale, // 'vi',
          // click the wordcount status to change from count words to count character
          init_instance_callback: (editor) => {
            $(editor.getContainer()).find('button.tox-statusbar__wordcount').click() // if you use jQuery
          },
        }}
      />
      <div className="typography-small error-message p-t-10 p-b-10">{errorMessage}</div>
    </>
  )
}
