import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StringUtils } from 'utils'

export const I18NFormatMessage = (intl, data) => {
  try {
    return StringUtils.isString(data)
      ? intl.formatMessage({ id: data })
      : intl.formatMessage({ id: data.id }, data.values)
  } catch (e) {
    return JSON.stringify(data)
  }
}

const TEXT_ERROR_CODE_DICT = {
  EMAIL_UNMATCHED: 'email_unmatched',
  INFORMATION_NOT_FULFIL: 'information_not_fulfil',
}
export const TEXT_ERROR_CODE = TEXT_ERROR_CODE_DICT
export const TEXT_NOTIFICATION_ERROR = {
  'network-error': 'Không thể kết nối ứng dụng',
  username_exist: 'backend.error.validator.usernameExist',
  email_exist: 'backend.error.validator.emailExist',
  email_required: 'backend.error.validator.emailRequired',
  [TEXT_ERROR_CODE_DICT.EMAIL_UNMATCHED]: 'backend.error.validator.emailUnmatched',
  account_not_found: 'backend.error.validator.accountNotFound',
  no_valid_account_found: 'backend.error.validator.noValidAccountFound',
  account_is_inactive: 'backend.error.validator.accountIsInactive',
  account_is_banned: 'backend.error.validator.accountIsBanned',
  account_had_been_activated: 'backend.error.validator.accountHadBeenActivated',
  invalid_login_credentials: 'backend.error.validator.invalidLoginCredentials',
  invalid_old_password: 'backend.error.validator.invalidOldPassword',
  activate_token_expired: 'backend.error.validator.activateTokenExpired',
  activate_token_invalid: 'backend.error.validator.activateTokenInvalid',
  not_human: 'backend.error.validator.notHuman',
  human_checking_failed: 'backend.error.validator.humanCheckingFailed',
}

export const TEXT_NOTIFICATION_SUCCESS = {
  UPDATE_INFO_SUCCESS: 'notification.success.updateInfo',
  EMAIL_SENT_SUCCESS: 'notification.success.emailSent',
  SEND_SUCCESS: 'notification.success.send',
  SAVE_SUCCESS: 'notification.success.save',
}

export const TEXT_VALIDATOR = {
  REQUIRED: 'validator.required',
  INVALID_EMAIL_FORMAT: 'validator.invalidEmailFormat',
  TEXT_AT_LEAST_N_LENGTH: 'validator.textAtLeastNLength',
  TEXT_MAX_N_LENGTH: 'validator.textMaxNLength',
  USERNAME_OR_EMAIL_REQUIRED: 'validator.usernameOrEmailRequired',
  PASSWORD_REQUIRED: 'validator.passwordRequired',
  PASSWORD_UNMATCHED: 'validator.passwordUnmatched',
  PASSWORD_AT_LEAST_1_UPPER_CASE: 'validator.passwordAtLeast1UpperCase',
  PASSWORD_AT_LEAST_1_NUMBER: 'validator.passwordAtLeast1Number',
  PASSWORD_AT_LEAST_1_SPECIAL_CHAR: 'validator.passwordAtLeast1SpecialChar',
  PASSWORD_MAY_NOT_CONTAIN_SEQUENCES: 'validator.passwordMayNotContainSequences',
  TEAM_NAME_REQUIRED: 'Please enter team name',
  NO_SPECIAL_CHAR_ALLOWED: 'validator.noSpecialCharAllowed',
  MUST_START_WITH: 'validator.mustStartWith',
  SVG_PHOTO_BLOCKED: 'validator.svgPhotoBlocked',
  ALPHABET_ONLY: 'validator.alphabetOnly',
  NUMERIC_ONLY: 'validator.numericOnly',
}

export const TEXT_UI_ERROR_PAGE = {
  GO_BACK: <FormattedMessage id="button.goBack" />,
  NOT_FOUND: {
    TITLE: <FormattedMessage id="error.notFound" />,
  },
  FORBIDDEN: {
    TITLE: <FormattedMessage id="error.forbidden" />,
  },
}

export const TEXT_BUTTON = {
  JOIN_NOW: <FormattedMessage id="button.joinNow" />,
  CREATE_TEAM: <FormattedMessage id="button.createTeam" />,
  CANCEL: <FormattedMessage id="button.cancel" />,
  SAVE: <FormattedMessage id="button.save" />,
  EDIT: <FormattedMessage id="button.edit" />,
  DELETE: <FormattedMessage id="button.delete" />,
  OK: 'OK',
  ADD: <FormattedMessage id="button.add" />,
  ADD_MEMBER: <FormattedMessage id="button.addMember" />,
  INVITE: <FormattedMessage id="button.invite" />,
  GET_NEW_PASSWORD: <FormattedMessage id="button.getNewPassword" />,
  RESEND: <FormattedMessage id="button.resend" />,
  PREVIEW: <FormattedMessage id="button.preview" />,
  SAVE_POST: <FormattedMessage id="button.savePost" />,
  COMMENT: <FormattedMessage id="button.comment" />,
  VIEW_MORE: <FormattedMessage id="button.viewMore" />,
  UPLOAD: <FormattedMessage id="button.upload" />,
  LOGIN_TO_COMMENT: <FormattedMessage id="button.loginToComment" />,
  REPLY: <FormattedMessage id="button.reply" />,
  REPORT: <FormattedMessage id="button.report" />,
  EDIT_OR_DELETE_THIS: <FormattedMessage id="button.editOrDeleteThis" />,
  HERE: <FormattedMessage id="button.here" />,
  CONTINUE: <FormattedMessage id="button.continue" />,
  BACK: <FormattedMessage id="button.back" />,
}

export const TEXT_UI_MISC = {
  MODAL: {
    TITLE_DELETE_CONFIRM: <FormattedMessage id="misc.modal.confirmToDelete" />,
  },
  NO_DATA_AVAILABLE: <FormattedMessage id="misc.noDataAvailable" />,
  REQUIRED_INFORMATION: <FormattedMessage id="misc.requiredInformation" />,
  HERE: <FormattedMessage id="misc.here" />,
}

export const TEXT_UI_LAYOUT = {
  HEADER: {
    HOME: <FormattedMessage id="layout.header.home" />,
    LOGIN: <FormattedMessage id="layout.header.login" />,
    LOGOUT: <FormattedMessage id="layout.header.logout" />,
    REGISTER: <FormattedMessage id="layout.header.register" />,
    REGISTER_NOW: <FormattedMessage id="layout.header.register_now" />,
    CONTEST: <FormattedMessage id="layout.header.contest" />,
    TRAINING: <FormattedMessage id="layout.header.training" />,
    NEWS: <FormattedMessage id="layout.header.news" />,
    FORUM: <FormattedMessage id="layout.header.forum" />,
    LEADER_BOARD: <FormattedMessage id="layout.header.ranking" />,
    DROPDOWN_MANAGE_ACCOUNT: <FormattedMessage id="layout.header.manage_account" />,
    DROPDOWN_MY_PROFILE: <FormattedMessage id="layout.header.my_profile" />,
    DROPDOWN_CHANGE_PASSWORD: <FormattedMessage id="layout.header.change_password" />,
    DROPDOWN_MANAGE_TEAM: <FormattedMessage id="layout.header.manage_team" />,
  },
  FOOTER: {
    LINKS: <FormattedMessage id="layout.footer.links" />,
    INFORMATION: <FormattedMessage id="layout.footer.information" />,
    ABOUT_US: <FormattedMessage id="layout.footer.about_us" />,
    TOS: <FormattedMessage id="layout.footer.tos" />,
    PRIVACY: <FormattedMessage id="layout.footer.privacy" />,
    FAQ: <FormattedMessage id="layout.footer.faq" />,
    COPYRIGHT: <FormattedMessage id="layout.footer.copyright" />,
  },
  NOTIFICATION: {
    TITLE: <FormattedMessage id="layout.notification" />,
    NO_DATA: <FormattedMessage id="layout.notification.noData" />,
    ALL: <FormattedMessage id="layout.notification.all" />,
    UNREAD: <FormattedMessage id="layout.notification.unread" />,
  },
}

export const TEXT_UI_AUTH = {
  SOCIAL_LOGIN: <FormattedMessage id="auth.socialSignIn" />,
  REGISTER: {
    REGISTER: <FormattedMessage id="auth.signUp" />,
    ACTIVATE_SUCCESS: <FormattedMessage id="auth.activateSuccess" />,
    VERIFICATION_REQUIRED: <FormattedMessage id="auth.verificationRequired" />,
    VERIFICATION_GUIDELINE: <FormattedMessage id="auth.verificationGuideline" />,
    VERIFICATION_GUIDELINE_RESEND: 'auth.verificationGuidelineResend',
    CREATE_ACCOUNT: <FormattedMessage id="auth.signUp.createAccount" />,
    USERNAME: <FormattedMessage id="auth.username" />,
    EMAIL: <FormattedMessage id="auth.email" />,
    GENDER: <FormattedMessage id="auth.gender" />,
    GENDER_MALE: <FormattedMessage id="auth.gender_male" />,
    GENDER_FEMALE: <FormattedMessage id="auth.gender_female" />,
    GENDER_OTHER: <FormattedMessage id="auth.gender_other" />,
    PASSWORD: <FormattedMessage id="auth.password" />,
    PASSWORD_CONFIRM: <FormattedMessage id="auth.passwordConfirm" />,
    RESEND_EMAIL: <FormattedMessage id="auth.signUp.resendEmail" />,
    SETUP_SOCIAL_ACCOUNT: <FormattedMessage id="auth.signUp.setupSocialAccount" />,
    AGREE_WITH: <FormattedMessage id="auth.signUp.agreeWith" />,
    AGREE_WITH_TITLE: <FormattedMessage id="auth.signUp.agreeWithTitle" />,
  },
  LOGIN: {
    LOGIN: <FormattedMessage id="auth.signIn" />,
    USERNAME_EMAIL: <FormattedMessage id="auth.usernameOrEmail" />,
    PASSWORD: <FormattedMessage id="auth.password" />,
    REMEMBER_ME: <FormattedMessage id="auth.signIn.rememberMe" />,
    REMEMBER_ME_HINT: <FormattedMessage id="auth.signIn.rememberMeHint" />,
    FORGOT_PASSWORD: <FormattedMessage id="auth.signIn.forgotPassword" />,
  },
}

export const TEXT_UI_HOME = {
  BANNER: {
    PLATFORM: <FormattedMessage id="home.banner.platform" />,
    PLATFORM_1: <FormattedMessage id="home.banner.platform_1" />,
    PLATFORM_2: <FormattedMessage id="home.banner.platform_2" />,
    AND: <FormattedMessage id="home.banner.and" />,
    CONNECTION: <FormattedMessage id="home.banner.connection" />,
    CONNECTION_1: <FormattedMessage id="home.banner.connection_1" />,
    CONNECTION_2: <FormattedMessage id="home.banner.connection_2" />,
  },
  INTRODUCTION: {
    TITLE: <FormattedMessage id="home.introduction.title" />,
    DESCRIPTION: <FormattedMessage id="home.introduction.description" />,
  },
  FACT: {
    YEAR: <FormattedMessage id="home.fact.year" />,
    OF_DEVELOPMENT: <FormattedMessage id="home.fact.ofDevelopment" />,
    PARTICIPANT: <FormattedMessage id="home.fact.participant" />,
    CHALLENGE: <FormattedMessage id="home.fact.challenge" />,
  },
  BENEFIT: {
    TITLE: <FormattedMessage id="home.benefit.title" />,
    PRICE: <FormattedMessage id="home.benefit.price" />,
    PRICE_DESCRIPTION: <FormattedMessage id="home.benefit.priceDescription" />,
    EXAM: <FormattedMessage id="home.benefit.exam" />,
    EXAM_DESCRIPTION: <FormattedMessage id="home.benefit.examDescription" />,
    ACTIVITY: <FormattedMessage id="home.benefit.activity" />,
    ACTIVITY_DESCRIPTION: <FormattedMessage id="home.benefit.activityDescription" />,
    SKILL: <FormattedMessage id="home.benefit.skill" />,
    SKILL_DESCRIPTION: <FormattedMessage id="home.benefit.skillDescription" />,
  },
  CONTEST: {
    TITLE: <FormattedMessage id="home.contest.title" />,
  },
  TESTIMONIAL: {
    TITLE: <FormattedMessage id="home.testimonial.title" />,
  },
  PARTNER: {
    TITLE: <FormattedMessage id="home.partner.title" />,
    MEDIA: <FormattedMessage id="home.partner.media" />,
    ORGANIZER: <FormattedMessage id="home.partner.organizer" />,
    PARTNERS: <FormattedMessage id="home.partner.partners" />,
    TECHNICAL: <FormattedMessage id="home.partner.technical" />,
    STRATEGY: <FormattedMessage id="home.partner.strategy" />,
    COMPANION: <FormattedMessage id="home.partner.companion" />,
  },
  PROFILE_MODAL: {
    TITLE: <FormattedMessage id="home.profileModal.title" />,
    CONTENT: <FormattedMessage id="home.profileModal.content" />,
    BUTTON_TEXT: <FormattedMessage id="home.profileModal.buttonText" />,
  },
  TERM_MODAL: {
    TITLE: <FormattedMessage id="home.termModal.title" />,
    CONTENT: <FormattedMessage id="home.termModal.content" />,
  },
}

export const TEXT_UI_LEADER_BOARD = {
  TITLE: <FormattedMessage id="leaderBoard.title" />,
  RANK: <FormattedMessage id="leaderBoard.rank" />,
  USERNAME: <FormattedMessage id="leaderBoard.username" />,
  CONTEST: <FormattedMessage id="leaderBoard.contest" />,
  CONTEST_HINT: <FormattedMessage id="leaderBoard.contestHint" />,
  AVERAGE_SCORE: <FormattedMessage id="leaderBoard.averageScore" />,
  AVERAGE_SCORE_HINT: <FormattedMessage id="leaderBoard.averageScoreHint" />,
  AVERAGE_PENALTY: <FormattedMessage id="leaderBoard.averagePenalty" />,
  AVERAGE_PENALTY_HINT: <FormattedMessage id="leaderBoard.averagePenaltyHint" />,
  TOTAL_SCORE: <FormattedMessage id="leaderBoard.totalScore" />,
  TOTAL_SCORE_HINT: <FormattedMessage id="leaderBoard.totalScoreHint" />,
  TOTAL_PENALTY: <FormattedMessage id="leaderBoard.totalPenalty" />,
  TOTAL_PENALTY_HINT: <FormattedMessage id="leaderBoard.totalPenaltyHint" />,
  ANNUAL: <FormattedMessage id="leaderBoard.annual" />,
  CUMULATIVE: <FormattedMessage id="leaderBoard.cumulative" />,
  LEADERBOARD_GUIDELINE: <FormattedMessage id="leaderBoard.leaderBoardGuideline" />,
  LEADERBOARD_CRITERIA: <FormattedMessage id="leaderBoard.leaderBoardCriteria" />,
}

export const TEXT_UI_USER_PROFILE = {
  MANAGE_ACCOUNT: <FormattedMessage id="userProfile.manageAccount" />,
  FROM: <FormattedMessage id="userProfile.from" />,
  TO: <FormattedMessage id="userProfile.to" />,
  PRESENT: <FormattedMessage id="userProfile.present" />,
  CHANGE_USERNAME: <FormattedMessage id="userProfile.changeUsername" />,
  AVATAR_MODAL: {
    CHANGE: <FormattedMessage id="userProfile.avatar.change" />,
    UPLOADED_PHOTOS: <FormattedMessage id="userProfile.avatar.uploadedPhotos" />,
  },
  PERSONAL_INFORMATION: {
    TITLE: <FormattedMessage id="userProfile.information" />,
    TITLE_SHORT: <FormattedMessage id="userProfile.informationShort" />,
    FULL_NAME: <FormattedMessage id="userProfile.information.fullName" />,
    GENDER: <FormattedMessage id="userProfile.information.gender" />,
    GENDER_MALE: <FormattedMessage id="userProfile.information.gender.male" />,
    GENDER_FEMALE: <FormattedMessage id="userProfile.information.gender.female" />,
    GENDER_OTHER: <FormattedMessage id="userProfile.information.gender.other" />,
    BIRTH_DATE: <FormattedMessage id="userProfile.information.birthDate" />,
    PHONE: <FormattedMessage id="userProfile.information.phone" />,
    EMAIL: <FormattedMessage id="userProfile.information.email" />,
    COUNTRY: <FormattedMessage id="userProfile.information.country" />,
    PROVINCE: <FormattedMessage id="userProfile.information.province" />,
    ADDRESS: <FormattedMessage id="userProfile.information.address" />,
    FACEBOOK: 'Facebook',
    LINKEDIN: 'LinkedIn',
  },
  CHANGE_PASSWORD: {
    TITLE: <FormattedMessage id="userProfile.changePassword" />,
    SUCCESS: <FormattedMessage id="userProfile.changePasswordSuccess" />,
    OLD_PASSWORD: <FormattedMessage id="userProfile.changePassword.oldPassword" />,
    NEW_PASSWORD: <FormattedMessage id="userProfile.changePassword.newPassword" />,
    NEW_PASSWORD_CONFIRM: <FormattedMessage id="userProfile.changePassword.newPasswordConfirm" />,
    GUIDELINE_TITLE: <FormattedMessage id="userProfile.changePassword.guideline.title" />,
    GUIDELINE_1: <FormattedMessage id="userProfile.changePassword.guideline.1" />,
    GUIDELINE_2: <FormattedMessage id="userProfile.changePassword.guideline.2" />,
    GUIDELINE_3: <FormattedMessage id="userProfile.changePassword.guideline.3" />,
  },
  TEAM_MANAGEMENT: {
    TITLE: 'Quản lí đội',
    CREATE_TEAM: 'Thêm đội mới',
    TEAM_NAME: 'Tên đội:',
    TEAM_DESCRIPTION: 'Mô tả về đội của bạn:',
    WAITING: 'Chờ',
    MEMBER: 'Thành viên',
    LEADER: 'Trưởng nhóm',
    ROLE_LEADER: 'LEADER',

    TITLE_ADD_TEAM: 'Thêm đội',
    TITLE_ADD_MEMBER: 'Thêm thành viên',
    TITLE_TEAM_EDIT: 'Chỉnh sửa thông tin đội',
    TITLE_DELETE_TEAM: 'Xóa đội',
    DESCRIPTION_DELETE_TEAM: 'Bạn có chắc muốn xóa đội này?',
    TITLE_DELETE_MEMBER: 'Xóa thành viên',
    DESCRIPTION_DELETE_MEMBER: 'Bạn có chắc muốn xóa thành viên này?',
  },
  EDUCATION_WORK_EXPERIENCE: {
    TITLE: <FormattedMessage id="userProfile.education_experience" />,
    STATUS: <FormattedMessage id="userProfile.education_experience.status" />,
    STUDENT: <FormattedMessage id="userProfile.education_experience.student" />,
    WORKED: <FormattedMessage id="userProfile.education_experience.worked" />,
  },
  EDUCATION: {
    TITLE: <FormattedMessage id="userProfile.education" />,
    LONG_TITLE: <FormattedMessage id="userProfile.education.longTitle" />,
    SCHOOL: <FormattedMessage id="userProfile.education.school" />,
    MAJOR: <FormattedMessage id="userProfile.education.major" />,
    CURRENT_SCHOOL: <FormattedMessage id="userProfile.education.current" />,
    OTHER: <FormattedMessage id="userProfile.education.other" />,
    CODE: <FormattedMessage id="userProfile.education.code" />,
    LOCK: <FormattedMessage id="userProfile.education.lock" />,
  },
  WORK_EXPERIENCE: {
    TITLE: <FormattedMessage id="userProfile.work" />,
    LONG_TITLE: <FormattedMessage id="userProfile.work.longTitle" />,
    WORK_TITLE: <FormattedMessage id="userProfile.work.title" />,
    COMPANY: <FormattedMessage id="userProfile.work.company" />,
    CURRENT_JOB: <FormattedMessage id="userProfile.work.current" />,
  },
  SOCIAL_CONNECTION: {
    TITLE: <FormattedMessage id="userProfile.socialConnection" />,
    TITLE_SHORT_IN_MOBILE: <FormattedMessage id="userProfile.socialConnectionShort" />,
    DISCONNECT: <FormattedMessage id="userProfile.socialConnection.disconnect" />,
  },
}

export const TEXT_UI_MY_PROFILE = {
  TITLE: <FormattedMessage id="myProfile.title" />,
  CONTEST: {
    PARTICIPATED: <FormattedMessage id="myProfile.contest.participated" />,
    ALL: <FormattedMessage id="myProfile.contest.all" />,
    TOTAL_SCORE: <FormattedMessage id="myProfile.contest.totalScore" />,
    EXPORT_CERTIFICATE: <FormattedMessage id="userProfile.information.certificate" />,
  },
  TRAINING: {
    TITLE: 'Luyện thi',
    TRAINING_SUM: 'Đã đóng góp',
    PROBLEM_SUM: 'Tổng điểm',
    SOLVED: 'Đã giải',
  },
  FORUM: {
    CREATED: <FormattedMessage id="myProfile.forum.created" />,
    COMMENT: <FormattedMessage id="myProfile.forum.comment" />,
    LIKE: <FormattedMessage id="myProfile.forum.like" />,
  },
}

// in User Practice / Training
export const TEXT_UI_PROBLEM = {
  PROBLEM_NAME: 'Tên bài',
  SCORE: 'Điểm',
  TOTAL_SCORE: 'Tổng điểm',
  TYPE: {
    TITLE: 'Phân loại',
  },
  DIFFICULTY: {
    TITLE: 'Độ khó',
    EASY: 'Dễ',
    MEDIUM: 'Trung bình',
    HARD: 'Khó',
  },
}

export const TEXT_UI_CONTEST = {
  PUBLIC: <FormattedMessage id="contest.public" />,
  PRIVATE: <FormattedMessage id="contest.private" />,
  PLEASE_ENTER_SECRET_CODE: <FormattedMessage id="contest.pleaseEnterSecretCode" />,
  NO_PRIVATE_CONTEST_FOUND: <FormattedMessage id="contest.noPrivateContestFound" />,
  PAST: <FormattedMessage id="contest.status.past" />,
  ONGOING: <FormattedMessage id="contest.status.onGoing" />,
  PLANNED: <FormattedMessage id="contest.status.planned" />,
  TIME: <FormattedMessage id="contest.time" />,
  DATE: <FormattedMessage id="contest.date" />,
  BEGIN: <FormattedMessage id="contest.begin" />,
  END: <FormattedMessage id="contest.end" />,
  VENUE: <FormattedMessage id="contest.venue" />,
  DAY: <FormattedMessage id="contest.day" />,
  DAY_SHORT: <FormattedMessage id="contest.day_short" />,
  HOUR: <FormattedMessage id="contest.hour" />,
  HOUR_SHORT: <FormattedMessage id="contest.hour_short" />,
  MINUTE: <FormattedMessage id="contest.minute" />,
  MINUTE_SHORT: <FormattedMessage id="contest.minute_short" />,
  SECOND: <FormattedMessage id="contest.second" />,
  SECOND_SHORT: <FormattedMessage id="contest.second_short" />,
  REGISTERED_USER: <FormattedMessage id="contest.registeredUser" />,
  SCORE_BOARD: <FormattedMessage id="contest.scoreboard" />,
  VIEW_DETAIL: <FormattedMessage id="contest.viewDetail" />,
  ADD_TO_CALENDAR: <FormattedMessage id="contest.button.addToCalendar" />,
  ADDED_TO_CALENDAR: <FormattedMessage id="contest.button.addedToCalendar" />,
  REGISTER: <FormattedMessage id="contest.button.register" />,
  REGISTERED: <FormattedMessage id="contest.button.registered" />,
  ENTER_ROOM: <FormattedMessage id="contest.button.enterRoom" />,
  INFORMATION: <FormattedMessage id="contest.page.information" />,
  WAITING_ROOM: <FormattedMessage id="contest.page.waitingRoom" />,
  NAME: <FormattedMessage id="contest.scoreboard.name" />,
  SCORE: <FormattedMessage id="contest.scoreboard.score" />,
  SOLVED: <FormattedMessage id="contest.scoreboard.solved" />, // no of solved problems in contest report
  PENALTY: <FormattedMessage id="contest.scoreboard.penalty" />, // time to solve problems in contest report
  PENALTY_HINT: <FormattedMessage id="contest.scoreboard.penaltyHint" />, // time to solve problems in contest report
  FIRST_TO_SOLVE_PROBLEM: <FormattedMessage id="contest.scoreboard.firstToSolveProblem" />,
  SOLVED_PROBLEM: <FormattedMessage id="contest.scoreboard.solvedProblem" />,
  ATTEMPTED_PROBLEM: <FormattedMessage id="contest.scoreboard.attemptedProblem" />,
  PENDING_PROBLEM: <FormattedMessage id="contest.scoreboard.pendingProblem" />,
  SCORE_BOARD_STATUS_UPDATING: <FormattedMessage id="contest.scoreBoardUpdating" />,
  SCORE_BOARD_STATUS_IN_REVIEW: <FormattedMessage id="contest.scoreBoardInReview" />,
  SCORE_BOARD_STATUS_FINALIZED: <FormattedMessage id="contest.scoreBoardFinalized" />,
  JUDGEMENT_ENVIRONMENT: <FormattedMessage id="contest.judgementEnvironment" />,
  JUDGEMENT_RANKING: <FormattedMessage id="contest.judgementRanking" />,
  CONTEST_WILL_BEGIN: <FormattedMessage id="contest.willBegin" />,
  NOT_ALLOW_TO_ATTEND_CONTEST: <FormattedMessage id="contest.notAllowToAttendContest" />,
  ONLY_REGISTER_ONE_CONTEST: <FormattedMessage id="contest.onlyRegisterOneContest" />,
  FACEBOOK_GROUP_MODAL: <FormattedMessage id="contest.facebookGroupModal" />,
  VNOI_GROUP_MODAL: <FormattedMessage id="contest.vnoiGroupModal" />,
  FACEBOOK_GROUP_MODAL_TITLE: <FormattedMessage id="contest.facebookGroupModalTitle" />,
  SCORE_BOARD_FILTER_HELPER: <FormattedMessage id="contest.scoreBoardFilterHelper" />,
}

export const CONTEST_SCORE_BOARD_STATUS = {}

export const TEXT_UI_NEWS = {
  WRITE_A_COMMENT: <FormattedMessage id="news.writeComment" />,
  WRITE_A_REPLY_TO: <FormattedMessage id="news.writeReplyTo" />,
  FILTER_TITLE: <FormattedMessage id="news.filterTitle" />,
}

export const TEXT_UI_FORUM = {
  TITLE: <FormattedMessage id="forum.title" />,
  RECENT_POST: <FormattedMessage id="forum.recentPost" />,
  STATISTIC: <FormattedMessage id="forum.statistic" />,
  THREAD: <FormattedMessage id="forum.thread" />,
  MESSAGE: <FormattedMessage id="forum.message" />,
  CREATE_TOPIC: <FormattedMessage id="forum.createTopic" />,
  VIEW: <FormattedMessage id="forum.view" />,
  REPLIES: <FormattedMessage id="forum.replies" />,
  POST: <FormattedMessage id="forum.post" />,
  REPORT_CONTENT: <FormattedMessage id="forum.reportContent" />,
  REPORT_REASON: <FormattedMessage id="forum.reportReason" />,
}

export const TEXT_UI_FAQ = {
  FAQ: <FormattedMessage id="faq.faq" />,
}

export const TEXT_UI_ABOUT_US = {
  ABOUT_CODE_TOUR_TITLE: <FormattedMessage id="aboutUs.aboutCodeTourTitle" />,
  ABOUT_CODE_TOUR_DESCRIPTION: <FormattedMessage id="aboutUs.aboutCodeTourDescription" />,
  INFO_VISION_TITLE: <FormattedMessage id="aboutUs.infoVisionTitle" />,
  INFO_VISION_DESCRIPTION: <FormattedMessage id="aboutUs.infoVisionDescription" />,
  INFO_SLOGAN_TITLE: <FormattedMessage id="aboutUs.infoSloganTitle" />,
  INFO_SLOGAN_DESCRIPTION: <FormattedMessage id="aboutUs.infoSloganDescription" />,
  INFO_MISSION_TITLE: <FormattedMessage id="aboutUs.infoMissionTitle" />,
  INFO_MISSION_DESCRIPTION: <FormattedMessage id="aboutUs.infoMissionDescription" />,

  MILESTONE_UPGRADE_TITLE: <FormattedMessage id="aboutUs.milestoneUpgradeTitle" />,
  MILESTONE_UPGRADE_DESCRIPTION: <FormattedMessage id="aboutUs.milestoneUpgradeDescription" />,
  MILESTONE_TRANSFORM_TITLE: <FormattedMessage id="aboutUs.milestoneTransformTitle" />,
  MILESTONE_TRANSFORM_DESCRIPTION: (
    <FormattedMessage id="aboutUs.milestoneTransformDescription_main" />
  ),
  MILESTONE_TRANSFORM_DESCRIPTION_1: (
    <FormattedMessage id="aboutUs.milestoneTransformDescription_1" />
  ),
  MILESTONE_TRANSFORM_DESCRIPTION_2: (
    <FormattedMessage id="aboutUs.milestoneTransformDescription_2" />
  ),
  MILESTONE_TRANSFORM_DESCRIPTION_3: (
    <FormattedMessage id="aboutUs.milestoneTransformDescription_3" />
  ),
  MILESTONE_EXPAND_TITLE: <FormattedMessage id="aboutUs.milestoneExpandTitle" />,
  MILESTONE_EXPAND_DESCRIPTION: <FormattedMessage id="aboutUs.milestoneExpandDescription" />,
  MILESTONE_BEGIN_TITLE: <FormattedMessage id="aboutUs.milestoneBeginTitle" />,
  MILESTONE_BEGIN_DESCRIPTION: <FormattedMessage id="aboutUs.milestoneBeginDescription" />,
}

export const TEXT_UI_NOTIFICATION = {
  ACTION: {
    REPLY: 'notification.action.reply',
    POST: 'notification.action.post',
    LIKE: 'notification.action.like',
    DISLIKE: 'notification.action.dislike',
    NEWS: 'notification.action.news',
  },
  OBJECT: {
    YOUR_COMMENT: 'notification.object.yourComment',
    YOUR_REPLY: 'notification.object.yourReply',
    YOUR_TOPIC: 'notification.object.yourTopic',
    YOUR_POST: 'notification.object.yourPost',
    NEWS: 'notification.object.news',
  },
}
