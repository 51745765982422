import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor, history } from 'redux/store'
import { Localization, InitialCheck } from 'components'
import AppRouter from 'AppRouter'
import 'antd/dist/antd.css'
import 'styles/global.scss'
// import * as serviceWorker from './serviceWorker'

setDefaultBreakpoints([
  { mobileLow: 0 },
  { mobileUp: 767 },
  { tabletLow: 768 },
  { tabletUp: 1023 },
  { desktopLow: 1024 },
  { maxWidthBodyLow: 1170 }, // $max-width-body
  { maxWidthBodyUp: 1171 },
  { desktopUp: 1365 },
  { desktopLargeLow: 1366 },
  { desktopLargeUp: 1920 },
  { desktopXLarge: 1921 },
])

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <BreakpointProvider>
        <InitialCheck>
          <Localization>
            <AppRouter history={history} />
          </Localization>
        </InitialCheck>
      </BreakpointProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// serviceWorker.register()
