import React from 'react'
import { Input } from 'antd'

// const InputTextWithI18N = ({ intl, i18nId, ...props }) => (
//   <Input placeholder={intl.formatMessage({ id: i18nId })} {...props} />
// )
//
// export default injectIntl(InputTextWithI18N)

export default ({ intl, i18nId, ...props }) => (
  <Input placeholder={intl.formatMessage({ id: i18nId })} {...props} />
)
