import React, { useState } from 'react'
import { Button } from 'antd'
import { TEXT_BUTTON } from 'config'
import ModalDeleteConfirm from '../../ModalDeleteConfirm'

export default ({ className, onDeleteSubmission }) => {
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)

  const hideModal = () => setIsShowConfirmModal(false)
  const showModal = () => setIsShowConfirmModal(true)

  const deleteSubmission = () => {
    hideModal()
    onDeleteSubmission()
  }

  return (
    <>
      <ModalDeleteConfirm
        isShown={isShowConfirmModal}
        onOK={deleteSubmission}
        onCancel={hideModal}
      />
      <Button type="cancel" onClick={showModal} className={className}>
        <span className="color-red">{TEXT_BUTTON.DELETE}</span>
      </Button>
    </>
  )
}
