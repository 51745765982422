import React from 'react'
import { TEXT_UI_LEADER_BOARD } from 'config'
import iconRank1 from 'assets/image/icon/rank_1.png'
import iconRank2 from 'assets/image/icon/rank_2.png'
import iconRank3 from 'assets/image/icon/rank_3.png'

const MEDAL = {
  1: iconRank1,
  2: iconRank2,
  3: iconRank3,
}

export default class DatatableUtils {
  static prepareColumnAlign(columns) {
    return columns.map((col) => {
      if (!('align' in col)) col.align = 'center'
      return col
    })
  }

  static renderColumnLeaderboardRank(currentPage, customConfig) {
    return {
      title: TEXT_UI_LEADER_BOARD.RANK,
      dataIndex: 'rank',
      ...customConfig,
      render: (value, row, idx) =>
        currentPage === 1 && MEDAL[value] ? (
          <img src={MEDAL[value]} alt="medal" className="w-25px" />
        ) : (
          <span>{value}</span>
        ),
    }
  }

  static renderColumnLeaderboardIndex(totalPrevious, customConfig) {
    return {
      title: TEXT_UI_LEADER_BOARD.RANK,
      dataIndex: 'rank',
      ...customConfig,
      render: (value, row, idx) => {
        const actualRank = idx + totalPrevious + 1
        return totalPrevious === 0 && MEDAL[actualRank] ? (
          <img src={MEDAL[actualRank]} alt="medal" className="w-25px" />
        ) : (
          <span>{actualRank}</span>
        )
      },
    }
  }
}
