import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { LoadingIndicator } from 'components'
import { ErrorHandlerUtils, MiscUtils, URLUtils, ValidationUtils } from 'utils'
import { FE_ROUTE } from 'config'
import { UserService } from 'services'
import { setCurrentUserAction } from 'redux/auth/actions'

function InitialCheck({ currentUser, children }) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const currentPathname = URLUtils.getPathnameInURL()
    // when connecting to Linkedin in profile page
    if (currentPathname === FE_ROUTE.AUTH.LOGIN_LINKEDIN_CALLBACK) {
      setIsLoading(false)
      return
    }
    if (
      !ValidationUtils.empty(currentUser) &&
      ![
        FE_ROUTE.ERROR.FORBIDDEN,
        FE_ROUTE.ERROR.FORBIDDEN_LOGOUT,
        FE_ROUTE.ERROR.NOT_FOUND,
      ].includes(currentPathname)
    )
      // async, we dont care if if failed (un-login) or not because it will be checked in route later
      UserService.sync()
        .then((user) => {
          MiscUtils.dispatchReduxAction(setCurrentUserAction(user))
        })
        .catch((error) => {
          ErrorHandlerUtils.http(error)
        })

    setTimeout(() => {
      // check if Zalo auth callback
      const params = URLUtils.getParamsInURL()
      const { uid, code } = params
      if (
        !ValidationUtils.empty(currentUser) &&
        ValidationUtils.empty(uid) &&
        ValidationUtils.empty(code) &&
        (currentPathname.indexOf(FE_ROUTE.AUTH.ROOT) === 0 ||
          (currentPathname.indexOf(FE_ROUTE.ACTIVATE.ROOT) === 0 &&
            currentPathname !== FE_ROUTE.ACTIVATE.FORGOT_PASSWORD))
      )
        URLUtils.moveToURL(FE_ROUTE.DEFAULT_ROUTE)
      else setIsLoading(false)
    }, 100)
  }, [])

  return (
    <>
      {isLoading && <LoadingIndicator isHidden={!isLoading} />}
      {!isLoading && children}
    </>
  )
}

const mapStateToProps = ({ authReducer: { currentUser } }) => ({
  currentUser,
})

export default connect(mapStateToProps)(InitialCheck)
