import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class SettingService {
  static getContent(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.SETTING.BASE, BE_ROUTE.SETTING.PATH.CONTENT)
    return HttpService.get(url, data)
  }

  static getCountry() {
    const url = URLUtils.buildBeURL(BE_ROUTE.SETTING.BASE, BE_ROUTE.SETTING.PATH.GET_COUNTRY)
    return HttpService.get(url)
  }

  static getUniversity() {
    const url = URLUtils.buildBeURL(BE_ROUTE.SETTING.BASE, BE_ROUTE.SETTING.PATH.GET_UNIVERSITY)
    return HttpService.get(url)
  }

  static getMajor() {
    const url = URLUtils.buildBeURL(BE_ROUTE.SETTING.BASE, BE_ROUTE.SETTING.PATH.GET_MAJOR)
    return HttpService.get(url)
  }
}
