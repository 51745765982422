import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class UserService {
  static sync() {
    const url = URLUtils.buildBeURL(BE_ROUTE.USER.BASE, BE_ROUTE.USER.PATH.SYNC)
    return HttpService.get(url)
  }

  static syncStatistic() {
    const url = URLUtils.buildBeURL(BE_ROUTE.USER.BASE, BE_ROUTE.USER.PATH.SYNC_STATISTIC)
    return HttpService.get(url)
  }

  static getUserInformation(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE)
    return HttpService.get(url, data)
  }

  static getUserProfile(userId, recaptchaToken) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.PROFILE.BASE,
      `/${userId}?recaptchaToken=${recaptchaToken}`,
    )
    return HttpService.get(url)
  }

  static getAttendedContests(userId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.PROFILE.BASE,
      BE_ROUTE.PROFILE.PATH.ATTENDED_CONTESTS.replace(':userId', userId),
    )
    return HttpService.get(url, data)
  }

  // update user info
  static putUserInfo(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE)
    return HttpService.put(url, data)
  }

  // get all uploaded avatar
  static getUserAvatar() {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE, BE_ROUTE.INFORMATION.PATH.AVATAR)
    return HttpService.get(url)
  }

  // upload new avatar
  static uploadUserAvatar(recaptchaToken, data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE, BE_ROUTE.INFORMATION.PATH.AVATAR)
    return HttpService.postFormData(`${url}?recaptchaToken=${recaptchaToken}`, data)
  }

  // set new avatar
  static setUserAvatar(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE, BE_ROUTE.INFORMATION.PATH.AVATAR)
    return HttpService.put(url, data)
  }

  // delete selected avatar
  static deleteUserAvatar(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE, BE_ROUTE.INFORMATION.PATH.AVATAR)
    return HttpService.delete(url, data)
  }

  static changePassword(data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.CHANGE_PASSWORD,
    )
    return HttpService.post(url, data)
  }

  static forgotPassword(data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.FORGOT_PASSWORD,
    )
    return HttpService.post(url, data)
  }

  static changeUsername(data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.CHANGE_USERNAME,
    )
    return HttpService.post(url, data)
  }

  // get teams that include this user
  static getTeamInfo() {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE, BE_ROUTE.INFORMATION.PATH.TEAM)
    return HttpService.get(url)
  }

  static hasOldPassword() {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.HAS_OLD_PASSWORD,
    )
    return HttpService.get(url)
  }

  static connectSocial(data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.CONNECT_SOCIAL,
    )
    return HttpService.post(url, data)
  }

  static disconnectSocial(data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.DISCONNECT_SOCIAL,
    )
    return HttpService.post(url, data)
  }

  static logoutContestSession() {
    const url = URLUtils.buildBeURL(BE_ROUTE.USER.BASE, BE_ROUTE.USER.PATH.LOGOUT_CONTEST_SESSION)
    return HttpService.post(url)
  }

  static deleteWorkExperience(objectId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.WORK_EXPERIENCE.replace(':id', objectId),
    )
    return HttpService.delete(url, data)
  }

  static deleteEducation(objectId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.EDUCATION.replace(':id', objectId),
    )
    return HttpService.delete(url, data)
  }

  static setLocale(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.INFORMATION.BASE, BE_ROUTE.INFORMATION.PATH.SET_LOCALE)
    return HttpService.put(url, data)
  }

  static exportContestCertificate(contestId) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.INFORMATION.BASE,
      BE_ROUTE.INFORMATION.PATH.CERTIFICATION_CONTEST.replace(':contestId', contestId),
    )
    return HttpService.download(url)
  }
}
