import React from 'react'
import ReactHtmlParser from 'html-react-parser'
import classnames from 'classnames'
import DOMPurify from 'dompurify'
import styles from './style.module.scss'

// Avoiding XSS in React applications: https://pragmaticwebsecurity.com/files/cheatsheets/reactxss.pdf
// demo: https://replit.com/@remarkablemark/html-react-parser-dompurify
// only allow iframe for Youtube embedded
DOMPurify.addHook('uponSanitizeElement', (node, data) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || ''
    if (!src.startsWith('https://www.youtube.com/embed/')) {
      return node.parentNode.removeChild(node)
    }
  }
  return node
})

// allow iframe: https://stackoverflow.com/questions/60299226/how-to-allow-an-iframe-tag-in-dompurify-including-all-of-its-attributes
export default ({ htmlData, isTrusted }) => (
  <div className={classnames('mce-content-body', styles.content)}>
    {isTrusted
      ? // ReactHtmlParser(htmlData) :
        ReactHtmlParser(
          DOMPurify.sanitize(htmlData, {
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
          }),
        )
      : ReactHtmlParser(DOMPurify.sanitize(htmlData))}
  </div>
)

// https://github.com/remarkablemark/html-react-parser
// https://github.com/peternewnham/react-html-parser : same, also not prevent xss
// --- xss attack: https://github.com/remarkablemark/html-react-parser/issues/94
// --- root cause: https://github.com/remarkablemark/html-react-parser/blob/v0.6.2/lib/dom-to-react.js#L55-L62
// correct xss: https://jsfiddle.net/harveydf/qmhbwoLy/1
// incorrect xss: https://jsfiddle.net/remarkablemark/4ye1dq2a/
// --- owasp sample: https://jsfiddle.net/remarkablemark/ujd2Lev9/

// import ReactHtmlParser from "react-html-parser"
// export default ({ htmlData }) => (
//   <div>
//     {ReactHtmlParser(htmlData)}
//   </div>
// )
