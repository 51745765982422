import React from 'react'
import { useCurrentBreakpointName } from 'react-socks'
import { SNS_LOGIN_CONFIG, SNS_NAME } from 'config'
import { DisplayUtils } from 'utils'
import logoGoogle from 'assets/image/logo-brand/logo-google-1.png'
import SocialButton from '../index'

export default ({ handleSocialLogin, handleSocialLoginFailure, disabled, isShowName }) => {
  const isMobile = DisplayUtils.isMobile(useCurrentBreakpointName())
  return (
    <SocialButton
      provider={SNS_NAME.GOOGLE.toLowerCase()}
      appId={SNS_LOGIN_CONFIG.GOOGLE_APP_ID}
      onLoginSuccess={(data) => handleSocialLogin(SNS_NAME.GOOGLE, data)}
      onLoginFailure={handleSocialLoginFailure}
      providerName={isShowName || !isMobile ? SNS_NAME.GOOGLE : ''}
      // Google OAuth2 API in https://developers.google.com/identity/protocols/oauth2/scopes
      scope={[
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ]}
      logo={logoGoogle}
      disabled={disabled}
    />
  )
}
