const ACTION = {
  SET_COUNT_UNREAD_NOTIFICATION_TRIGGER: 'SET_COUNT_UNREAD_NOTIFICATION_TRIGGER',
  SET_COUNT_UNREAD_NOTIFICATION: 'SET_COUNT_UNREAD_NOTIFICATION',
}

const setCountUnreadNotificationAction = (countUnread) => ({
  type: ACTION.SET_COUNT_UNREAD_NOTIFICATION_TRIGGER,
  data: { countUnread },
})

module.exports = {
  ACTION,
  setCountUnreadNotificationAction,
}
