import React, { useState, useEffect } from 'react'
import { CustomModal } from 'components'
import { TEXT_BUTTON, TEXT_UI_MISC } from 'config'

export default ({ isShown, onCancel, onOK }) => {
  const [modalVisible, setModalVisible] = useState(isShown)

  useEffect(() => {
    setModalVisible(isShown)
  }, [isShown])

  return (
    <CustomModal
      title={TEXT_UI_MISC.MODAL.TITLE_DELETE_CONFIRM}
      visible={modalVisible}
      onCancel={onCancel}
      onOK={onOK}
      okText={TEXT_BUTTON.DELETE}
    />
  )
}
