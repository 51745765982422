import antdData from 'antd/lib/locale-provider/en_US'
// https://formatjs.io/docs/react-intl/upgrade-guide-3x/#migrate-to-using-native-intl-apis
import localeData from '@formatjs/intl-pluralrules/locale-data/en'

require('@formatjs/intl-pluralrules/polyfill')
// require('@formatjs/intl-relativetimeformat/polyfill')
// require('@formatjs/intl-relativetimeformat/locale-data/en')

const messages = {
  'backend.error.validator.usernameExist': 'Username had been registered',
  'backend.error.validator.emailExist': 'Email had been registered',
  'backend.error.validator.emailRequired': 'Email is required',
  'backend.error.validator.accountNotFound': 'Account not found',
  'backend.error.validator.noValidAccountFound': 'No valid account found',
  'backend.error.validator.accountIsInactive': 'Account had not been activated yet',
  'backend.error.validator.accountIsBanned': 'Account had been banned',
  'backend.error.validator.accountHadBeenActivated': 'Account had already been activated',
  'backend.error.validator.invalidLoginCredentials': 'Invalid credentials',
  'backend.error.validator.emailUnmatched': 'Social email does not match with your account',
  'backend.error.validator.invalidOldPassword': 'Invalid old password',
  'backend.error.validator.activateTokenExpired': 'Token was expired',
  'backend.error.validator.activateTokenInvalid': 'Token is invalid',
  'backend.error.validator.notHuman': 'You are not a human being, are you?',
  'backend.error.validator.humanCheckingFailed': 'Security checking failed',

  'notification.success.updateInfo': 'Information updated successfully',
  'notification.success.emailSent': 'Email sent successfully',
  'notification.success.send': 'Sent successfully',
  'notification.success.save': 'Save successfully',

  'error.notFound': 'Not found',
  'error.forbidden': 'Forbidden',

  'validator.required': 'This is a required field',
  'validator.noSpecialCharAllowed': 'No special character allowed',
  'validator.textAtLeastNLength': 'Please enter at least {N} characters',
  'validator.textMaxNLength': 'Exceeded maximum character length of {N}',
  'validator.invalidEmailFormat': 'Invalid email format',
  'validator.passwordUnmatched': 'The two passwords that you entered do not match',
  'validator.usernameOrEmailRequired': 'Please enter your username or email',
  'validator.passwordRequired': 'Please enter your password',
  'validator.passwordAtLeast1UpperCase': 'Password must contain at least one uppercase letter',
  'validator.passwordAtLeast1Number': 'Password must contain at least one number',
  'validator.passwordAtLeast1SpecialChar': 'Password must contain at least one special character',
  'validator.passwordMayNotContainSequences':
    'Password may not contain sequences of three or more repeated characters',
  'validator.mustStartWith': 'Must start with {text}',
  'validator.svgPhotoBlocked': 'SVG format photo is not allowed',
  'validator.alphabetOnly': 'Alphabet only',
  'validator.numericOnly': 'Numeric only',

  'button.save': 'Save',
  'button.cancel': 'Cancel',
  'button.edit': 'Edit',
  'button.delete': 'Delete',
  'button.joinNow': 'Join Code Tour 2024',
  'button.createTeam': 'Create team',
  'button.add': 'Add',
  'button.addMember': 'Add member',
  'button.invite': 'Invite',
  'button.getNewPassword': 'Get new password',
  'button.resend': 'Resend',
  'button.preview': 'Preview',
  'button.savePost': 'Save post',
  'button.comment': 'Comment',
  'button.viewMore': 'View more',
  'button.upload': 'Upload',
  'button.loginToComment': 'Login to comment',
  'button.goBack': 'Go back',
  'button.reply': 'Reply',
  'button.report': 'Report',
  'button.editOrDeleteThis': 'Edit or delete this',
  'button.here': 'Here',
  'button.continue': 'Continue',
  'button.back': 'Back',

  'misc.modal.confirmToDelete': 'Are you sure to delete?',
  'misc.noDataAvailable': 'No data available',
  'misc.requiredInformation': 'Required',
  'misc.here': 'here',

  'layout.header.home': 'Home',
  'layout.header.login': 'SIGN IN',
  'layout.header.logout': 'Logout',
  'layout.header.register': 'SIGN UP',
  'layout.header.register_now': 'Register now',
  'layout.header.contest': 'Contest',
  'layout.header.training': 'Practice',
  'layout.header.news': 'News',
  'layout.header.forum': 'Forum',
  'layout.header.ranking': 'Leaderboard',
  'layout.header.manage_account': 'Manage account',
  'layout.header.my_profile': 'My profile',
  'layout.header.change_password': 'Change password',
  'layout.header.manage_team': 'Manage team',

  'layout.footer.links': 'Links',
  'layout.footer.information': 'Information',
  'layout.footer.about_us': 'About Code Tour',
  'layout.footer.privacy': 'Privacy policy',
  'layout.footer.tos': 'Terms of use',
  'layout.footer.faq': 'FAQ',
  'layout.footer.copyright': 'Copyright © 2022',

  'layout.notification': 'Notifications',
  'layout.notification.noData': 'There is no notification',
  'layout.notification.all': 'All',
  'layout.notification.unread': 'Unread',

  'auth.signIn': 'Sign in',
  'auth.signUp': 'Sign up',
  'auth.activateSuccess': 'Account activated successfully',
  'auth.verificationRequired': 'You have not verified your account',
  'auth.verificationGuideline':
    'Please check your email and follow the instructions to verify your email and complete account registration on Code Tour.',
  'auth.verificationGuidelineResend':
    'If you have not received the email, please click {URL} to resend the email.',
  'auth.logout': 'Logout',
  'auth.email': 'Email',
  'auth.gender': 'Gender',
  'auth.gender_male': 'Male',
  'auth.gender_female': 'Female',
  'auth.gender_other': 'Other',
  'auth.username': 'Username',
  'auth.usernameOrEmail': 'Username/Email',
  'auth.password': 'Password',
  'auth.passwordConfirm': 'Confirm password',
  'auth.socialSignIn': 'or sign in with',
  'auth.signIn.rememberMe': 'Stay signed in',
  'auth.signIn.rememberMeHint': 'Do this to reduce the number of times you are asked to sign in',
  'auth.signIn.forgotPassword': 'Forgot password?',
  'auth.signUp.createAccount': 'Create account',
  'auth.signUp.resendEmail': 'Resend email',
  'auth.signUp.setupSocialAccount': 'Setup social account',
  'auth.signUp.agreeWith': 'I agree with',
  'auth.signUp.agreeWithTitle':
    'By registering an account on the website codetour.org, you allow VNG to use the personal information provided for the purposes of the contest in accordance.',

  'home.banner.platform': 'Platform to develop coding mindset',
  'home.banner.platform_1': 'Platform to',
  'home.banner.platform_2': 'develop coding mindset',
  'home.banner.and': 'and',
  'home.banner.connection': 'connect tech-loving community',
  'home.banner.connection_1': 'connect tech-loving community',
  'home.banner.connection_2': '\n',
  'home.introduction.title': 'Introduction about Code Tour',
  'home.introduction.description':
    'Code Tour is a platform that provides high quality exams, helps members (Codee) develop their coding mindset and connects tech-loving community where they can learn, exchange and share experiences.',
  'home.fact.year': 'years of',
  'home.fact.ofDevelopment': 'development',
  'home.fact.participant': 'participants',
  'home.fact.challenge': 'Code Challenges',
  'home.benefit.title': 'Benefits of joining Code Tour',
  'home.benefit.price': 'Attractive prizes',
  'home.benefit.priceDescription':
    'Prizes for the top contestants of each Code Challenge and other benefits.',
  'home.benefit.exam': 'High-quality exams',
  'home.benefit.examDescription':
    'A library of exams and exercises will enhance your algorithmic mindset and your coding skill.',
  'home.benefit.activity': 'Interactive activities',
  'home.benefit.activityDescription':
    'Networking activities in different formats and platforms are for all members.',
  'home.benefit.skill': 'Advancing skills',
  'home.benefit.skillDescription':
    'Academic knowledge and time pressure in each Code Challenge help practice and improve capability.',
  'home.contest.title': 'Highlighted events',
  'home.testimonial.title': 'Codee stories',
  'home.partner.title': 'Accompanying partners',
  'home.partner.media': 'Media partners',
  'home.partner.organizer': 'Organizer',
  'home.partner.partners': 'Partners',
  'home.partner.strategy': 'Strategic partners',
  'home.partner.companion': 'Accompanying partners',
  'home.partner.technical': 'Technical partners',
  'home.profileModal.title': 'Please update your information',
  'home.profileModal.content':
    'Please provide more information in the "Manage account" section to participate in the contests',
  'home.profileModal.buttonText': 'Update now',
  'home.termModal.title': 'Data privacy term',
  'home.termModal.content':
    'By registering an account on the website codetour.org, you allow VNG to use the personal information provided for the purposes of the contest in accordance with the Privacy policy',

  'contest.searchPlaceholderPublic': 'Search for contests',
  'contest.searchPlaceholderPrivate': 'Enter secret code',
  'contest.public': 'Public',
  'contest.private': 'Private',
  'contest.status.past': 'Finished',
  'contest.status.onGoing': 'Ongoing',
  'contest.status.planned': 'Coming soon',
  'contest.pleaseEnterSecretCode':
    'Please enter the secret code provided by the Organizers to join Private Contest',
  'contest.noPrivateContestFound':
    'Your private contest is not available, please input another Secret code',
  'contest.time': 'Time',
  'contest.date': 'Date',
  'contest.begin': 'Begin',
  'contest.end': 'End',
  'contest.venue': 'Venue',
  'contest.viewDetail': 'View details',
  'contest.day': 'Days',
  'contest.day_short': 'd',
  'contest.hour': 'Hours',
  'contest.hour_short': 'h',
  'contest.minute': 'Minutes',
  'contest.minute_short': 'm',
  'contest.second': 'Seconds',
  'contest.second_short': 's',
  'contest.registeredUser': 'Participants registered',
  'contest.scoreboard': 'Leaderboard',
  'contest.button.addToCalendar': 'Calendar',
  'contest.button.addedToCalendar': 'Calendar',
  'contest.button.register': 'Register',
  'contest.button.registered': 'Registered',
  'contest.button.enterRoom': 'Enter room',
  'contest.page.information': 'Overall information',
  'contest.page.waitingRoom': 'Waiting room',
  'contest.scoreboard.name': 'Name',
  'contest.scoreboard.score': 'Score',
  'contest.scoreboard.solved': 'Solved',
  'contest.scoreboard.penalty': 'Penalty',
  'contest.scoreboard.penaltyHint':
    'Total spending time which displayed as "DD:HH:mm:ss". If it is less than a day, then "DD" will be striped',
  'contest.scoreboard.firstToSolveProblem': 'First to solve problem',
  'contest.scoreboard.solvedProblem': 'Solved problem',
  'contest.scoreboard.attemptedProblem': 'Attempted problem',
  'contest.scoreboard.pendingProblem': 'Pending problem',
  'contest.scoreBoardUpdating': 'Updating',
  'contest.scoreBoardInReview': 'In review',
  'contest.scoreBoardFinalized': 'Final',
  'contest.judgementEnvironment': 'Judgement environment',
  'contest.judgementRanking': 'Judgement ranking',
  'contest.willBegin': 'The contest will begin in',
  'contest.notAllowToAttendContest':
    'You are not eligible to participate. See details in the Overall information of the contest',
  'contest.onlyRegisterOneContest': 'You can only register one contest',
  'contest.facebookGroupModal':
    'Follow Facebook Group "Code to Connect" to get the latest information!',
  'contest.vnoiGroupModal':
    'The contest will be on codetour.vnoi.info. Organizers will email your contest account at least 12 hours before the competition starts. Check your email to stay updated!',
  'contest.facebookGroupModalTitle': 'You have successfully registered for the contest',
  'contest.scoreBoardFilterHelper': 'Find result be school',

  'news.writeComment': 'Write a comment',
  'news.writeReplyTo': 'Reply to <username>',
  'news.filterTitle': 'News related to #',

  'forum.title': 'Title',
  'forum.searchPlaceholder': 'Search for topics',
  'forum.recentPost': 'Recent post',
  'forum.post': 'Post',
  'forum.statistic': 'Statistic',
  'forum.thread': 'Threads',
  'forum.message': 'Messages',
  'forum.createTopic': 'Create topic',
  'forum.view': 'Views',
  'forum.replies': 'Replies',
  'forum.reportContent': 'Report content',
  'forum.reportReason': 'Reason',

  'teamCard.pending': 'Waiting',
  'teamCard.leader': 'Leader',
  'teamCard.member': 'Member',
  'teamCard.addMember': 'Add Member',
  'teamCard.invite': 'Invite',
  'teamCard.inviteSuccess': 'Successfully sent invitation',
  'teamCard.myTeam': 'My teams',
  'teamCard.createTeam': 'Create teams',
  'teamCard.teamName': 'Team Name',
  'teamCard.teamDescription': 'Description',
  'teamCard.confirmDelete': 'Confirm Delete',
  'teamCard.confirmDeleteMessage': 'Are you sure to delete item?',

  'profile.information': 'INFORMATION',
  'profile.challenges': 'Challenges',
  'profile.sum': 'SUM',
  'profile.forum': 'Forum',
  'profile.UserPractice': 'UserPractices',
  'profile.resolvedAssignments': 'Resolved Assignments',

  'aboutUs.aboutCodeTourTitle': 'About Code Tour',
  'aboutUs.aboutCodeTourDescription':
    'Code Tour, formerly a playground for algorithm enthusiasts created by VNG, has now been upgraded to become a platform to improve coding mindset and connect the tech-loving community, to bring along a series of interesting activities for "coding fans" to learn, practice and connect. Code Tour not only offers a source of high-quality knowledge and attractive prizes but also accompanies with you as a "buddy" on your continuous journey of learning and training to build up your future career.',
  'aboutUs.infoVisionTitle': 'Vision',
  'aboutUs.infoVisionDescription':
    'Code Tour provides a friendly platform with exciting experiences for coders to learn and connect together thereby creating a large technology community not only in Vietnam but also in Southeast Asia area and international scale.',
  'aboutUs.infoSloganTitle': 'Slogan: Code. Connect. Challenge',
  'aboutUs.infoSloganDescription':
    'Code Tour community together develops based on the spirit of practice - cultivation - competing. Young people are given the opportunity to really start coding with a regular frequency and various formats, to connect with like-minded friends to share knowledge and information, and at the same time to foster their ability by challenging themselves through the pressure and difficult level of the exams.',
  'aboutUs.infoMissionTitle': 'Mission: Develop tech talents',
  'aboutUs.infoMissionDescription':
    'All activities and values of Code Tour aim to create the best conditions for Codees (named members of Code Tour) to improve their mindset and develop skills through tests, exercises and networking with other Codees, helping them to nurture their passion and build their capability to develop future career.',
  'aboutUs.milestoneUpgradeTitle': 'Upgrading',
  'aboutUs.milestoneUpgradeDescription':
    'Code Tour updates new website interface containing many new features so that domestic and international contestants have more interesting experiences than ever before.',
  'aboutUs.milestoneTransformTitle': 'Transforming',
  'aboutUs.milestoneTransformDescription_main':
    'Code Tour was organized with many new versions for different groups of contestants. Therefore, Code Tour built interaction with more than 1.500 contestants on the website, including:',
  'aboutUs.milestoneTransformDescription_1':
    'Code Tour x Uni: exclusively for candidates of HCMUT, UIT',
  'aboutUs.milestoneTransformDescription_2':
    'Code Tour x VTF: for candidates who are applying for VNG Tech Fresher Program',
  'aboutUs.milestoneTransformDescription_3': 'Code Tour 2020: for all coders nationwide',
  'aboutUs.milestoneExpandTitle': 'Expanding',
  'aboutUs.milestoneExpandDescription':
    'Code Tour was held in Saigon and Da Nang combining physical activities and engagement in novel locations attracting the participation of more than 300 coders. After that, Code Tour 2019 took place successfully with 5 Code Challenges online on the website and attracted more than 1.000 contestants.',
  'aboutUs.milestoneBeginTitle': 'Beginning',
  'aboutUs.milestoneBeginDescription':
    'Code Tour is formerly Saigon Code Tour 2018 - a unique coding contest organized by VNG in Ho Chi Minh City with 4 rounds, attracting 182 contestants, bringing an interesting playground for coding fans.',

  'faq.faq': 'Frequently Asked Questions (FAQ)',

  'leaderBoard.title': 'Leaderboard',
  'leaderBoard.rank': 'Rank',
  'leaderBoard.username': 'Username',
  'leaderBoard.contest': 'Contests',
  'leaderBoard.contestHint': 'Attended Code Challenges',
  'leaderBoard.averageScore': 'Average score',
  'leaderBoard.averageScoreHint': 'Total score / Total official rounds',
  'leaderBoard.averagePenalty': 'Average penalty',
  'leaderBoard.averagePenaltyHint': 'Total penalty / Total official rounds',
  'leaderBoard.totalScore': 'Total score',
  'leaderBoard.totalScoreHint': 'Sum of scores from official rounds',
  'leaderBoard.totalPenalty': 'Total penalty',
  'leaderBoard.totalPenaltyHint': 'Sum of submission times from official rounds',
  'leaderBoard.searchPlaceholder': 'Input username or email',
  'leaderBoard.annual': 'Leaderboard by year',
  'leaderBoard.cumulative': 'Cumulative leaderboard',
  'leaderBoard.leaderBoardGuideline': 'Read criteria of Leaderboard in',
  'leaderBoard.leaderBoardCriteria': 'Criteria of Leaderboard',

  'userProfile.manageAccount': 'Manage account',
  'userProfile.from': 'From',
  'userProfile.to': 'To',
  'userProfile.present': 'Present',
  'userProfile.changeUsername': 'Change username',
  'userProfile.avatar.change': 'Change avatar',
  'userProfile.avatar.uploadedPhotos': 'Photos uploaded',

  'userProfile.information': 'Personal information',
  'userProfile.informationShort': 'Information',
  'userProfile.information.fullName': 'Full name',
  'userProfile.information.gender': 'Gender',
  'userProfile.information.gender.male': 'Male',
  'userProfile.information.gender.female': 'Female',
  'userProfile.information.gender.other': 'Other',
  'userProfile.information.birthDate': 'Year of birth',
  'userProfile.information.phone': 'Phone number',
  'userProfile.information.email': 'Email',
  'userProfile.information.country': 'Country/Region',
  'userProfile.information.province': 'Province/City',
  'userProfile.information.address': 'Address',
  'userProfile.information.certificate': 'Export your certificate',
  'userProfile.education_experience': 'Experience and education',
  'userProfile.education_experience.status': 'Current status',
  'userProfile.education_experience.student': 'Student',
  'userProfile.education_experience.worked': 'Employee',
  'userProfile.education': 'Education',
  'userProfile.education.longTitle': 'Academic level',
  'userProfile.education.school': 'School/University',
  'userProfile.education.major': 'Major',
  'userProfile.education.majorHint': 'Type N/A if you don’t have your major',
  'userProfile.education.current': 'Currently studying here',
  'userProfile.education.other': 'Other',
  'userProfile.education.otherHint': 'Type your school/university if you choose Other',
  'userProfile.education.code': 'Student code',
  'userProfile.education.codeHint': 'If you do not have one, type STUDENT',
  'userProfile.education.lock':
    'Education update feature is temporarily locked during the contest period',
  'userProfile.work': 'Experience',
  'userProfile.work.longTitle': 'Working experience',
  'userProfile.work.title': 'Role',
  'userProfile.work.company': 'Company',
  'userProfile.work.current': 'Currently working here',
  'userProfile.socialConnection': 'Connected accounts',
  'userProfile.socialConnectionShort': 'Connection',
  'userProfile.socialConnection.disconnect': 'Disconnect',
  'userProfile.changePassword': 'Change password',
  'userProfile.changePasswordSuccess': 'Password changed successfully',
  'userProfile.changePassword.oldPassword': 'Old password',
  'userProfile.changePassword.newPassword': 'New password',
  'userProfile.changePassword.newPasswordConfirm': 'Confirm password',
  'userProfile.changePassword.guideline.title': 'Follow the steps below to reset your account:',
  'userProfile.changePassword.guideline.1': 'Enter your username or email',
  'userProfile.changePassword.guideline.2':
    'Wait for the recovery information to be sent to the email address registered on Code Tour',
  'userProfile.changePassword.guideline.3': 'Follow the instructions in the email',

  'myProfile.title': 'Profile',
  'myProfile.contest.participated': 'Participated events',
  'myProfile.contest.all': 'All',
  'myProfile.contest.totalScore': 'Total points',
  'myProfile.forum.created': 'Created',
  'myProfile.forum.comment': 'Comment',
  'myProfile.forum.like': 'Liked',

  'notification.action.reply': 'replied to',
  'notification.action.post': 'posted to',
  'notification.action.like': 'reacted to',
  'notification.action.dislike': 'disliked',
  'notification.action.news': 'added',

  'notification.object.yourComment': 'your comment',
  'notification.object.yourReply': 'your reply',
  'notification.object.yourTopic': 'your topic',
  'notification.object.yourPost': 'your post',
  'notification.object.news': 'a post',

  'UserPractice.col.name': 'Name',
  'UserPractice.col.type': 'Type',
  'UserPractice.col.level': 'Level',
  'UserPractice.col.score': 'Score',
}

export default {
  locale: 'en',
  antdData,
  localeData,
  messages,
}
