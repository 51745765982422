import { ACTION } from './actions'

const initialState = {
  countUnread: 0,
}

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_COUNT_UNREAD_NOTIFICATION: {
      return {
        ...state,
        countUnread: action.data.countUnread,
      }
    }

    default: {
      return state
    }
  }
}

export default NotificationReducer
