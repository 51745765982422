import React, { useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { useCurrentBreakpointName } from 'react-socks'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import Logo from 'assets/image/logo.svg'
import { Layout as AntLayout, Menu, Dropdown, Button, Drawer, Space } from 'antd'
import { TEXT_UI_LAYOUT, FE_ROUTE, APP_MODULES } from 'config'
import { DisplayUtils, MiscUtils, URLUtils } from 'utils'
import { LanguageSelector, DesktopOnly, MobileOrTabletOnly } from 'components'
import Notification from './notification'
import styles from './style.module.scss'
import './style.overwrite.scss'

const AntHeader = AntLayout.Header

const infoConfig = [
  {
    label: TEXT_UI_LAYOUT.FOOTER.ABOUT_US,
    link: FE_ROUTE.MISC.ABOUT_US,
  },
  {
    label: TEXT_UI_LAYOUT.FOOTER.FAQ,
    link: FE_ROUTE.MISC.FAQ,
  },
  {
    label: TEXT_UI_LAYOUT.FOOTER.TOS,
    link: FE_ROUTE.MISC.TOS,
  },
  {
    label: TEXT_UI_LAYOUT.FOOTER.PRIVACY,
    link: FE_ROUTE.MISC.PRIVACY,
  },
]

const Header = ({ currentUser, pathname }) => {
  const [isDrawerShown, setIsDrawerShown] = useState(false)
  const initPathname = URLUtils.getPathnameInURL()
  const selectedButton = initPathname !== pathname ? initPathname : pathname
  const isMobileOrTablet = DisplayUtils.isMobileOrTablet(useCurrentBreakpointName())

  return (
    <AntHeader className={styles.header}>
      <div id="headerContentId" className={styles.content}>
        {/* ----- Mobile ----- */}
        <MobileOrTabletOnly>
          <div className={classnames('color-primary', styles['burger-menu'])}>
            <GiHamburgerMenu onClick={() => setIsDrawerShown(true)} />
          </div>
          <Drawer
            placement="left"
            onClose={() => setIsDrawerShown(false)}
            visible={isDrawerShown}
            closable={false}
          >
            {APP_MODULES.map((module) => (
              <p key={MiscUtils.generateId()} className="drawer-link">
                <Link to={module.link} onClick={() => setIsDrawerShown(false)}>
                  {module.label}
                </Link>
              </p>
            ))}

            {infoConfig.map((link) => (
              <p key={MiscUtils.generateId()} className="drawer-link">
                <Link to={link.link} onClick={() => setIsDrawerShown(false)}>
                  {link.label}
                </Link>
              </p>
            ))}
            <LanguageSelector
              placement="bottomLeft"
              onChangeCallback={() => setIsDrawerShown(false)}
            />
          </Drawer>
        </MobileOrTabletOnly>

        {/* ----- Logo ----- */}
        <div className={styles.logo}>
          <Link to={FE_ROUTE.DEFAULT_ROUTE}>
            {' '}
            <img src={Logo} alt="Code Tour" />
          </Link>
        </div>

        {/* ----- Desktop ----- */}
        {/* ----- Center: Main menu ----- */}
        {!isMobileOrTablet && (
          <div className={styles['center-menu-list']}>
            <Space size={35}>
              {APP_MODULES.map((module) => (
                <Link to={module.link} key={MiscUtils.generateId()}>
                  <div
                    className={classnames(styles['top-menu'], 'typography-menu', {
                      'text-button-current-selected': selectedButton.indexOf(module.link) >= 0,
                    })}
                  >
                    {module.label}
                  </div>
                </Link>
              ))}
            </Space>
          </div>
        )}

        {/* ----- Right: Context ----- */}
        <div className={styles['right-content']}>
          <Space size={12}>
            <DesktopOnly>
              <LanguageSelector />
            </DesktopOnly>

            {currentUser ? (
              <>
                {/* ----- list notifications -----*/}
                <Notification />

                <Dropdown
                  placement="bottomRight"
                  getPopupContainer={() => document.getElementById('headerContentId')}
                  className={styles['avatar-dropdown']}
                  overlayClassName={styles['avatar-dropdown']}
                  size="large"
                  overlay={
                    <Menu>
                      <Menu.Item key={MiscUtils.generateId()}>
                        <Link to={FE_ROUTE.USER.INFORMATION} className="typography-menu-subtext">
                          {TEXT_UI_LAYOUT.HEADER.DROPDOWN_MANAGE_ACCOUNT}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key={MiscUtils.generateId()}>
                        <Link
                          to={URLUtils.buildUserProfileURL(currentUser.id)}
                          className="typography-menu-subtext"
                        >
                          {TEXT_UI_LAYOUT.HEADER.DROPDOWN_MY_PROFILE}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key={MiscUtils.generateId()}>
                        <Link
                          to={FE_ROUTE.USER.CHANGE_PASSWORD}
                          className="typography-menu-subtext"
                        >
                          {TEXT_UI_LAYOUT.HEADER.DROPDOWN_CHANGE_PASSWORD}
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item key={MiscUtils.generateId()}> */}
                      {/*  <Link to={FE_ROUTE.USER.TEAM_MANAGEMENT}> */}
                      {/*    {TEXT_UI_LAYOUT.HEADER.DROPDOWN_MANAGE_TEAM} */}
                      {/*  </Link> */}
                      {/* </Menu.Item> */}
                      <Menu.Item key={MiscUtils.generateId()}>
                        <Link to={FE_ROUTE.AUTH.LOGOUT} className="typography-menu-subtext">
                          {TEXT_UI_LAYOUT.HEADER.LOGOUT}
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <div className={styles['avatar-container']}>
                    <img src={URLUtils.buildAvatarURL(currentUser.avatar)} alt="" />
                  </div>
                </Dropdown>
              </>
            ) : (
              <>
                <MobileOrTabletOnly>
                  <Link to={FE_ROUTE.AUTH.LOGIN}>
                    <span className="typography-menu-subtext color-primary bold">
                      {TEXT_UI_LAYOUT.HEADER.LOGIN}
                    </span>
                  </Link>
                </MobileOrTabletOnly>

                {/* ----- Desktop ----- */}
                <DesktopOnly>
                  <Space>
                    <a href={FE_ROUTE.AUTH.LOGIN}>
                      <Button>
                        <span className="typography-menu-subtext">
                          {TEXT_UI_LAYOUT.HEADER.LOGIN}
                        </span>
                      </Button>
                    </a>
                    <a href={FE_ROUTE.AUTH.REGISTER}>
                      <Button>
                        <span className="typography-menu-subtext">
                          {TEXT_UI_LAYOUT.HEADER.REGISTER}
                        </span>
                      </Button>
                    </a>
                  </Space>
                </DesktopOnly>
              </>
            )}
          </Space>
        </div>
      </div>
    </AntHeader>
  )
}

const mapStateToProps = ({
  authReducer: { currentUser },
  router: {
    location: { pathname },
  },
}) => ({
  currentUser,
  pathname,
})
export default connect(mapStateToProps)(Header)
