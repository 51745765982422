import owasp from 'owasp-password-strength-test'
import validator from 'validator'
import _ from 'lodash'
import {
  TEXT_VALIDATOR,
  CONST_VALIDATOR,
  I18NFormatMessage,
  APP_TYPE_CODE,
  SVG_DETECTOR_RE,
} from 'config'
import { DisplayUtils, StringUtils } from 'utils'

export default class ValidationUtils {
  static isPositiveInteger(val) {
    if (this.empty(val)) return false
    try {
      if ((Number.isInteger(val) && val > 0) || (validator.isInt(val) && parseInt(val, 10) > 0))
        return true
      if (typeof val !== 'string') {
        return false
      }
      return false
    } catch (e) {
      return false
    }
  }

  static isDate(val) {
    try {
      if (Date.parse(val)) return true
    } catch (e) {
      return false
    }
    return false
  }

  static empty(obj) {
    // undefined, null, {}, ''
    return _.isEmpty(obj)
  }

  static emptyHTML(htmlText) {
    let txt = DisplayUtils.stripHtmlTag(htmlText)
    txt = StringUtils.replaceAll(txt, '&nbsp;', '')
    txt = StringUtils.trim(txt)
    return this.empty(txt)
  }

  static email(intl, email) {
    if (this.empty(email)) return false
    return validator.isEmail(email)
  }

  static password(intl, passwd = '') {
    owasp.config({
      allowPassphrases: false,
      minLength: CONST_VALIDATOR.AUTH.PASSWORD.MIN,
      maxLength: CONST_VALIDATOR.AUTH.PASSWORD.MAX,
      minOptionalTestsToPass: 4,
    })
    const result = owasp.test(passwd || '')
    const errors = {
      'The password must contain at least one uppercase letter.':
        TEXT_VALIDATOR.PASSWORD_AT_LEAST_1_UPPER_CASE,
      'The password must contain at least one number.': TEXT_VALIDATOR.PASSWORD_AT_LEAST_1_NUMBER,
      'The password must contain at least one special character.':
        TEXT_VALIDATOR.PASSWORD_AT_LEAST_1_SPECIAL_CHAR,
      'The password may not contain sequences of three or more repeated characters.':
        TEXT_VALIDATOR.PASSWORD_MAY_NOT_CONTAIN_SEQUENCES,
    }
    errors[`The password must be at least ${CONST_VALIDATOR.AUTH.PASSWORD.MIN} characters long.`] =
      TEXT_VALIDATOR.TEXT_AT_LEAST_N_LENGTH
    errors[`The password must be fewer than ${CONST_VALIDATOR.AUTH.PASSWORD.MAX} characters.`] =
      TEXT_VALIDATOR.TEXT_MAX_N_LENGTH
    if (result.errors.length > 0) {
      const errorMessage = result.errors[0]
      let errorObject = null
      if (errors[errorMessage] === TEXT_VALIDATOR.TEXT_AT_LEAST_N_LENGTH)
        errorObject = {
          id: TEXT_VALIDATOR.TEXT_AT_LEAST_N_LENGTH,
          values: { N: CONST_VALIDATOR.AUTH.PASSWORD.MIN },
        }
      else if (errors[errorMessage] === TEXT_VALIDATOR.TEXT_MAX_N_LENGTH)
        errorObject = {
          id: TEXT_VALIDATOR.TEXT_MAX_N_LENGTH,
          values: { N: CONST_VALIDATOR.AUTH.PASSWORD.MAX },
        }
      return errorObject
        ? Promise.reject(new Error(I18NFormatMessage(intl, errorObject)))
        : Promise.reject(new Error(I18NFormatMessage(intl, errors[errorMessage]) || errorMessage))
    }
    return Promise.resolve()
  }

  static passwordConfirm(intl, passwd, passwdConfirm) {
    if (this.empty(passwdConfirm) || passwd === passwdConfirm) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(I18NFormatMessage(intl, TEXT_VALIDATOR.PASSWORD_UNMATCHED)))
  }

  static minMax(intl, info = '', customLength) {
    const validators = {
      ...CONST_VALIDATOR.INPUT_TEXT,
      ...customLength,
    }
    const txt = info.trim()
    let error = null

    if (this.empty(txt) || txt.length < validators.MIN)
      error = {
        id: TEXT_VALIDATOR.TEXT_AT_LEAST_N_LENGTH,
        values: { N: validators.MIN },
      }
    else if (txt.length > validators.MAX)
      error = {
        id: TEXT_VALIDATOR.TEXT_MAX_N_LENGTH,
        values: { N: validators.MAX },
      }
    if (error) return Promise.reject(new Error(I18NFormatMessage(intl, error)))
    return Promise.resolve()
  }

  static isAlphanumeric(txt) {
    if (this.empty(txt)) return false
    return /^[a-zA-Z0-9\.\-\+\_\=]*$/.test(txt)
  }

  static isAlphabet(txt) {
    if (this.empty(txt)) return false
    return /^[a-zA-Z]*$/.test(txt)
  }

  static isAlphabetOrSpace(txt) {
    if (this.empty(txt)) return false
    return /^[a-zA-Z\s]*$/.test(txt)
  }

  static isVietnameseAlphabetOrSpace(txt) {
    if (this.empty(txt)) return false
    return /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/.test(
      txt,
    )
  }

  static isNumeric(txt) {
    if (this.empty(txt)) return false
    return /^[0-9]*$/.test(txt)
  }

  static isValidRichTextEditorContent(editorRef, type) {
    const condition = {}
    condition[APP_TYPE_CODE.NEWS_COMMENT] = CONST_VALIDATOR.NEWS.COMMENT
    condition[APP_TYPE_CODE.FORUM_TOPIC] = CONST_VALIDATOR.FORUM.TOPIC_CONTENT
    condition[APP_TYPE_CODE.FORUM_POST] = CONST_VALIDATOR.FORUM.POST
    const htmlContent = editorRef.current.getContent()
    const content = editorRef.current.getContent({ format: 'text' }).trim()

    if (htmlContent.match(SVG_DETECTOR_RE)) return [false, TEXT_VALIDATOR.SVG_PHOTO_BLOCKED]

    // there is only emotion icon
    // <p><img style="width: 1em; height: 1em; margin: 0 .05em 0 .1em; vertical-align: -.1em;" draggable="false" src="https://twemoji.maxcdn.com/v/13.0.1/72x72/1f603.png" alt="😃" data-emoticon="true" /></p>
    if (
      !(
        // not only emotion icon
        (
          [APP_TYPE_CODE.NEWS_COMMENT, APP_TYPE_CODE.FORUM_POST].includes(type) &&
          htmlContent.includes(CONST_VALIDATOR.HTML_CONTENT_EMOTION_ICON_PATTERN)
        )
      ) &&
      content.length < condition[type].MIN
    )
      return [
        false,
        {
          id: TEXT_VALIDATOR.TEXT_AT_LEAST_N_LENGTH,
          values: { N: condition[type].MIN },
        },
      ]
    if (content.length > condition[type].MAX)
      return [
        false,
        {
          id: TEXT_VALIDATOR.TEXT_MAX_N_LENGTH,
          values: { N: condition[type].MAX },
        },
      ]
    return [true, null]
  }

  static formHasError(antForm) {
    return _.some(antForm.getFieldsError(), (item) => item.errors.length > 0)
  }

  static formValidateFields(antForm) {
    // {
    //   "name": ["newPassword"],
    //   "errors": ["Please enter at least 8 characters"]
    // }
    if (this.formHasError(antForm)) {
      const fields = []
      for (const { name, errors } of antForm.getFieldsError()) {
        if (errors.length > 0) fields.push(name[0])
      }
      antForm.validateFields(fields)
    }
  }

  static formRequireRule(intl, messageId = TEXT_VALIDATOR.REQUIRED) {
    return {
      required: true,
      whitespace: true,
      message: I18NFormatMessage(intl, messageId),
    }
  }
}
