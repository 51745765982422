import React from 'react'
import { Modal } from 'antd'
import { TEXT_BUTTON } from 'config'
import topRight from 'assets/image/modal/top-right.png'
import bottomLeft from 'assets/image/modal/bottom-left.png'
import styles from './style.module.scss'

export default ({
  onOK,
  onCancel,
  okText,
  cancelText = TEXT_BUTTON.CANCEL,
  okButtonProps,
  title,
  visible,
  disableClosable,
  children,
  width,
  footer,
  centered,
}) => (
  <Modal
    title={<span className="typography-menu">{title}</span>}
    visible={visible}
    maskClosable={!disableClosable}
    className={styles['modal-view']}
    onCancel={onCancel}
    cancelText={cancelText}
    cancelButtonProps={{ className: 'ant-btn-cancel' }}
    onOk={onOK}
    okType="primary"
    okText={okText}
    okButtonProps={okButtonProps}
    width={width}
    footer={footer}
    centered={centered}
  >
    <img src={topRight} alt="top-right" className={styles['img-top-right']} />
    <img src={bottomLeft} alt="bottom-left" className={styles['img-bottom-left']} />
    <div className={styles.content}>{children}</div>
  </Modal>
)
