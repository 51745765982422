import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Layout as AntLayout, Button } from 'antd'
import { FE_ROUTE, TEXT_UI_LAYOUT, TEXT_BUTTON } from 'config'
import { HighlightNotification } from 'components'
import Header from './header'
import Footer from './footer'
import styles from './style.module.scss'

const { Content } = AntLayout

export default function Layout({ children }) {
  const [news, setNews] = useState('')
  const [newsAction, setNewsAction] = useState(<> button</>)
  const notiDisplay = useMemo(() => (news ? 'inline-flex' : 'none'), [news])

  // todo: disable until release
  // useEffect(() => {
  //   // set default news
  //   setNews(
  //     <span className="typography-h5-bold-01">
  //       Code Challenge #1 sẽ diễn ra vào lúc 19h00 ngày 01/06/2021
  //     </span>,
  //   )
  //   // set news action
  //   setNewsAction(
  //     <Link to={FE_ROUTE.AUTH.REGISTER}>
  //       <Button className="typography-small-02">{TEXT_UI_LAYOUT.HEADER.REGISTER_NOW}</Button>
  //     </Link>,
  //   )
  // }, [])

  return (
    <AntLayout className={styles.layout}>
      <Header />
      <Content className={styles.content}>
        <HighlightNotification
          style={{ display: notiDisplay }}
          news={news}
          action={newsAction}
          onClose={() => setNews('')}
        />
        <Link to={FE_ROUTE.CONTEST.HOME}>
          <div className={styles['join-now-cta']}>{TEXT_BUTTON.JOIN_NOW}</div>
        </Link>
        {children}
      </Content>
      <Footer />
    </AntLayout>
  )
}
