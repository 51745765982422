import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import classnames from 'classnames'
import { Form, Input } from 'antd'
import { CustomModal, LoadingIndicator } from 'components'
import {
  I18NFormatMessage,
  RECAPTCHA_SITE_KEY,
  TEXT_BUTTON,
  TEXT_NOTIFICATION_SUCCESS,
  TEXT_UI_FORUM,
} from 'config'
import { DisplayUtils, ErrorHandlerUtils, ValidationUtils, ReCaptchaUtils } from 'utils'
import { ForumService } from 'services'

export default ({ isShown, onClose, onOK, postId, index, page }) => {
  const intl = useIntl()
  const [reason, setReason] = useState('')
  const [isLoadingShown, setIsLoadingShown] = useState(false)
  const recaptchaRef = useRef()
  const [form] = Form.useForm()

  const okButtonProps = {
    disabled: !reason,
    className: classnames({ 'ant-btn-primary-disabled': !reason }),
  }

  const onSubmit = async () => {
    if (!ValidationUtils.formHasError(form)) {
      const reasonValue = form.getFieldValue('reason')
      form.resetFields()
      const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
      setIsLoadingShown(true)
      ForumService.reportPost(postId, {
        recaptchaToken,
        isReportTopic: index === 1,
        page,
        reason: reasonValue,
      })
        .then((resp) => {
          ReCaptchaUtils.reset(recaptchaRef)
          setIsLoadingShown(false)
          DisplayUtils.showNotification(
            I18NFormatMessage(intl, TEXT_NOTIFICATION_SUCCESS.SEND_SUCCESS),
          )
          onOK()
        })
        .catch((error) => {
          ReCaptchaUtils.reset(recaptchaRef)
          setIsLoadingShown(false)
          ErrorHandlerUtils.http(error, intl)
        })
    }
  }

  const onCancel = () => {
    form.resetFields()
    setReason('')
    onClose()
  }

  return (
    <CustomModal
      title={TEXT_UI_FORUM.REPORT_CONTENT}
      visible={isShown}
      onCancel={onCancel}
      onOK={onSubmit}
      okText={TEXT_BUTTON.REPORT}
      okButtonProps={okButtonProps}
    >
      <LoadingIndicator isHidden={!isLoadingShown}>
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size="invisible" ref={recaptchaRef} />
        <Form layout="vertical" form={form}>
          <Form.Item
            name="reason"
            key="reason"
            label={<div className="typography-small">{TEXT_UI_FORUM.REPORT_REASON}</div>}
            rules={[
              () => ({
                validator(nul, value) {
                  return ValidationUtils.minMax(intl, value)
                },
              }),
            ]}
          >
            <Input onChange={(e) => setReason(e.target.value)} />
          </Form.Item>
        </Form>
      </LoadingIndicator>
    </CustomModal>
  )
}
