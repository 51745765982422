import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import Loadable from 'react-loadable'
import Layout from 'pages/layout'
import NotFound404 from 'pages/error/notFound'
import { LoadingIndicator, PrivateRoute } from 'components'
import { FE_ROUTE } from 'config'
import { MiscUtils } from './utils'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <LoadingIndicator />,
  })

const publicRoutes = [
  // ----- Error -----
  {
    path: FE_ROUTE.ERROR.FORBIDDEN,
    exact: true,
    component: loadable(() => import('pages/error/forbidden')),
  },
  {
    path: FE_ROUTE.ERROR.FORBIDDEN_LOGOUT,
    exact: true,
    component: loadable(() => import('pages/error/forbiddenLogout')),
  },
  {
    path: FE_ROUTE.ERROR.NOT_FOUND,
    exact: true,
    component: loadable(() => import('pages/error/notFound')),
  },

  // ----- Auth -----
  {
    path: FE_ROUTE.AUTH.REGISTER,
    exact: true,
    component: loadable(() => import('pages/auth')),
  },
  {
    path: FE_ROUTE.AUTH.LOGIN,
    exact: true,
    component: loadable(() => import('pages/auth')),
  },
  {
    path: FE_ROUTE.AUTH.LOGIN_ZALO,
    exact: true,
    component: loadable(() => import('pages/auth/LoginZalo')),
  },
  {
    path: FE_ROUTE.AUTH.LOGOUT,
    exact: true,
    component: loadable(() => import('pages/auth/Logout')),
  },
  {
    path: FE_ROUTE.ACTIVATE.REGISTRATION,
    exact: true,
    component: loadable(() => import('pages/activate/ActivateRegistrationView')),
  },
  {
    path: FE_ROUTE.ACTIVATE.FORGOT_PASSWORD,
    exact: true,
    component: loadable(() => import('pages/activate/ActivateForgotPasswordView')),
  },

  // ----- Home -----
  {
    path: '/',
    exact: true,
    component: loadable(() => import('pages/home')),
  },

  // ----- Contest -----
  {
    path: FE_ROUTE.CONTEST.HOME,
    exact: true,
    component: loadable(() => import('pages/contest')),
  },
  {
    path: FE_ROUTE.CONTEST.PUBLIC,
    exact: true,
    component: loadable(() => import('pages/contest/public')),
  },
  {
    path: `${FE_ROUTE.CONTEST.HOME}/public/:id`,
    exact: true,
    component: loadable(() => import('pages/contest/detail')),
  },

  // in every contest information page
  {
    path: FE_ROUTE.CONTEST.JUDGEMENT_ENVIRONMENT,
    exact: true,
    component: loadable(() => import('pages/contest/detail/information/judgementEnvironment')),
  },
  {
    path: FE_ROUTE.CONTEST.JUDGEMENT_RANKING,
    exact: true,
    component: loadable(() => import('pages/contest/detail/information/judgementRanking')),
  },

  // ----- Training -----

  // view user public profile
  {
    path: `${FE_ROUTE.USER.PROFILE}/:id`,
    exact: true,
    component: loadable(() => import('pages/myProfile')),
  },

  // ----- News -----
  {
    path: FE_ROUTE.NEWS.HOME,
    exact: true,
    component: loadable(() => import('pages/news')),
  },
  {
    path: FE_ROUTE.NEWS.VIEW_NEWS,
    exact: true,
    component: loadable(() => import('pages/news/view')),
  },

  // ----- Forums -----
  {
    path: FE_ROUTE.FORUM.HOME,
    exact: true,
    component: loadable(() => import('pages/forum/forum/list')),
  },
  {
    path: FE_ROUTE.FORUM.VIEW_FORUM,
    exact: true,
    component: loadable(() => import('pages/forum/forum/view')),
  },
  {
    path: FE_ROUTE.FORUM.VIEW_TOPIC,
    exact: true,
    component: loadable(() => import('pages/forum/topic/view')),
  },
  {
    path: FE_ROUTE.FORUM.SEARCH,
    exact: true,
    component: loadable(() => import('pages/forum/search')),
  },

  // ----- Leaderboard -----
  {
    path: FE_ROUTE.LEADER_BOARD.HOME,
    exact: true,
    component: loadable(() => import('pages/leaderboard')),
  },
  {
    path: FE_ROUTE.LEADER_BOARD.CRITERIA,
    exact: true,
    component: loadable(() => import('pages/leaderboard/criteria')),
  },

  // ----- Misc -----
  {
    path: FE_ROUTE.MISC.ABOUT_US,
    exact: true,
    component: loadable(() => import('pages/aboutUs')),
  },
  {
    path: FE_ROUTE.MISC.FAQ,
    component: loadable(() => import('pages/faq')),
  },
  {
    path: FE_ROUTE.MISC.TOS,
    exact: true,
    component: loadable(() => import('pages/term')),
  },
  {
    path: FE_ROUTE.MISC.PRIVACY,
    exact: true,
    component: loadable(() => import('pages/privacy')),
  },
]

const privateRoutes = [
  // ----- Contest -----
  {
    path: FE_ROUTE.CONTEST.PRIVATE,
    exact: true,
    component: loadable(() => import('pages/contest/private')),
  },
  {
    path: `${FE_ROUTE.CONTEST.HOME}/private/:id`,
    exact: true,
    component: loadable(() => import('pages/contest/detail')),
  },

  // ----- User -----
  {
    path: FE_ROUTE.USER.CHANGE_PASSWORD,
    exact: true,
    component: loadable(() => import('pages/changePassword')),
  },
  {
    path: FE_ROUTE.USER.LINK_ACCOUNT,
    exact: true,
    component: loadable(() => import('pages/linkAccount')),
  },
  {
    path: FE_ROUTE.USER.INFORMATION,
    exact: true,
    component: loadable(() => import('pages/userProfile')),
  },
  {
    path: FE_ROUTE.USER.FILLUP_INFORMATION,
    exact: true,
    component: loadable(() => import('pages/fillupProfile')),
  },
  {
    path: FE_ROUTE.FORUM.NEW_TOPIC,
    exact: true,
    component: loadable(() => import('pages/forum/topic/create')),
  },
  // {
  //   path: FE_ROUTE.USER.TEAM_MANAGEMENT,
  //   exact: true,
  //   component: loadable(() => import('pages/team')),
  // },
]

export default ({ history }) => (
  <ConnectedRouter history={history}>
    <Layout>
      <Route exact path="/auth/login/linkedin" component={LinkedInPopUp} />
      <Switch>
        {publicRoutes.map((route) => (
          <Route
            key={MiscUtils.generateId()}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}
        {privateRoutes.map((route) => (
          <PrivateRoute
            key={MiscUtils.generateId()}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}
        <Route path="/" component={NotFound404} />
      </Switch>
    </Layout>
  </ConnectedRouter>
)
