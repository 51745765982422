export const FE_ROUTE = {
  DEFAULT_ROUTE: '/',
  ERROR: {
    FORBIDDEN: '/error/forbidden',
    FORBIDDEN_LOGOUT: '/error/forbidden-logout',
    NOT_FOUND: '/error/not-found',
  },
  AUTH: {
    ROOT: '/auth',
    LOGIN: '/auth/login',
    LOGIN_ZALO: '/auth/login-zalo',
    LOGOUT: '/auth/logout',
    REGISTER: '/auth/register',
    LOGIN_LINKEDIN_CALLBACK: '/auth/login/linkedin',
  },
  ACTIVATE: {
    ROOT: '/activate',
    REGISTRATION: '/activate/registration',
    FORGOT_PASSWORD: '/activate/forgot',
  },
  CONTEST: {
    HOME: '/contest',
    PUBLIC: '/contest/public',
    PRIVATE: '/contest/private',
    JUDGEMENT_ENVIRONMENT: '/contest/judgement-environment',
    JUDGEMENT_RANKING: '/contest/judgement-ranking',
  },
  TRAINING: {
    HOME: '/training',
  },
  NEWS: {
    HOME: '/news',
    VIEW_NEWS: '/news/:newsId',
  },
  FORUM: {
    HOME: '/forum',
    SEARCH: '/forum/search/topic',
    VIEW_FORUM: '/forum/:forumId',
    VIEW_TOPIC: '/forum/topic/:topicId',
    NEW_TOPIC: '/forum/:forumId/new-topic',
  },
  LEADER_BOARD: {
    HOME: '/ranking',
    CRITERIA: '/ranking/criteria',
  },
  USER: {
    FILLUP_INFORMATION: '/user/fillup_information',
    INFORMATION: '/user/information',
    PROFILE: '/user/profile',
    CHANGE_PASSWORD: '/user/change-password',
    LINK_ACCOUNT: '/user/link-account',
    TEAM_MANAGEMENT: '/user/team-management',
  },
  MISC: {
    ABOUT_US: '/about-us',
    TOS: '/terms',
    PRIVACY: '/privacy',
    FAQ: '/faqs',
    VNG_CHANNEL: {
      FACEBOOK: 'https://www.facebook.com/LifeAtVNG', // https://www.facebook.com/VNGCorporation.Page',
      INSTAGRAM: 'https://www.instagram.com/life_at_vng',
      LINKEDIN: 'https://www.linkedin.com/company/vng-corporation/mycompany',
      YOUTUBE: 'https://www.youtube.com/channel/UCwyo2uR5GNjGnhAyypuHHMQ',
      YOUTUBE_INTRO_CLIP: 'https://www.youtube.com/watch?v=ehOfQf1JmNo', // https://www.youtube.com/embed/3gRQ5NdlpCg
    },
  },
}

export const BE_ROUTE = {
  HOME: {
    BASE: '/home',
    PATH: {
      TESTIMONIALS: '/testimonials',
      HIGHLIGHT_CONTESTS: '/highlight-contests',
    },
  },
  AUTH: {
    BASE: '/auth',
    PATH: {
      REGISTER: '/register',
      REGISTER_SOCIAL: '/register-social',
      REGISTER_GET_CODE_CHALLENGE: '/register-get-code-challenge',
      LOGIN: '/login',
      LOGIN_SOCIAL: '/login-social',
      LOGIN_SOCIAL_CHECK_ZALO_EXIST: '/login-social-check-zalo-exist',
      FORGOT_PASSWORD: '/forgot-password',
      LOGOUT: '/logout',
      RESEND_REGISTRATION_EMAIL: '/resend-registration-email',
    },
  },
  ACTIVATE: {
    BASE: '/activate',
    PATH: {
      REGISTRATION: '/registration',
      FORGOT_PASSWORD: '/forgot-password',
    },
  },
  USER: {
    BASE: '/user',
    PATH: {
      SYNC: '/sync',
      SYNC_STATISTIC: '/sync-statistic',
      LOGOUT_CONTEST_SESSION: '/logout-contest-session',
    },
  },
  CONTEST: {
    BASE: '/contest',
    PATH: {
      PUBLIC: '/public',
      PRIVATE: '/private',
      ADD_TO_CALENDAR: '/add-to-calendar',
      REGISTER: '/register',
      VISIT_ROOM: '/visit-room',
      DETAIL: '/:id/detail',
      SCORE_BOARD: '/:id/scoreboard',
      TOP_USERS: '/:id/top-users',
    },
  },
  INFORMATION: {
    BASE: '/information',
    PATH: {
      CHANGE_PASSWORD: '/change-password',
      FORGOT_PASSWORD: '/forgot-password',
      AVATAR: '/avatar',
      TEAM: '/team',
      CONNECT_SOCIAL: '/connect-social',
      DISCONNECT_SOCIAL: '/disconnect-social',
      HAS_OLD_PASSWORD: '/has-old-password',
      CHANGE_USERNAME: '/change-username',
      WORK_EXPERIENCE: '/work-experience/:id',
      EDUCATION: '/education/:id',
      SET_LOCALE: '/set-locale',
      CERTIFICATION_CONTEST: '/certificates/contests/:contestId',
    },
  },
  PROFILE: {
    BASE: '/profile',
    PATH: {
      ATTENDED_CONTESTS: '/attended-contests/:userId',
    },
  },
  NEWS: {
    BASE: '/news',
    PATH: {
      DETAIL: '/:id',
      COMMENT: '/:id/comment',
      REPLY_TO_COMMENT: '/:id/comment/:commentId',
      LIKE_COMMENT_OR_REPLY: '/:id/comment/:commentId/like',
      TAGS: '/tags',
    },
  },
  TEAM: {
    BASE: '/team',
    PATH: {
      CREATE: '/create',
      DELETE: '/delete',
      ADD_MEMBER: '/add-member',
      DELETE_MEMBER: '/delete-member',
      RENAME: '/rename',
    },
  },
  FORUM: {
    BASE: '/forum',
    PATH: {
      CATEGORY: '/category',
      FORUM: '/forum',
      TOPIC: '/topic',
      POST: '/post',
    },
  },
  FAQ: {
    BASE: '/faq',
    PATH: {
      CATEGORY: '/category',
    },
  },
  SETTING: {
    BASE: '/setting',
    PATH: {
      CONTENT: '/content',
      GET_COUNTRY: '/country',
      GET_UNIVERSITY: '/university',
      GET_MAJOR: '/education-majors',
    },
  },
  LEADERBOARD: {
    BASE: '/leaderboard',
    PATH: {
      ANNUAL: '/annual',
      ANNUAL_DETAIL: '/annual/detail',
      CUMULATIVE: '/cumulative',
    },
  },
  SHARE: {
    BASE: '/share',
    PATH: {
      NEWS: '/news/:id',
      FORUM_TOPIC: '/topic/:id',
    },
  },
  NOTIFICATION: {
    BASE: '/notification',
    PATH: {
      VIEW: '/view/:id',
      COUNT_UNREAD: '/count-unread',
    },
  },
}
