import { GOOGLE_CALENDAR_API_KEY, SNS_LOGIN_CONFIG } from 'config'

export default class GoogleAPIUtils {
  // https://www.linkedin.com/pulse/add-events-google-calendar-reactjs-alex-gordon
  // https://developers.google.com/calendar/api/quickstart/js
  // request event body: https://developers.google.com/calendar/api/v3/reference/events#resource-representations
  // response: https://developers.google.com/calendar/api/v3/reference/events#resource
  // delete: https://developers.google.com/calendar/api/v3/reference/events/delete
  static addToCalendar(
    eventName,
    beginTime,
    endTime,
    location,
    reminderBefore,
    reminderContent,
    reminderGoogleCalenderDefaultTimezone,
    successCallback,
  ) {
    // google calendar api
    const { gapi } = window
    const CLIENT_ID = SNS_LOGIN_CONFIG.GOOGLE_APP_ID
    const API_KEY = GOOGLE_CALENDAR_API_KEY
    const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']
    const SCOPES = 'https://www.googleapis.com/auth/calendar.events'

    // ----- gapi: begin -----
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      gapi.client.load('calendar', 'v3')
      // time zone list:
      // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      // check if currentUser has timezone, if not then set to reminderGoogleCalenderDefaultTimezone
      const timeZone = reminderGoogleCalenderDefaultTimezone

      // sign in with pop up window
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: eventName, // or event name
            location, // where it would happen, will be shown with Google map URL
            description: reminderContent,
            start: {
              dateTime: beginTime, // "2021-10-15T16:40:00+07:00 or 2021-10-24T06:00:00.000Z",
              timeZone,
            },
            end: {
              dateTime: endTime,
              timeZone,
            },

            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'popup', minutes: reminderBefore },
                { method: 'email', minutes: reminderBefore },
              ],
            },
          }
          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          })
          request.execute((addedEvent) => {
            if (successCallback) successCallback(addedEvent.id)
            window.open(addedEvent.htmlLink)
          })
        })
    })
    // ----- gapi: end -----
  }

  static removeFromCalendar(googleEventId, successCallback) {
    // google calendar api
    const { gapi } = window
    const CLIENT_ID = SNS_LOGIN_CONFIG.GOOGLE_APP_ID
    const API_KEY = GOOGLE_CALENDAR_API_KEY
    const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']
    const SCOPES = 'https://www.googleapis.com/auth/calendar.events'

    // ----- gapi: begin -----
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      gapi.client.load('calendar', 'v3')

      // sign in with pop up window
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const request = gapi.client.calendar.events.delete({
            calendarId: 'primary',
            eventId: googleEventId,
          })
          request.execute(() => {
            if (successCallback) successCallback()
          })
        })
    })
    // ----- gapi: end -----
  }
}
