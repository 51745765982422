import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Drawer, Breadcrumb, Collapse } from 'antd'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
import { FE_ROUTE, TEXT_UI_LAYOUT, TEXT_UI_USER_PROFILE, USER_PROFILE_TAB_KEY } from 'config'
import { MiscUtils, URLUtils } from 'utils'
import styles from './style.module.scss'
import './style.overwrite.scss'

const { Item } = Breadcrumb
const { Panel } = Collapse

export default () => {
  const breadcrumbItems = [<AiOutlineMenuUnfold onClick={() => setIsDrawerShown(true)} />]
  const [isDrawerShown, setIsDrawerShown] = useState(false)
  const currentPathname = URLUtils.getPathnameInURL()
  let currentTab = null
  if (currentPathname === FE_ROUTE.USER.CHANGE_PASSWORD) {
    breadcrumbItems.push(TEXT_UI_LAYOUT.HEADER.DROPDOWN_CHANGE_PASSWORD)
  } else {
    currentTab = URLUtils.getParamsInURL().tab
    if (!currentTab && currentPathname === FE_ROUTE.USER.INFORMATION)
      currentTab = USER_PROFILE_TAB_KEY.INFORMATION
    breadcrumbItems.push(TEXT_UI_USER_PROFILE.PERSONAL_INFORMATION.TITLE)

    if (currentTab === USER_PROFILE_TAB_KEY.INFORMATION)
      breadcrumbItems.push(TEXT_UI_USER_PROFILE.PERSONAL_INFORMATION.TITLE_SHORT)
    else if (currentTab === USER_PROFILE_TAB_KEY.EDUCATION_WORK_EXPERIENCE)
      breadcrumbItems.push(TEXT_UI_USER_PROFILE.EDUCATION_WORK_EXPERIENCE.TITLE)
    else if (currentTab === USER_PROFILE_TAB_KEY.WORK_EXPERIENCE)
      breadcrumbItems.push(TEXT_UI_USER_PROFILE.WORK_EXPERIENCE.TITLE)
    else if (currentTab === USER_PROFILE_TAB_KEY.EDUCATION)
      breadcrumbItems.push(TEXT_UI_USER_PROFILE.EDUCATION.TITLE)
  }

  return (
    <>
      <Breadcrumb className={styles.breadcrumb} separator=">">
        {breadcrumbItems.map((item) => (
          <Item key={MiscUtils.generateId()}>{item}</Item>
        ))}
      </Breadcrumb>
      <Drawer
        placement="left"
        onClose={() => setIsDrawerShown(false)}
        visible={isDrawerShown}
        closable={false}
      >
        <Collapse defaultActiveKey={['1']} ghost>
          <Panel
            header={TEXT_UI_USER_PROFILE.PERSONAL_INFORMATION.TITLE}
            key="1"
            className={classnames({ currentActive: currentPathname === FE_ROUTE.USER.INFORMATION })}
          >
            <p className={classnames({ bold: currentTab === USER_PROFILE_TAB_KEY.INFORMATION })}>
              <a
                onClick={() =>
                  URLUtils.moveToURL(
                    `${FE_ROUTE.USER.INFORMATION}?tab=${USER_PROFILE_TAB_KEY.INFORMATION}`,
                  )
                }
              >
                {TEXT_UI_USER_PROFILE.PERSONAL_INFORMATION.TITLE_SHORT}
              </a>
            </p>
            <p
              className={classnames({
                bold: currentTab === USER_PROFILE_TAB_KEY.EDUCATION_WORK_EXPERIENCE,
              })}
            >
              <a
                onClick={() =>
                  URLUtils.moveToURL(
                    `${FE_ROUTE.USER.INFORMATION}?tab=${USER_PROFILE_TAB_KEY.EDUCATION_WORK_EXPERIENCE}`,
                  )
                }
              >
                {TEXT_UI_USER_PROFILE.EDUCATION_WORK_EXPERIENCE.TITLE}
              </a>
            </p>
          </Panel>
        </Collapse>
        <p className={classnames({ bold: currentPathname === FE_ROUTE.USER.CHANGE_PASSWORD })}>
          <Link to={FE_ROUTE.USER.CHANGE_PASSWORD}>
            {TEXT_UI_LAYOUT.HEADER.DROPDOWN_CHANGE_PASSWORD}
          </Link>
        </p>
        <p className={classnames({ bold: currentPathname === FE_ROUTE.USER.LINK_ACCOUNT })}>
          <Link to={FE_ROUTE.USER.LINK_ACCOUNT}>
            {TEXT_UI_USER_PROFILE.SOCIAL_CONNECTION.TITLE}
          </Link>
        </p>
        {/* <p><Link to={FE_ROUTE.USER.TEAM_MANAGEMENT}>{TEXT_UI_LAYOUT.HEADER.DROPDOWN_MANAGE_TEAM}</Link></p> */}
      </Drawer>
    </>
  )
}
