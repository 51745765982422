import React from 'react'
// https://github.com/prabhuignoto/react-chrono
import { Chrono } from 'react-chrono'
// https://github.com/veysiyildiz/vertical-timeline-component-for-react
// import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react'
import './style.overwrite.scss'

export default function TimeLine({ children }) {
  return (
    <div>
      <Chrono mode="VERTICAL" hideControls cardHeight={100}>
        {children}
      </Chrono>
    </div>
  )
}
