import React, { useState, useRef } from 'react'
import { Space } from 'antd'
import { useCurrentBreakpointName } from 'react-socks'
import { Editor } from '@tinymce/tinymce-react'
import { APP_TYPE_CODE, I18NFormatMessage } from 'config'
import { DisplayUtils, ValidationUtils } from 'utils'
import ButtonSave from '../Button/Save'
import ButtonCancel from '../Button/Cancel'
import styles from './style.module.scss'

export default ({ value, textSubmit, onSubmit, onCancel, placeholder, intl }) => {
  const editorRef = useRef(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const resetEditor = () => editorRef.current.setContent('')

  const breakpoint = useCurrentBreakpointName()
  let editorHeight = 170
  if (DisplayUtils.isMobile(breakpoint)) editorHeight = 220
  else if (DisplayUtils.isTablet(breakpoint)) editorHeight = 240
  else if (DisplayUtils.isDesktopLow(breakpoint)) editorHeight = 240

  const onSubmitLocal = () => {
    const [isValid, errorData] = ValidationUtils.isValidRichTextEditorContent(
      editorRef,
      APP_TYPE_CODE.NEWS_COMMENT,
    )
    if (!isValid) {
      DisplayUtils.showNotificationError(I18NFormatMessage(intl, errorData))
      return
    }
    const text = editorRef.current.getContent()
    resetEditor()
    // add space at the end, to prevent the raw html of emotion icon
    // in case: "hello <icon>"
    onSubmit({ text })
  }

  const onCancelLocal = () => {
    resetEditor()
    if (onCancel) onCancel()
  }

  return (
    <div className={styles['input-comment']}>
      <Editor
        onInit={(evt, editor) => {
          editorRef.current = editor
        }}
        initialValue={value}
        init={{
          height: editorHeight,
          placeholder,
          // https://www.tiny.cloud/blog/emoji-picker/
          // https://www.tiny.cloud/docs/plugins/opensource/emoticons/
          plugins: 'emoticons, paste', // , autoresize',
          paste_as_text: true, // plain text : https://www.tiny.cloud/docs/plugins/opensource/paste/#paste_as_text
          toolbar: 'emoticons',
          emoticons_database: 'emojiimages', // emojis: cannot show flags in windows
          toolbar_location: 'bottom',
          menubar: false,
          statusbar: false,
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; font-size: 18px; line-height: 32px;} p { margin-block-start: 0; margin-block-end: 0; }",
        }}
        // not onChange:  https://github.com/tinymce/tinymce-react/issues/31
        // because we want the state of Submit button changed instantly when typing
        onEditorChange={() =>
          setIsDisabled(
            !ValidationUtils.isValidRichTextEditorContent(editorRef, APP_TYPE_CODE.NEWS_COMMENT)[0],
          )
        }
      />
      <div className={styles['button-comment-wrapper']}>
        <Space>
          <ButtonCancel onClick={onCancelLocal} />
          <ButtonSave text={textSubmit} onClick={onSubmitLocal} isDisabled={isDisabled} />
        </Space>
      </div>
    </div>
  )
}
