import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './auth/reducer'
import localeReducer from './locale/reducer'
import notificationReducer from './notification/reducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    localeReducer,
    notificationReducer,
  })
