import { ACTION } from './actions'

const initialState = {
  locale: null,
}

const LocaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_LOCALE: {
      return {
        ...state,
        locale: action.data.locale,
      }
    }

    default: {
      return state
    }
  }
}

export default LocaleReducer
