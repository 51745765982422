export default class ReCaptchaUtils {
  static async getValue(ref) {
    const val = await ref.current?.executeAsync()
    return val // dont return await directly
  }

  static reset(ref) {
    return ref.current?.reset()
  }
}
