import { all, put, takeEvery } from 'redux-saga/effects'
import { ACTION } from './actions'

function* SET_LOCALE({ data: { locale } }) {
  yield put({
    type: ACTION.SET_LOCALE,
    data: { locale },
  })
}

export default function* LocaleSaga() {
  yield all([takeEvery(ACTION.SET_LOCALE_TRIGGER, SET_LOCALE)])
}
