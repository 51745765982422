const ACTION = {
  SET_LOCALE_TRIGGER: 'SET_LOCALE_TRIGGER',
  SET_LOCALE: 'SET_LOCALE',
}

const setLocaleAction = (locale) => ({
  type: ACTION.SET_LOCALE_TRIGGER,
  data: { locale },
})

module.exports = {
  ACTION,
  setLocaleAction,
}
