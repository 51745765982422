import React from 'react'
import { Spin } from 'antd'

export default ({ children, size = null, isHidden = true, delay = 200 }) => (
  <div>
    {children}
    <div className="ta-center p-t-5 p-b-5">
      <Spin size={size} delay={delay} spinning={!isHidden} />
    </div>
  </div>
)
