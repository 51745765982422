import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class HomeService {
  static getTestimonials() {
    const url = URLUtils.buildBeURL(BE_ROUTE.HOME.BASE, BE_ROUTE.HOME.PATH.TESTIMONIALS)
    return HttpService.get(url)
  }

  static getHighLightContests() {
    const url = URLUtils.buildBeURL(BE_ROUTE.HOME.BASE, BE_ROUTE.HOME.PATH.HIGHLIGHT_CONTESTS)
    return HttpService.get(url)
  }
}
