import React from 'react'
import { Tooltip } from 'antd'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

export default ({ text, tooltip }) => (
  <span>
    {text}&nbsp;
    <Tooltip title={tooltip}>
      <AiOutlineQuestionCircle className="vertical-align-middle" />
    </Tooltip>
  </span>
)
