import React, { useState, useEffect, useRef } from 'react'
import { ButtonCancel, CustomModal, LoadingIndicator } from 'components'
import { TEXT_BUTTON, TEXT_UI_HOME, FE_ROUTE } from 'config'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

export default ({ isShown, onCancel, onOK }) => {
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(isShown)

  const onNavigateToFillupProfile = () => {
    history.push(`${FE_ROUTE.USER.FILLUP_INFORMATION}`)
  }

  const closeModal = () => {
    setModalVisible(false)
    onCancel()
  }

  useEffect(() => {
    setModalVisible(isShown)
  }, [isShown])

  return (
    <CustomModal
      title={TEXT_UI_HOME.PROFILE_MODAL.TITLE}
      visible={modalVisible}
      onCancel={closeModal}
      footer={[
        <ButtonCancel onClick={closeModal} />,
        <Button type="primary" onClick={onNavigateToFillupProfile}>
          {TEXT_BUTTON.CONTINUE}
        </Button>,
      ]}
    >
      <LoadingIndicator isHidden>
        <div className="m-t-20 m-b-20 typography-small">
          <div>{TEXT_UI_HOME.PROFILE_MODAL.CONTENT}</div>
        </div>
      </LoadingIndicator>
    </CustomModal>
  )
}
