import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class FaqService {
  static getCategories(locale) {
    const url = URLUtils.buildBeURL(BE_ROUTE.FAQ.BASE, BE_ROUTE.FAQ.PATH.CATEGORY)
    return HttpService.get(url, { locale })
  }
}
