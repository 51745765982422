import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Card } from 'antd'
import { TEXT_UI_USER_PROFILE, FE_ROUTE } from 'config'
import { MiscUtils } from 'utils'
import { DesktopOnly, MobileOrTabletOnly } from 'components'
import CustomEditAvatar from './CustomEditAvatar'
import BreadcrumbNavigatorInMobile from './BreadcrumbNavigatorInMobile'
import styles from './style.module.scss'

const ComponentView = ({ children, pathname }) => {
  const leftMenu = [
    {
      link: FE_ROUTE.USER.INFORMATION,
      label: TEXT_UI_USER_PROFILE.PERSONAL_INFORMATION.TITLE,
    },
    {
      link: FE_ROUTE.USER.CHANGE_PASSWORD,
      label: TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.TITLE,
    },
    {
      link: FE_ROUTE.USER.LINK_ACCOUNT,
      label: TEXT_UI_USER_PROFILE.SOCIAL_CONNECTION.TITLE,
    },
    // {
    //   link: FE_ROUTE.USER.TEAM_MANAGEMENT,
    //   label: TEXT_UI_USER_PROFILE.TEAM_MANAGEMENT.TITLE,
    // },
  ]

  const generateLeftMenuContent = (leftMenuConfig) =>
    leftMenuConfig.map((item) => (
      <div key={MiscUtils.generateId()}>
        <Link to={item.link}>
          <span
            className={classnames('typography-main-regular vertical-menu-list-item', {
              'text-button-current-selected': pathname === item.link,
            })}
          >
            {item.label}
          </span>
        </Link>
      </div>
    ))

  return (
    <div className="full-width">
      <div className={classnames('center-content', styles.content)}>
        <DesktopOnly>
          <div className={classnames('typography-subtitle-regular', styles.title)}>
            {TEXT_UI_USER_PROFILE.MANAGE_ACCOUNT}
          </div>
        </DesktopOnly>
        <div className={styles['left-right-content']}>
          <div className={styles['left-content']}>
            <Card className={styles['card-avatar']}>
              <MobileOrTabletOnly>
                <BreadcrumbNavigatorInMobile />
              </MobileOrTabletOnly>
              <CustomEditAvatar />
              <DesktopOnly>
                <div className={styles['menu-list']}>{generateLeftMenuContent(leftMenu)}</div>
              </DesktopOnly>
            </Card>
          </div>
          <div className={styles['right-content']}>{children}</div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  router: {
    location: { pathname },
  },
}) => ({
  pathname,
})
export default connect(mapStateToProps)(ComponentView)
