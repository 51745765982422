import React from 'react'
import { useCurrentBreakpointName } from 'react-socks'
import { Button } from 'antd'
// import { LoginSocialMicrosoft } from 'reactjs-social-login'
import MicrosoftLogin from 'react-microsoft-login'
import logo from 'assets/image/logo-brand/logo-microsoft.png'
import { SNS_LOGIN_CONFIG, SNS_NAME } from 'config'
import { DisplayUtils } from 'utils'

export default ({ handleSocialLogin, handleSocialLoginFailure, disabled, isShowName }) => {
  const isMobile = DisplayUtils.isMobile(useCurrentBreakpointName())

  const authCallback = (err, data, msal) => {
    if (err) {
      console.log(err)
      return
    }
    // if not, then after logout Code Tour, it will auto-login to current M$ account when visiting login page
    window.sessionStorage.removeItem('msal.idtoken')
    window.sessionStorage.removeItem('msal.client.info')
    // https://github.com/alexandrtovmach/react-microsoft-login/issues/84
    // window.sessionStorage.clear()
    // msal.logout()
    handleSocialLogin(SNS_NAME.MICROSOFT, data)
  }

  return (
    <MicrosoftLogin
      clientId={SNS_LOGIN_CONFIG.MICROSOFT_APP_ID}
      redirectUri={SNS_LOGIN_CONFIG.MICROSOFT_REDIRECT_URL}
      authCallback={authCallback}
      // useLocalStorageCache={false}
    >
      <Button icon={<img src={logo} alt="" />} disabled={disabled}>
        <span className="typography-small">
          {isShowName || !isMobile ? SNS_NAME.MICROSOFT : ''}
        </span>
      </Button>
    </MicrosoftLogin>

    // using this, the 1st login will not be successful (nothing happens). But the 2nd (retry) will be done
    // <LoginSocialMicrosoft
    //   client_id={SNS_LOGIN_CONFIG.MICROSOFT_APP_ID}
    //   redirect_uri={SNS_LOGIN_CONFIG.MICROSOFT_REDIRECT_URL}
    //   onResolve={({ provider, data }) => handleSocialLogin(SNS_NAME.MICROSOFT, data)}
    //   onReject={handleSocialLoginFailure}
    // >
    //   <Button icon={<img src={logo} alt="" />} disabled={disabled}>
    //       <span className="typography-small">
    //         {isShowName || !isMobile ? SNS_NAME.MICROSOFT : ''}
    //       </span>
    //   </Button>
    // </LoginSocialMicrosoft>
  )
}
