import antdData from 'antd/lib/locale-provider/vi_VN'
// import localeData from 'react-intl/locale-data/vi'
// https://formatjs.io/docs/react-intl/upgrade-guide-3x/#migrate-to-using-native-intl-apis
import localeData from '@formatjs/intl-pluralrules/locale-data/vi'

require('@formatjs/intl-pluralrules/polyfill')
// require('@formatjs/intl-relativetimeformat/polyfill')
// require('@formatjs/intl-relativetimeformat/locale-data/vi')

const messages = {
  'backend.error.validator.usernameExist': 'Username đã được đăng ký',
  'backend.error.validator.emailExist': 'Email đã được đăng ký',
  'backend.error.validator.emailRequired': 'Email không thể rỗng',
  'backend.error.validator.accountNotFound': 'Không tìm thấy tài khoản',
  'backend.error.validator.noValidAccountFound': 'Không tìm thấy tài khoản hợp lệ',
  'backend.error.validator.accountIsInactive': 'Tài khoản chưa được kích hoạt',
  'backend.error.validator.accountIsBanned': 'Tài khoản đã bị khoá',
  'backend.error.validator.accountHadBeenActivated': 'Tài khoản đã được kích hoạt',
  'backend.error.validator.invalidLoginCredentials': 'Thông tin định danh không đúng',
  'backend.error.validator.emailUnmatched': 'Email khác với tài khoản liên kết',
  'backend.error.validator.invalidOldPassword': 'Mật khẩu cũ không hợp lệ',
  'backend.error.validator.activateTokenExpired': 'Mã xác thực đã hết hạn',
  'backend.error.validator.activateTokenInvalid': 'Mã xác thực không hợp lệ',
  'backend.error.validator.notHuman': 'Bạn không phải là người dùng bình thường đúng không?',
  'backend.error.validator.humanCheckingFailed': 'Yêu cầu kiểm tra bảo mật thất bại',

  'notification.success.updateInfo': 'Cập nhật thông tin thành công',
  'notification.success.emailSent': 'Email đã được gửi thành công',
  'notification.success.send': 'Gửi thành công',
  'notification.success.save': 'Lưu thành công',

  'error.notFound': 'Không tìm thấy',
  'error.forbidden': 'Truy cập trái phép',

  'validator.required': 'Thông tin bắt buộc',
  'validator.noSpecialCharAllowed': 'Không chứa kí tự đặc biệt',
  'validator.textAtLeastNLength': 'Chiều dài tối thiểu là {N}',
  'validator.textMaxNLength': 'Chiều dài tối đa là {N}',
  'validator.invalidEmailFormat': 'Không đúng định dạng email',
  'validator.passwordUnmatched': 'Mật khẩu xác nhận không đúng',
  'validator.usernameOrEmailRequired': 'Vui lòng nhập Username hoặc Email',
  'validator.passwordRequired': 'Vui lòng nhập mật khẩu',
  'validator.passwordAtLeast1UpperCase': 'Mật khẩu phải chứa ít nhất 1 chữ hoa',
  'validator.passwordAtLeast1Number': 'Mật khẩu phải chứa ít nhất 1 chữ số',
  'validator.passwordAtLeast1SpecialChar': 'Mật khẩu phải chứ ít nhất một ký tự đặc biệt',
  'validator.passwordMayNotContainSequences':
    'Mật khẩu không được chứa các ký tự giống nhau liên tục',
  'validator.mustStartWith': 'Phải bắt đầu bằng {text}',
  'validator.svgPhotoBlocked': 'Định dạng hình ảnh SVG không được cho phép',
  'validator.alphabetOnly': 'Chỉ chấp nhận chữ cái',
  'validator.numericOnly': 'Chỉ chấp nhận chữ số',

  'button.save': 'Lưu',
  'button.cancel': 'Hủy',
  'button.edit': 'Sửa',
  'button.delete': 'Xoá',
  'button.joinNow': 'Tham gia Code Tour 2024',
  'button.createTeam': 'Tạo đội ngay',
  'button.add': 'Thêm mới',
  'button.addMember': 'Thêm thành viên',
  'button.invite': 'Mời',
  'button.getNewPassword': 'Lấy mật khẩu mới',
  'button.resend': 'Gửi lại',
  'button.preview': 'Xem trước',
  'button.savePost': 'Đăng bài',
  'button.comment': 'Bình luận',
  'button.viewMore': 'Xem thêm',
  'button.upload': 'Tải lên',
  'button.loginToComment': 'Đăng nhập để bình luận',
  'button.goBack': 'Về trang trước',
  'button.reply': 'Trả lời',
  'button.report': 'Báo cáo',
  'button.editOrDeleteThis': 'Chỉnh sửa hoặc xóa bình luận này',
  'button.here': 'Đây',
  'button.continue': 'Tiếp tục',
  'button.back': 'Quay lại',

  'misc.modal.confirmToDelete': 'Bạn có thực sự muốn xoá không?',
  'misc.noDataAvailable': 'Không có dữ liệu',
  'misc.requiredInformation': 'Bắt buộc',
  'misc.here': 'tại đây',

  'layout.header.home': 'Trang chủ',
  'layout.header.login': 'ĐĂNG NHẬP',
  'layout.header.logout': 'Đăng xuất',
  'layout.header.register': 'ĐĂNG KÝ',
  'layout.header.register_now': 'Đăng ký ngay',
  'layout.header.contest': 'Kỳ thi',
  'layout.header.training': 'Luyện thi',
  'layout.header.news': 'Tin tức',
  'layout.header.forum': 'Diễn đàn',
  'layout.header.ranking': 'Xếp hạng',
  'layout.header.manage_account': 'Quản lý tài khoản',
  'layout.header.my_profile': 'Hồ sơ của tôi',
  'layout.header.change_password': 'Đổi mật khẩu',
  'layout.header.manage_team': 'Quản lý đội',

  'layout.footer.links': 'Liên kết',
  'layout.footer.information': 'Thông tin chung',
  'layout.footer.about_us': 'Về Code Tour',
  'layout.footer.privacy': 'Chính sách bảo mật',
  'layout.footer.tos': 'Thoả thuận sử dụng',
  'layout.footer.faq': 'Câu hỏi thường gặp',
  'layout.footer.copyright': 'Copyright © 2022',

  'layout.notification': 'Thông báo',
  'layout.notification.noData': 'Không có thông báo mới',
  'layout.notification.all': 'Tất cả',
  'layout.notification.unread': 'Chưa đọc',

  'auth.signIn': 'Đăng nhập',
  'auth.signUp': 'Đăng ký',
  'auth.activateSuccess': 'Kích hoạt tài khoản thành công',
  'auth.verificationRequired': 'Bạn chưa xác thực tài khoản',
  'auth.verificationGuideline':
    'Bạn vui lòng kiểm tra email và thực hiện theo hướng dẫn để xác thực email và hoàn thành đăng ký tài khoản trên Code Tour.',
  'auth.verificationGuidelineResend':
    'Nếu vẫn chưa nhận được email, bạn vui lòng nhấn vào {URL} để được gửi lại.',
  'auth.logout': 'Đăng xuất',
  'auth.email': 'Email',
  'auth.gender': 'Giới tính',
  'auth.gender_male': 'Nam',
  'auth.gender_female': 'Nữ',
  'auth.gender_other': 'Khác',
  'auth.username': 'Tên đăng nhập',
  'auth.usernameOrEmail': 'Tên đăng nhập/Email',
  'auth.password': 'Mật khẩu',
  'auth.passwordConfirm': 'Xác nhận mật khẩu',
  'auth.socialSignIn': 'hoặc đăng nhập bằng',
  'auth.signIn.rememberMe': 'Duy trì đăng nhập',
  'auth.signIn.rememberMeHint': 'Duy trì đăng nhập để bạn không phải đăng nhập lại lần tiếp theo',
  'auth.signIn.forgotPassword': 'Quên mật khẩu?',
  'auth.signUp.createAccount': 'Tạo tài khoản',
  'auth.signUp.resendEmail': 'Gửi lại email',
  'auth.signUp.setupSocialAccount': 'Thiết lập tài khoản',
  'auth.signUp.agreeWith': 'Tôi đồng ý với',
  'auth.signUp.agreeWithTitle':
    'Bằng việc đăng ký tài khoản trên website codetour.org, bạn cho phép VNG sử dụng các thông tin cá nhân đã cung cấp cho các mục đích của cuộc thi.',

  'home.banner.platform': 'Nền tảng phát triển tư duy lập trình',
  'home.banner.platform_1': 'Nền tảng',
  'home.banner.platform_2': 'phát triển tư duy lập trình',
  'home.banner.and': 'và',
  'home.banner.connection': 'kết nối cộng đồng yêu công nghệ',
  'home.banner.connection_1': 'kết nối cộng đồng',
  'home.banner.connection_2': 'yêu công nghệ',
  'home.introduction.title': 'Giới thiệu về Code Tour',
  'home.introduction.description':
    'Code Tour là nền tảng cung cấp những kỳ thi và bài tập chất lượng, giúp các thành viên (Codee) nâng cao tư duy lập trình, đồng thời kết nối cộng đồng yêu công nghệ để các thành viên cùng học hỏi, trao đổi và chia sẻ kinh nghiệm.',
  'home.fact.year': 'năm',
  'home.fact.ofDevelopment': 'phát triển',
  'home.fact.participant': 'thí sinh tham gia',
  'home.fact.challenge': 'Code Challenges được tổ chức',
  'home.benefit.title': 'Lợi ích khi tham gia Code Tour',
  'home.benefit.price': 'Giải thưởng hấp dẫn',
  'home.benefit.priceDescription':
    'Phần thưởng dành cho các thí sinh dẫn đầu mỗi kỳ thi và nhiều đãi ngộ hấp dẫn.',
  'home.benefit.exam': 'Đề thi chất lượng',
  'home.benefit.examDescription':
    'Kho đề thi và bài tập giúp bạn phát triển tư duy giải thuật và rèn luyện kỹ năng lập trình.',
  'home.benefit.activity': 'Hoạt động tương tác',
  'home.benefit.activityDescription':
    'Hoạt động kết nối ở các hình thức và nền tảng khác nhau dành cho mọi thành viên.',
  'home.benefit.skill': 'Cọ xát năng lực',
  'home.benefit.skillDescription':
    'Kiến thức học thuật và áp lực thời gian trong mỗi kỳ thi giúp cọ xát và cải thiện năng lực.',
  'home.contest.title': 'Các cuộc thi nổi bật',
  'home.testimonial.title': 'Cảm nhận của thí sinh',
  'home.partner.title': 'Đơn vị đồng hành',
  'home.partner.media': 'Đối tác truyền thông',
  'home.partner.organizer': 'Ban tổ chức',
  'home.partner.partners': 'Đối tác',
  'home.partner.strategy': 'Đối tác chiến lược',
  'home.partner.companion': 'Đơn vị đồng hành',
  'home.partner.technical': 'Đối tác kỹ thuật',
  'home.profileModal.title': 'Cập nhật thông tin',
  'home.profileModal.content':
    'Vui lòng cung cấp thêm thông tin tại mục "Quản lý tài khoản" để tham gia các kỳ thi',
  'home.profileModal.buttonText': 'Cập nhật ngay',
  'home.termModal.title': 'Điều khoản bảo mật dữ liệu',
  'home.termModal.content':
    'Bằng việc đăng ký tài khoản trên website codetour.org, bạn cho phép VNG sử dụng các thông tin cá nhân đã cung cấp cho các mục đích của cuộc thi tuân theo Chính sách bảo mật',

  'contest.searchPlaceholderPublic': 'Tìm tên cuộc thi',
  'contest.searchPlaceholderPrivate': 'Nhập mã chia sẻ',
  'contest.public': 'Công khai',
  'contest.private': 'Riêng tư',
  'contest.status.past': 'Đã diễn ra',
  'contest.status.onGoing': 'Đang diễn ra',
  'contest.status.planned': 'Sắp diễn ra',
  'contest.pleaseEnterSecretCode':
    'Bạn vui lòng nhập mã bí mật do Ban Tổ Chức cung cấp để tham gia Kỳ thi Riêng tư',
  'contest.noPrivateContestFound':
    'Cuộc thi bạn tìm kiếm không tồn tại, vui lòng nhập Mã chia sẻ khác',
  'contest.time': 'Thời gian',
  'contest.date': 'Ngày',
  'contest.begin': 'Bắt đầu',
  'contest.end': 'Kết thúc',
  'contest.venue': 'Địa điểm',
  'contest.viewDetail': 'Xem chi tiết',
  'contest.day': 'Ngày',
  'contest.day_short': 'd',
  'contest.hour': 'Giờ',
  'contest.hour_short': 'h',
  'contest.minute': 'Phút',
  'contest.minute_short': 'm',
  'contest.second': 'Giây',
  'contest.second_short': 's',
  'contest.registeredUser': 'Thí sinh đã đăng ký',
  'contest.scoreboard': 'Bảng xếp hạng',
  'contest.button.addToCalendar': 'Thêm vào lịch',
  'contest.button.addedToCalendar': 'Đã thêm lịch',
  'contest.button.register': 'Đăng ký thi',
  'contest.button.registered': 'Đã đăng ký',
  'contest.button.enterRoom': 'Vào phòng thi',
  'contest.page.information': 'Thông tin chung',
  'contest.page.waitingRoom': 'Phòng chờ',
  'contest.scoreboard.name': 'Tên',
  'contest.scoreboard.score': 'Điểm',
  'contest.scoreboard.solved': 'Bài',
  'contest.scoreboard.penalty': 'Thời gian',
  'contest.scoreboard.penaltyHint':
    'Là tổng thời gian nộp bài của bạn, hiển thị theo định dạng ngày:giờ:phút:giây (nếu ngày bằng 0 thì số ngày sẽ bị bỏ)',
  'contest.scoreboard.firstToSolveProblem': 'Giải được đầu tiên',
  'contest.scoreboard.solvedProblem': 'Đã giải được',
  'contest.scoreboard.attemptedProblem': 'Chưa giải được',
  'contest.scoreboard.pendingProblem': 'Đang chấm',
  'contest.scoreBoardUpdating': 'Đang cập nhật',
  'contest.scoreBoardInReview': 'Đang chấm',
  'contest.scoreBoardFinalized': 'Chính thức',
  'contest.judgementEnvironment': 'Môi trường chấm thi',
  'contest.judgementRanking': 'Tiêu chuẩn chấm bài',
  'contest.willBegin': 'Phòng thi sẽ bắt đầu sau',
  'contest.notAllowToAttendContest':
    'Bạn không thuộc đối tượng tham gia của cuộc thi, vui lòng xem mục Thông tin chung',
  'contest.onlyRegisterOneContest': 'Chỉ được phép đăng ký một cuộc thi',
  'contest.facebookGroupModal':
    'Theo dõi Facebook Group Code to Connect để cập nhập thông tin mới nhất nhé!',
  'contest.vnoiGroupModal':
    'Cuộc thi sẽ diễn ra trên codetour.vnoi.info. Ban Tổ chức sẽ gửi tài khoản dự thi qua email trước 12 tiếng bắt đầu cuộc thi. Bạn hãy kiểm tra email để không bỏ sót thông tin quan trọng!',
  'contest.facebookGroupModalTitle': 'Bạn đã đăng ký dự thi thành công',
  'contest.scoreBoardFilterHelper': 'Tìm kiếm kết quả theo tên trường',

  'news.writeComment': 'Viết bình luận',
  'news.writeReplyTo': 'Trả lời đến <username>',
  'news.filterTitle': 'Tin tức về #',

  'forum.title': 'Tiêu đề',
  'forum.searchPlaceholder': 'Tìm tên các chủ đề',
  'forum.recentPost': 'Bài đăng gần đây',
  'forum.post': 'Bài đăng',
  'forum.statistic': 'Thống kê',
  'forum.thread': 'Threads',
  'forum.message': 'Messages',
  'forum.createTopic': 'Tạo chủ đề',
  'forum.view': 'View',
  'forum.replies': 'Replies',
  'forum.reportContent': 'Báo cáo nội dung',
  'forum.reportReason': 'Lý do',

  'teamCard.pending': 'Chờ',
  'teamCard.leader': 'Trưởng nhóm',
  'teamCard.member': 'Thành viên',
  'teamCard.addMember': 'Thêm thành viên',
  'teamCard.invite': 'Mời',
  'teamCard.inviteSuccess': 'Đã gửi lời mời thành công.',
  'teamCard.myTeam': 'Đội của tôi',
  'teamCard.createTeam': 'Tạo đội',
  'teamCard.teamName': 'Tên đội',
  'teamCard.teamDescription': 'Mô tả về đội của bạn',
  'teamCard.confirmDelete': 'Xác nhận xoá',
  'teamCard.confirmDeleteMessage': 'Bạn có chắc chắn muốn xoá đối tượng này?',

  'profile.information': 'THÔNG TIN',
  'profile.challenges': 'Các cuộc thi tham gia',
  'profile.sum': 'Tổng điểm',
  'profile.forum': 'Diễn đàn',
  'profile.UserPractice': 'Luyện thi',
  'profile.resolvedAssignments': 'Danh sách bài tập đã giải',

  'aboutUs.aboutCodeTourTitle': 'Về Code Tour',
  'aboutUs.aboutCodeTourDescription':
    'Code Tour có tiền thân là một sân chơi dành cho các bạn đam mê thuật toán do VNG sáng tạo, đến nay đã được nâng cấp trở thành một nền tảng nâng cao tư duy lập trình và kết nối cộng đồng yêu công nghệ, nhằm mang đến một sân chơi thường trực với hàng loạt hoạt động thú vị để các “fan lập trình” học hỏi, thực hành và kết nối. Không chỉ mang đến kho kiến thức chất lượng và giải thưởng hấp dẫn, Code Tour còn đồng hành cùng quá trình rèn luyện xuyên suốt của các bạn như một người anh em “chí cốt” trên hành trình học tập và xây dựng sự nghiệp.',
  'aboutUs.infoVisionTitle': 'Tầm nhìn',
  'aboutUs.infoVisionDescription':
    'Code Tour mang đến nền tảng thân thiện với nhiều trải nghiệm thú vị để các lập trình viên vừa học hỏi vừa kết nối cùng nhau, tạo nên một cộng đồng công nghệ rộng lớn không chỉ ở Việt Nam còn hướng ra thị trường Đông Nam Á và quốc tế.',
  'aboutUs.infoSloganTitle': 'Slogan: Code. Connect. Challenge',
  'aboutUs.infoSloganDescription':
    'Cộng đồng Code Tour cùng nhau phát triển dựa trên tinh thần thực hành - trao dồi - cọ xát. Các bạn trẻ được tạo cơ hội để thực sự bắt tay vào việc lập trình (code) với tần suất thường xuyên và hình thức đa dạng, được kết nối (connect) với những người bạn đồng chí hướng để chia sẻ kiến thức và thông tin, đồng thời bồi dưỡng năng lực bằng việc thử thách (challenge) chính mình qua áp lực và độ khó của các bài thi.',
  'aboutUs.infoMissionTitle': 'Sứ mệnh: Phát triển tài năng công nghệ',
  'aboutUs.infoMissionDescription':
    'Mọi hoạt động và giá trị của Code Tour nhằm tạo điều kiện tốt nhất cho các Codee (tên gọi các thành viên của Code Tour) được nâng cao tư duy và phát triển kỹ năng bằng các kỳ thi, bài tập và mạng lưới kết nối với các nhân tài khác, giúp các bạn nuôi dưỡng đam mê và tích lũy năng lực để xây dựng sự nghiệp của mình.',
  'aboutUs.milestoneUpgradeTitle': 'Nâng cấp',
  'aboutUs.milestoneUpgradeDescription':
    'Code Tour thay mới giao diện website với nhiều tính năng mới để các thí sinh trong nước và quốc tế có nhiều trải nghiệm thú vị hơn trước.',
  'aboutUs.milestoneTransformTitle': 'Chuyển mình',
  'aboutUs.milestoneTransformDescription_main':
    'Code Tour tổ chức nhiều phiên bản mới cho các nhóm thí sinh khác nhau tương tác cùng hơn 1.500 thí sinh trên nền tảng website, bao gồm:',
  'aboutUs.milestoneTransformDescription_1':
    'Code Tour x Uni: dành riêng cho thí sinh của các trường HCMUT, UIT',
  'aboutUs.milestoneTransformDescription_2':
    'Code Tour x VTF: dành cho các thí sinh đang ứng tuyển chương trình VNG Tech Fresher',
  'aboutUs.milestoneTransformDescription_3': 'Code Tour 2020: dành cho tất cả coder trên toàn quốc',
  'aboutUs.milestoneExpandTitle': 'Phát triển',
  'aboutUs.milestoneExpandDescription':
    'Code Tour tổ chức ở Sài Gòn và Đà Nẵng kết hợp với các hoạt động thể chất và gắn kết ở các địa điểm mới lạ thu hút sự tham gia của hơn 300 coder. Sau đó, Code Tour 2019 diễn ra thành công với 5 Code Challenges trực tuyến trên website và thu hút hơn 1.000 thí sinh tham gia.',
  'aboutUs.milestoneBeginTitle': 'Sơ khởi',
  'aboutUs.milestoneBeginDescription':
    'Tiền thân của Code Tour là Saigon Code Tour 2018 - cuộc thi code dạo độc đáo được VNG tổ chức ở TP.HCM với 4 vòng thi, thu hút được 182 thí sinh tham gia, mang tới một sân chơi thú vị cho các fan lập trình.',

  'faq.faq': 'Câu hỏi thường gặp',

  'leaderBoard.title': 'Bảng xếp hạng',
  'leaderBoard.rank': 'Hạng',
  'leaderBoard.username': ' Tên đăng nhập',
  'leaderBoard.contest': 'Cuộc thi',
  'leaderBoard.contestHint': 'Code Challenges đã tham gia',
  'leaderBoard.averageScore': 'Điểm trung bình',
  'leaderBoard.averageScoreHint': 'Tổng điểm chia cho tổng số vòng thi chính thức đã tham gia',
  'leaderBoard.averagePenalty': 'Thời gian trung bình',
  'leaderBoard.averagePenaltyHint':
    'Tổng thời gian chia cho tổng số vòng thi chính thức đã tham gia',
  'leaderBoard.totalScore': 'Tổng điểm',
  'leaderBoard.totalScoreHint': 'Tổng điểm của các vòng thi chính thức đã tham gia',
  'leaderBoard.totalPenalty': 'Tổng thời gian',
  'leaderBoard.totalPenaltyHint': 'Tổng thời gian nộp bài của các vòng thi chính thức đã tham gia',
  'leaderBoard.searchPlaceholder': 'Nhập tên đăng nhập hoặc email',
  'leaderBoard.annual': 'Bảng xếp hạng theo năm',
  'leaderBoard.cumulative': 'Bảng xếp hạng tích luỹ',
  'leaderBoard.leaderBoardGuideline': 'Xem thông tin cơ chế BXH tại',
  'leaderBoard.leaderBoardCriteria': 'Cách tính bảng xếp hạng',

  'userProfile.manageAccount': 'Quản lý tài khoản',
  'userProfile.from': 'Từ',
  'userProfile.to': 'Đến',
  'userProfile.present': 'Hiện tại',
  'userProfile.changeUsername': 'Đổi tên',
  'userProfile.avatar.change': 'Đổi ảnh đại diện',
  'userProfile.avatar.uploadedPhotos': 'Ảnh đã tải lên',
  'userProfile.information': 'Thông tin cá nhân',
  'userProfile.informationShort': 'Thông tin',
  'userProfile.information.fullName': 'Họ và tên',
  'userProfile.information.gender': 'Giới tính',
  'userProfile.information.gender.male': 'Nam',
  'userProfile.information.gender.female': 'Nữ',
  'userProfile.information.gender.other': 'Khác',
  'userProfile.information.birthDate': 'Năm sinh',
  'userProfile.information.phone': 'Số điện thoại',
  'userProfile.information.email': 'Email',
  'userProfile.information.country': 'Quốc gia/Vùng lãnh thổ',
  'userProfile.information.province': 'Tỉnh/Thành phố',
  'userProfile.information.address': 'Địa chỉ',
  'userProfile.information.certificate': 'Tải giấy chứng nhận',
  'userProfile.education_experience': 'Kinh nghiệm và học vấn',
  'userProfile.education_experience.status': 'Tình trạng hiện tại',
  'userProfile.education_experience.student': 'Học sinh sinh viên',
  'userProfile.education_experience.worked': 'Người đi làm',
  'userProfile.education': 'Học vấn',
  'userProfile.education.longTitle': 'Trình độ học vấn',
  'userProfile.education.school': 'Trường',
  'userProfile.education.major': 'Chuyên ngành',
  'userProfile.education.majorHint': 'Vui lòng điền N/A nếu bạn không có chuyên ngành',
  'userProfile.education.current': 'Trường học hiện tại',
  'userProfile.education.other': 'Khác',
  'userProfile.education.otherHint': 'Nhập tên trường nếu bạn chọn Khác',
  'userProfile.education.code': 'Mã số sinh viên',
  'userProfile.education.codeHint': 'Nếu là học sinh nhập mã STUDENT',
  'userProfile.education.lock':
    'Tính năng cập nhập học vấn bị tạm khoá trong thời gian cuộc thi diễn ra',
  'userProfile.work': 'Kinh nghiệm',
  'userProfile.work.longTitle': 'Kinh nghiệm làm việc',
  'userProfile.work.title': 'Chức danh',
  'userProfile.work.company': 'Công ty',
  'userProfile.work.current': 'Công việc hiện tại',
  'userProfile.socialConnection': 'Liên kết tài khoản',
  'userProfile.socialConnectionShort': 'Liên kết',
  'userProfile.socialConnection.disconnect': 'Huỷ liên kết',
  'userProfile.changePassword': 'Đổi mật khẩu',
  'userProfile.changePasswordSuccess': 'Đổi mật khẩu thành công',
  'userProfile.changePassword.oldPassword': 'Mật khẩu cũ',
  'userProfile.changePassword.newPassword': 'Mật khẩu mới',
  'userProfile.changePassword.newPasswordConfirm': 'Xác nhận mật khẩu',
  'userProfile.changePassword.guideline.title': 'Làm theo các bước sau để thiết lập lại tài khoản:',
  'userProfile.changePassword.guideline.1': 'Nhập tên đăng nhập hoặc email',
  'userProfile.changePassword.guideline.2':
    'Chờ thông tin khôi phục được gửi đến địa chỉ email đã đăng kí trên Code Tour',
  'userProfile.changePassword.guideline.3': 'Làm theo hướng dẫn trong email',

  'myProfile.title': 'Hồ sơ',
  'myProfile.contest.participated': 'Cuộc thi tham gia',
  'myProfile.contest.all': 'Tất cả',
  'myProfile.contest.totalScore': 'Tổng điểm',
  'myProfile.forum.created': 'Đã tạo',
  'myProfile.forum.comment': 'Bình luận',
  'myProfile.forum.like': 'Yêu thích',

  'notification.action.reply': 'đã trả lời',
  'notification.action.post': 'đã có bài viết mới tới',
  'notification.action.like': 'đã thích',
  'notification.action.dislike': 'đã không thích',
  'notification.action.news': 'đã thêm',

  'notification.object.yourComment': 'bình luận của bạn',
  'notification.object.yourReply': 'bình luận của bạn',
  'notification.object.yourTopic': 'chủ đề của bạn',
  'notification.object.yourPost': 'bài viết của bạn',
  'notification.object.news': 'một bài viết',

  'UserPractice.col.name': 'Tên bài',
  'UserPractice.col.type': 'Phân loại',
  'UserPractice.col.level': 'Độ khó',
  'UserPractice.col.score': 'Điểm',
}

export default {
  locale: 'vi',
  antdData,
  localeData,
  messages,
}
