import moment from 'moment'
import 'moment/locale/vi'
import { TEXT_UI_CONTEST, TIME_FORMAT } from 'config'

export default class DateTimeUtils {
  static now() {
    return moment()
  }

  // 3 hours ago...
  // need to transfer current locale,
  // double check with all DataTime.Utils.fromNow, atm only fix in News view comment
  static fromNow(dateTxt, locale = 'vi') {
    const dateObj = this.textToDate(dateTxt)
    // last over 7 days
    if (dateObj.add(7, 'days') < this.now()) return this.formatDate(dateTxt)
    return this.textToDate(dateTxt).locale(locale).fromNow()
  }

  static secondsToHours(numSeconds) {
    const days = moment.duration(numSeconds * 1000).asDays()
    let formattedNum = numSeconds
    let formatTxt = 'HH:mm:ss'
    if (days >= 1) {
      // because DD starts from 1
      formattedNum = numSeconds - 24 * 3600
      formatTxt = 'DD:HH:mm:ss'
    }
    return moment.utc(formattedNum * 1000).format(formatTxt)
  }

  static textToDate(txt, format) {
    if (txt) return format ? moment(txt, format) : moment(txt)
    return null
  }

  static formatDate(timeText, format = TIME_FORMAT, defaultValue = null) {
    return timeText && this.isValidDateText(timeText)
      ? this.textToDate(timeText).format(format)
      : defaultValue
  }

  static isValidDateText(txt) {
    return moment(txt).isValid()
  }

  // usage: DateTimeUtils.addToDate(user.createdAt, config.get('jwtExpiredInterval')) < DatetimeUtils.now()
  static addToDate(dateObj, interval) {
    let type = 'h'
    if (interval.includes('m'))
      // minute
      type = 'm'
    else if (interval.includes('d')) type = 'd'
    else if (interval.includes('M'))
      // month
      type = 'M'
    else if (interval.includes('y')) type = 'y'
    // parseInt(24h) --> 24
    return moment().add(parseInt(interval, 10), type).toDate()
  }

  static toMiliseconds(obj) {
    return moment(obj).valueOf()
  }

  static formatContestEventDate(eventTimeBegin, eventTimeEnd) {
    const beginTxt = this.formatDate(eventTimeBegin)
    const endTxt = this.formatDate(eventTimeEnd)
    const beginArr = beginTxt.split(' ')
    const endArr = endTxt.split(' ')
    // different date
    if (beginArr[0] !== endArr[0])
      return {
        text1: TEXT_UI_CONTEST.BEGIN,
        text2: TEXT_UI_CONTEST.END,
        value1: beginTxt,
        value2: endTxt,
      }
    return {
      text1: TEXT_UI_CONTEST.TIME,
      text2: TEXT_UI_CONTEST.DATE,
      value1: `${beginArr[1]} ~ ${endArr[1]}`,
      value2: beginArr[0],
    }
  }
}
