import React from 'react'
import { Table } from 'antd'
import { MiscUtils } from 'utils'
import Pagination from '../Pagination'
import styles from './style.module.scss'
import './style.overwrite.scss'

export default ({ data, columns, loading, ...props }) => (
  <div className={styles.content}>
    <Table
      columns={columns}
      loading={loading}
      dataSource={data}
      // onChange={onTableChange}
      bordered
      // pagination={paginationOptions} // topLeft, topCenter, topRight, none
      pagination={false} // use custom pager
      rowKey={MiscUtils.generateId} // set id of item as key for each row
      // scroll={{ y: 440 }}
    />

    <div className="p-t-30">
      <Pagination {...props} />
    </div>
  </div>
)
