import React from 'react'
import { MiscUtils } from 'utils'
import cccLogo from 'assets/image/banner/code-connect-challenge-with-medal.png'
import RedBackground from '../RedBackground'
import styles from './style.module.scss'

export default ({ leftLogo, textAbove, textBelow, title }) => (
  <RedBackground>
    <div className={styles['banner-content']}>
      <div className={styles.left}>
        <div className={`color-white ta-center ${MiscUtils.generateContestNameClass(title)}`}>
          {title}
        </div>
        <div className={styles['contest-info']}>
          <img src={leftLogo} alt="" />
          <div className={styles['text-wrapper']}>
            <div className={styles.text}>
              <div>{textAbove}</div>
              <div className="p-t-5">{textBelow}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <img src={cccLogo} alt="" />
      </div>
    </div>
  </RedBackground>
)
