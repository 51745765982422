import qs from 'qs'
import {
  FE_ROUTE,
  BE_ROUTE,
  CONTEST_STATUS,
  AWS_S3_STATIC_URL,
  UPLOAD_RELATIVE_PATH,
  DEFAULT_AVT,
  DEFAULT_THUMBNAIL,
} from 'config'

export default class URLUtils {
  static buildBeURL(base, pathname) {
    const baseUrl = `${process.env.REACT_APP_BACKEND_USER_END_POINT}${base}`
    return pathname ? baseUrl + pathname : baseUrl
  }

  static buildAvatarURL(avatar = DEFAULT_AVT) {
    if (avatar?.startsWith('http')) return avatar
    // https://codetour-static.s3.amazonaws.com/local/avatar/18-1647943467-siUQsgKW.png
    return `${AWS_S3_STATIC_URL.replace(
      '<bucketName>',
      process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    )}/${process.env.REACT_APP_APP_ENV}/${UPLOAD_RELATIVE_PATH.AVATAR}/${avatar}`
  }

  static buildVideoURL(video = '') {
    if (video?.startsWith('http')) return video
    // https://codetour-static.s3.amazonaws.com/production/video/Comp_1_7.mp4
    return `${AWS_S3_STATIC_URL.replace(
      '<bucketName>',
      process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    )}/${process.env.REACT_APP_APP_ENV}/${UPLOAD_RELATIVE_PATH.VIDEO}/${video}`
  }

  static buildThumbnailURL(thumbnail = DEFAULT_THUMBNAIL) {
    if (thumbnail?.startsWith('http')) return thumbnail
    return `${AWS_S3_STATIC_URL.replace(
      '<bucketName>',
      process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    )}/${process.env.REACT_APP_APP_ENV}/${UPLOAD_RELATIVE_PATH.NEWS}/${thumbnail}`
  }

  static buildUserProfileURL(userId) {
    return `${FE_ROUTE.USER.PROFILE}/${userId}`
  }

  static buildNewsViewURL(newsId, titleSlugify) {
    return `${FE_ROUTE.NEWS.VIEW_NEWS.replace(':newsId', newsId)}-${titleSlugify}`
  }

  static buildForumTopicViewURL(topicId, titleSlugify) {
    return `${FE_ROUTE.FORUM.VIEW_TOPIC.replace(':topicId', topicId)}-${titleSlugify}`
  }

  static buildSharedNewsViewURL(newsId, titleSlugify) {
    return this.buildBeURL(
      BE_ROUTE.SHARE.BASE,
      BE_ROUTE.SHARE.PATH.NEWS.replace(':id', `${newsId}-${titleSlugify}`),
    )
  }

  static buildSharedForumTopicViewURL(topicId, titleSlugify) {
    return this.buildBeURL(
      BE_ROUTE.SHARE.BASE,
      BE_ROUTE.SHARE.PATH.FORUM_TOPIC.replace(':id', `${topicId}-${titleSlugify}`),
    )
  }

  /* eslint-disable camelcase */
  static buildContestViewURL({ id, is_public, status }) {
    // todo: make CONTEST_TAB_KEY like USER_PROFILE_TAB_KEY
    let tab = ''
    if (status === CONTEST_STATUS.PAST) tab = 'scoreboard'
    else if (status === CONTEST_STATUS.ONGOING) tab = 'waiting-room'
    if (tab) tab = `?tab=${tab}`
    return `${is_public ? FE_ROUTE.CONTEST.PUBLIC : FE_ROUTE.CONTEST.PRIVATE}/${id}${tab}`
  }
  /* eslint-enable camelcase */

  static moveToURL(url) {
    window.location.href = url
  }

  static reloadPage() {
    window.location.reload(true)
    // If we needed to pull the document from
    //  the web-server again (such as where the document contents
    //  change dynamically) we would pass the argument as 'true'.
  }

  static routeToPage(history, url) {
    history.push(url)
  }

  static getParamsInURL() {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true })
  }

  static getParamValueInURL(key) {
    return this.getParamsInURL()[key]
  }

  static buildParamsInURL(obj, options) {
    return qs.stringify(obj, options)
  }

  static getPathnameInURL() {
    return window.location.pathname
  }

  static formatURL(text) {
    return text.includes('http://') || text.includes('https://') ? text : `http://${text}`
  }
}
