import { fork } from 'redux-saga/effects'
import authSaga from './auth/saga'
import localeSaga from './locale/saga'
import notificationSaga from './notification/saga'

export default function* rootSaga() {
  yield fork(authSaga)
  yield fork(localeSaga)
  yield fork(notificationSaga)
}
