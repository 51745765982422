import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import classnames from 'classnames'
import { Input } from 'antd'
import { CustomModal, LoadingIndicator } from 'components'
import {
  CONST_VALIDATOR,
  I18NFormatMessage,
  RECAPTCHA_SITE_KEY,
  TEXT_BUTTON,
  TEXT_NOTIFICATION_SUCCESS,
  TEXT_UI_AUTH,
  TEXT_UI_USER_PROFILE,
  TEXT_VALIDATOR,
} from 'config'
import { DisplayUtils, ErrorHandlerUtils, MiscUtils, ReCaptchaUtils, ValidationUtils } from 'utils'

export default ({ isShown, onClose, currentUserEmail, ModelService, locale }) => {
  const intl = useIntl()
  const [modalVisible, setModalVisible] = useState(isShown)
  const [usernameEmail, setUsernameEmail] = useState(currentUserEmail)
  const [validatorErrorMessage, setValidatorErrorMessage] = useState(null)
  const [isLoadingShown, setIsLoadingShown] = useState(false)
  const recaptchaRef = useRef()

  const okButtonProps = {
    disabled: !usernameEmail,
    className: classnames({ 'ant-btn-primary-disabled': !usernameEmail }),
  }

  useEffect(() => {
    setModalVisible(isShown)
  }, [isShown])

  const closeModal = () => {
    setModalVisible(false)
    onClose()
  }

  const onOK = async () => {
    // eslint-disable-next-line
    let [skipped, errorMessage] = await MiscUtils.safePromise(
      // use EMAIL because EMAIL.MAX > USERNAME.MAX
      ValidationUtils.minMax(intl, usernameEmail, CONST_VALIDATOR.USER.EMAIL),
    )
    if (ValidationUtils.empty(errorMessage)) {
      let validatorCode = null
      if (usernameEmail.includes('@') && !ValidationUtils.email(intl, usernameEmail))
        validatorCode = TEXT_VALIDATOR.INVALID_EMAIL_FORMAT
      else if (
        !ValidationUtils.email(intl, usernameEmail) &&
        !ValidationUtils.isAlphanumeric(usernameEmail)
      )
        validatorCode = TEXT_VALIDATOR.NO_SPECIAL_CHAR_ALLOWED
      if (validatorCode) errorMessage = I18NFormatMessage(intl, validatorCode)
    }
    if (errorMessage) {
      setValidatorErrorMessage(errorMessage)
      return
    }

    const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
    setIsLoadingShown(true)
    ModelService.forgotPassword({
      username_email: usernameEmail,
      locale,
      recaptchaToken,
    })
      .then(() => {
        setIsLoadingShown(false)
        ReCaptchaUtils.reset(recaptchaRef)
        if (!currentUserEmail) {
          // forgot pass in login page, not UserProfile
          setUsernameEmail('')
          setValidatorErrorMessage('')
        }
        closeModal()
        DisplayUtils.showNotification(
          I18NFormatMessage(intl, TEXT_NOTIFICATION_SUCCESS.EMAIL_SENT_SUCCESS),
        )
      })
      .catch((error) => {
        setIsLoadingShown(false)
        ReCaptchaUtils.reset(recaptchaRef)
        setValidatorErrorMessage(ErrorHandlerUtils.toText(error, intl))
      })
  }

  return (
    <CustomModal
      title={TEXT_UI_AUTH.LOGIN.FORGOT_PASSWORD}
      visible={modalVisible}
      onCancel={closeModal}
      onOK={onOK}
      okText={TEXT_BUTTON.GET_NEW_PASSWORD}
      okButtonProps={okButtonProps}
    >
      <LoadingIndicator isHidden={!isLoadingShown}>
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size="invisible" ref={recaptchaRef} />
        <div className="m-t-20 m-b-20 typography-small">
          <div>{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_TITLE}</div>
          <div>1.&nbsp;{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_1}</div>
          <div>2.&nbsp;{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_2}</div>
          <div>3.&nbsp;{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_3}</div>
        </div>

        <div className="m-b-20">
          <div>
            <span className="color-red">*</span>{' '}
            <span className="typography-menu-subtext">{TEXT_UI_AUTH.LOGIN.USERNAME_EMAIL}</span>
          </div>
          <Input
            type="text"
            name="username"
            value={usernameEmail}
            onChange={(e) => setUsernameEmail(e.target.value.trim())}
            disabled={currentUserEmail}
          />
          {validatorErrorMessage && <div className="color-red">{validatorErrorMessage}</div>}
        </div>
      </LoadingIndicator>
    </CustomModal>
  )
}
