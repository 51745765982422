import React from 'react'
import classnames from 'classnames'
import { useCurrentBreakpointName } from 'react-socks'
import { Button } from 'antd'
import { SNS_NAME, SNS_LOGIN_CONFIG } from 'config'
import { DisplayUtils, MiscUtils, URLUtils } from 'utils'
import { AuthService } from 'services'
import logoZalo from 'assets/image/logo-brand/logo-zalo.png'
import '../style.overwrite.scss'

export default ({ isDisabled, isShowName }) => {
  const handleOnClick = async () => {
    AuthService.getCodeChallengeToken().then(({ code_challenge: codeChallenge }) => {
      URLUtils.moveToURL(`${SNS_LOGIN_CONFIG.ZALO_LOGIN_URL}&code_challenge=${codeChallenge}`)
    })
  }

  const isMobile = DisplayUtils.isMobile(useCurrentBreakpointName())
  return (
    <Button
      key={MiscUtils.generateId()}
      icon={<img src={logoZalo} alt="" />}
      onClick={handleOnClick}
      disabled={isDisabled}
      className={classnames({ 'social-disabled': isDisabled })}
    >
      <span className="typography-small">{isShowName || !isMobile ? SNS_NAME.ZALO : ''}</span>
    </Button>
  )
}
