import React from 'react'
import { Button } from 'antd'
import classnames from 'classnames'
import SocialLogin from 'react-social-login'
import './style.overwrite.scss'

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, logo, providerName, disabled, ...props } = this.props
    return (
      <Button
        onClick={disabled ? null : triggerLogin}
        icon={<img src={logo} alt="" />}
        {...props}
        className={classnames({ 'social-disabled': disabled })}
      >
        <span className="typography-small">{providerName}</span>
      </Button>
    )
  }
}

export default SocialLogin(SocialButton)
