import React, { useState, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import _ from 'lodash'
import { GoCheck } from 'react-icons/go'
import { connect } from 'react-redux'
import { Popover } from 'antd'
import { MiscUtils, ReCaptchaUtils } from 'utils'
import { setLocaleAction } from 'redux/locale/actions'
import en from 'assets/image/flag/en.png'
import vi from 'assets/image/flag/vi.png'
import { setCurrentUserAction } from 'redux/auth/actions'
import { UserService } from 'services'
import { RECAPTCHA_SITE_KEY, SUPPORTED_LOCALES } from 'config'
import styles from './style.module.scss'
import './style.overwrite.scss'

const flags = {
  en,
  vi,
}

const LanguageSelector = ({ currentUser, locale, onChangeCallback, placement = 'bottom' }) => {
  const recaptchaRef = useRef()
  const [isShownContent, setIsShowContent] = useState(false)
  // load locale from user setting, atm we make it disabled
  // const currentLocale = currentUser ? currentUser.userProfile.locale : locale
  const currentLocale = locale

  const listFlags = (
    <div className={styles.languages}>
      {/* <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size="invisible" ref={recaptchaRef} /> */}
      {_.keys(flags).map((flagId) => (
        <div
          key={MiscUtils.generateId()}
          className={styles.language}
          onClick={() => chooseLanguage(flagId)}
        >
          <img src={flags[flagId]} alt={flagId} />
          {SUPPORTED_LOCALES[flagId]}
          {currentLocale === flagId && <GoCheck />}
        </div>
      ))}
    </div>
  )

  const chooseLanguage = async (selectedLocale) => {
    const doAction = () => {
      MiscUtils.dispatchReduxAction(setLocaleAction(selectedLocale))
      setIsShowContent(false)
      if (onChangeCallback) onChangeCallback()
      // todo: optional: if no onChangeCallback, then we can do "reload page",
      //  so that don't need to hook to current locale change
    }
    if (currentUser) {
      MiscUtils.dispatchReduxAction(
        setCurrentUserAction({
          ...currentUser,
          userProfile: {
            ...currentUser.userProfile,
            locale: selectedLocale,
          },
        }),
      )
      // const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
      UserService.setLocale({
        locale: selectedLocale,
        // recaptchaToken,
      })
        .then(() => {
          // ReCaptchaUtils.reset(recaptchaRef)
          // if we record the user's locale, then must do actions in here
          // doAction()
        })
        .catch((error) => {
          // ReCaptchaUtils.reset(recaptchaRef)
          // because we disable "record user's locale", so that we don't care if it succeeds or not
          // ErrorHandlerUtils.http(error)
        })
    }
    // always executed
    doAction()
  }

  const handleVisibleChange = (visible) => setIsShowContent(visible)

  return (
    <Popover
      id="languageSelectorPopover"
      content={listFlags}
      trigger="hover"
      placement={placement}
      // if use this, then cannot click on Mobile UI
      // getPopupContainer={() => document.getElementById('headerContentId')}
      onVisibleChange={handleVisibleChange}
      visible={isShownContent}
    >
      <img className={styles.currentFlag} src={flags[currentLocale]} alt="" />
    </Popover>
  )
}

const mapStateToProps = ({ authReducer: { currentUser }, localeReducer: { locale } }) => ({
  currentUser,
  locale,
})
export default connect(mapStateToProps)(LanguageSelector)
