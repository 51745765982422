import React, { useState, useEffect } from 'react'
import { CustomModal, LoadingIndicator } from 'components'
import {
  TEXT_UI_AUTH,
  TEXT_BUTTON,
  FE_ROUTE,
  TEXT_UI_HOME,
  TEXT_UI_MISC,
  TEXT_UI_LAYOUT,
} from 'config'
import { Button, Checkbox, Typography } from 'antd'
import classNames from 'classnames'

export default ({ isShown, onOK }) => {
  const [modalVisible, setModalVisible] = useState(isShown)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setModalVisible(false)
  }

  const onAgree = async () => {
    setLoading(true)
    await onOK?.()
    setLoading(false)
    closeModal()
  }

  const toggleIsTermAgreed = (e) => {
    setIsChecked(e.target.checked)
  }

  useEffect(() => {
    setModalVisible(isShown)
  }, [isShown])

  return (
    <CustomModal
      title={TEXT_UI_HOME.TERM_MODAL.TITLE}
      disableClosable
      visible={modalVisible}
      footer={[
        <Button
          className={classNames({ 'ant-btn-primary-disabled': !isChecked || loading })}
          type="primary"
          disabled={!isChecked || loading}
          loading={loading}
          onClick={() => onAgree()}
        >
          {TEXT_BUTTON.CONTINUE}
        </Button>,
      ]}
    >
      <LoadingIndicator isHidden>
        <div className="m-t-20 m-b-20 typography-small">
          <Typography.Text>
            {TEXT_UI_HOME.TERM_MODAL.CONTENT}
            &nbsp;
            <Typography.Link href={FE_ROUTE.MISC.PRIVACY} target="_blank" underline>
              {TEXT_UI_MISC.HERE}
            </Typography.Link>
            .
          </Typography.Text>
        </div>

        <Checkbox onChange={toggleIsTermAgreed} checked={isChecked}>
          <Typography.Text>
            {TEXT_UI_AUTH.REGISTER.AGREE_WITH}&nbsp;
            <Typography.Link href={FE_ROUTE.MISC.TOS} target="_blank" underline>
              {TEXT_UI_LAYOUT.FOOTER.TOS}
            </Typography.Link>
            &nbsp;&&nbsp;
            <Typography.Link href={FE_ROUTE.MISC.PRIVACY} target="_blank" underline>
              {TEXT_UI_LAYOUT.FOOTER.PRIVACY}
            </Typography.Link>
          </Typography.Text>
        </Checkbox>
      </LoadingIndicator>
    </CustomModal>
  )
}
