import { TEXT_UI_CONTEST, TEXT_UI_LAYOUT } from './text'
import { FE_ROUTE } from './urls'

/**
 ----- sync between BE & FE -----
 */
export const APP_TYPE_CODE = {
  NEWS: 1,
  NEWS_COMMENT: 2,
  NEWS_REPLY_TO_COMMENT: 3,
  FORUM_CATEGORY: 4,
  FORUM_FORUM: 5,
  FORUM_TOPIC: 6,
  FORUM_POST: 7,

  // action, used in notification
  ACTION_LIKE: 8,
  ACTION_DISLIKE: 9,
  ACTION_POST: 10,
  ACTION_REPLY: 11,
  ACTION_NEWS: 12,
}

export const HTTP_ERROR_CODE = {
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
  SERVER_ERROR: 500,
}

export const CONST_VALIDATOR = {
  INPUT_TEXT: {
    MIN: 10,
    MAX: 200,
  },
  AUTH: {
    PASSWORD: {
      MIN: 8,
      MAX: 256,
    },
  },
  USER: {
    EMAIL: {
      MIN: 5,
      MAX: 254,
    },
    USERNAME: {
      MIN: 5,
      MAX: 20,
    },
    FULL_NAME: {
      MIN: 4,
      MAX: 100,
    },
    PHONE: {
      MIN: 10,
      MAX: 15,
    },
    ADDRESS: {
      MIN: 7,
      MAX: 150,
    },
    // work, education
    COMMON: {
      MIN: 1,
      MAX: 100,
    },
  },
  NEWS: {
    COMMENT: {
      MIN: 1,
      MAX: 8000,
    },
  },
  FORUM: {
    TOPIC_TITLE: {
      MIN: 10,
      MAX: 200,
    },
    TOPIC_CONTENT: {
      MIN: 10,
      MAX: 50000,
    },
    POST: {
      MIN: 1,
      MAX: 8000,
    },
  },
  URL: {
    MIN: 1,
    MAX: 2083,
  },
  HTML_CONTENT_EMOTION_ICON_PATTERN: 'data-emoticon="true"',
}

export const DEFAULT_LOCALE = 'vi'
export const SUPPORTED_LOCALES = {
  vi: 'Tiếng Việt',
  en: 'English',
  // it: 'Italiano'
}

export const SETTING_CONTENT_TYPE = {
  term_of_use: 'Term of use',
  privacy: 'Privacy Policy',
  contest_judgement_environment: 'Contest Judgement - Environment',
  contest_judgement_ranking: 'Contest Judgement - Ranking',
  contest_criteria_of_leaderboard: 'Contest Leaderboard - Criteria',
}

export const SNS_NAME = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  LINKEDIN: 'LinkedIn',
  ZALO: 'Zalo',
  MICROSOFT: 'Microsoft',
}

export const SNS_ROOT_URL = {
  FACEBOOK: 'https://www.facebook.com/',
  LINKEDIN: 'https://www.linkedin.com/',
}

export const FACEBOOK_CODETOUR_URL = 'https://www.facebook.com/groups/codetoconnect'

export const UPLOAD_RELATIVE_PATH = {
  AVATAR: 'avatar',
  NEWS: 'news',
  VIDEO: 'video',
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
export const UPLOAD_ACCEPTED_TYPE = {
  PHOTO: '.png,.jpeg,.jpg,.gif',
}

export const AWS_S3_STATIC_URL = 'https://<bucketName>.s3.amazonaws.com'

export const SVG_DETECTOR_RE = /src=".+\.svg\s*"/
export const LEADER_BOARD_AGGREGATION_TYPE = {
  ANNUAL: 'annual',
  CUMULATIVE: 'cumulative',
}
export const DEFAULT_AVT = 'default.png'
export const DEFAULT_THUMBNAIL = 'default-thumbnail.png'
export const PRE_CHECKING_MILISECOND_INTERVAL_BEFORE_CONTEST_BEGIN = 24 * 60 * 60 * 1000

/**
 ----- sync between BE & FE: End -----
 */

export const ENV_LOCAL = 'local'
export const TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const TIME_FORMAT_MONTH_YEAR = 'MM/YYYY'
export const TIME_FORMAT_BIRTH_DATE = 'YYYY'
export const OTHER_SCHOOL = 'other'
export const CONTEST_NAME_BREAKPOINT_LENGTH_19 = 19
// export const FLASHLIGHT_INTERVAL = 9000 // 9 sec
export const MAX_BADGE_COUNT_IN_BELL_NOTIFICATION = 99
export const MAX_SHOWN_NOTIFICATIONS = 200
export const INTERVAL_SYNC_COUNT_UNREAD_NOTIFICATION = 60 * 1000 // 60 seconds
export const MAX_WAITING_INTERVAL_BEFORE_VISITING_ROOM = 10 // 10 seconds

export const SNS_LOGIN_CONFIG = {
  FACEBOOK_APP_ID: process.env.REACT_APP_LOGIN_FACEBOOK_APP_ID,
  GOOGLE_APP_ID: process.env.REACT_APP_LOGIN_GOOGLE_APP_ID,
  LINKEDIN_APP_ID: process.env.REACT_APP_LOGIN_LINKEDIN_APP_ID,
  LINKEDIN_REDIRECT_URL: process.env.REACT_APP_LOGIN_LINKEDIN_REDIRECT_URL,
  ZALO_LOGIN_URL: `https://oauth.zaloapp.com/v4/permission?app_id=${process.env.REACT_APP_LOGIN_ZALO_APP_ID}&redirect_uri=${process.env.REACT_APP_LOGIN_ZALO_REDIRECT_URL}&state=123`,
  MICROSOFT_APP_ID: process.env.REACT_APP_LOGIN_MICROSOFT_APP_ID,
  MICROSOFT_REDIRECT_URL: process.env.REACT_APP_LOGIN_MICROSOFT_REDIRECT_URL,
}

export const GOOGLE_CALENDAR_API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_V2_INVISIBLE_SITE_KEY

export const CONTEST_STATUS = {
  PLANNED: 'planned',
  ONGOING: 'ongoing',
  PAST: 'past',
}

export const CONTEST_SOURCE_TYPE = {
  BIG_O: 'BIG_O',
  VNG: 'VNG',
  VNOI: 'VNOI',
}

const contestStatusText = {}
contestStatusText[CONTEST_STATUS.PAST] = TEXT_UI_CONTEST.PAST
contestStatusText[CONTEST_STATUS.ONGOING] = TEXT_UI_CONTEST.ONGOING
contestStatusText[CONTEST_STATUS.PLANNED] = TEXT_UI_CONTEST.PLANNED
export const CONTEST_STATUS_TEXT = contestStatusText

export const REPLY_TO_COMMENT_CONTENT_FORMAT = `<div><a href="${FE_ROUTE.USER.PROFILE}/<repliedToUserId>" data-for='tooltip-user-<repliedToUserId>' data-tip='<repliedToUserId>' class="replied-username"><repliedToUsername></a>:&nbsp;<repliedToContent></div>`
// export const NEWS_REPLY_TO_COMMENT_CONTENT_EXTRACT_RE_FORMAT = new RegExp(
//   '<div><a.+>.+<\\/a>:&nbsp;(.+)<\\/div>',
//   'g',
// )

export const USER_PROFILE_TAB_KEY = {
  INFORMATION: 'information',
  EDUCATION_WORK_EXPERIENCE: 'education_experience',
  WORK_EXPERIENCE: 'experience',
  EDUCATION: 'education',
  SOCIAL: 'social',
}

export const APP_MODULES = [
  {
    label: TEXT_UI_LAYOUT.HEADER.CONTEST,
    link: FE_ROUTE.CONTEST.HOME,
  },
  // // todo: will be enabled when release Code Tour platform
  // {
  //   label: TEXT_UI_LAYOUT.HEADER.TRAINING,
  //   link: FE_ROUTE.TRAINING.HOME,
  // },
  {
    label: TEXT_UI_LAYOUT.HEADER.NEWS,
    link: FE_ROUTE.NEWS.HOME,
  },
  // TODO: Re-open when certificate is provided
  // {
  //   label: TEXT_UI_LAYOUT.HEADER.FORUM,
  //   link: FE_ROUTE.FORUM.HOME,
  // },
  {
    label: TEXT_UI_LAYOUT.HEADER.LEADER_BOARD,
    link: FE_ROUTE.LEADER_BOARD.HOME,
  },
]
