import { notification } from 'antd'
import shortid from 'shortid'
import slugify from 'slugify'
import { store } from 'redux/store'

export default class MiscUtils {
  static generateId() {
    return shortid.generate()
  }

  static generateRandomInteger(maxInt) {
    return Math.floor(Math.random() * maxInt)
  }

  static slugify(text) {
    return slugify(text.toLowerCase())
  }

  static showComingSoon(desc) {
    notification.warning({
      message: 'Coming soon!',
      description: desc || 'We are on it now :)',
      placement: 'bottomRight',
    })
  }

  static dispatchReduxAction(func) {
    store.dispatch(func)
  }

  static safePromise(promise) {
    return promise.then((data) => [data, null]).catch((error) => [null, error.message])
  }

  static generateContestNameClass(name) {
    const len = name.length
    if (len > 100) return 'typography-h3-bold'
    if (len <= 100 && len > 50) return 'typography-h3-bold'
    if (len <= 50 && len > 19) return 'typography-subtitle-bold-02'
    return 'typography-subtitle-bold-01'
  }
}
