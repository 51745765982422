import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class ForumService {
  static getCategories() {
    const url = URLUtils.buildBeURL(BE_ROUTE.FORUM.BASE, BE_ROUTE.FORUM.PATH.CATEGORY)
    return HttpService.get(url)
  }

  static getForumDetail(forumId) {
    const url = URLUtils.buildBeURL(BE_ROUTE.FORUM.BASE, `${BE_ROUTE.FORUM.PATH.FORUM}/${forumId}`)
    return HttpService.get(url)
  }

  static getTopicDetail(topicId) {
    const url = URLUtils.buildBeURL(BE_ROUTE.FORUM.BASE, `${BE_ROUTE.FORUM.PATH.TOPIC}/${topicId}`)
    return HttpService.get(url)
  }

  static getTopicsByForum(search, forumId) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.FORUM}/${forumId}${BE_ROUTE.FORUM.PATH.TOPIC}`,
    )
    return HttpService.get(url, search)
  }

  static searchTopic(search) {
    const url = URLUtils.buildBeURL(BE_ROUTE.FORUM.BASE, BE_ROUTE.FORUM.PATH.TOPIC)
    return HttpService.get(url, search)
  }

  static getPostsByTopic(search, topicId) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.TOPIC}/${topicId}${BE_ROUTE.FORUM.PATH.POST}`,
    )
    return HttpService.get(url, search)
  }

  static postPost(topicId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.TOPIC}/${topicId}${BE_ROUTE.FORUM.PATH.POST}`,
    )
    return HttpService.post(url, data)
  }

  static createTopic(forumId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.FORUM}/${forumId}${BE_ROUTE.FORUM.PATH.TOPIC}`,
    )
    return HttpService.post(url, data)
  }

  static postPostLikeDislike(postId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.POST}/${postId}/like`,
    )
    return HttpService.post(url, data)
  }

  static deletePostLikeDislike(postId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.POST}/${postId}/like`,
    )
    return HttpService.delete(url, data)
  }

  static deletePost(postId) {
    const url = URLUtils.buildBeURL(BE_ROUTE.FORUM.BASE, `${BE_ROUTE.FORUM.PATH.POST}/${postId}`)
    return HttpService.delete(url)
  }

  static reportPost(postId, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.FORUM.BASE,
      `${BE_ROUTE.FORUM.PATH.POST}/${postId}/report`,
    )
    return HttpService.post(url, data)
  }
}
