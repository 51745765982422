import React from 'react'
import classnames from 'classnames'
import styles from '../style.module.scss'

export default ({ children }) => (
  <div className="full-width">
    <div className={classnames(styles.banner, styles['banner-bg-white'])}>
      <div className={classnames('center-content', styles['banner-content'])}>{children}</div>
    </div>
  </div>
)
