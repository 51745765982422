import _ from 'lodash'
import { parse } from 'node-html-parser'

export default class StringUtils {
  static isString(obj) {
    return typeof obj === 'string' || obj instanceof String
  }

  static trim(txt, char) {
    return char ? _.trim(txt, char) : _.trim(txt)
  }

  static stripCharsAtBeginning(txt, chars) {
    return txt.indexOf(chars) === 0 ? txt.slice(chars.length) : txt
  }

  static replaceAll(txt, pattern, replacement) {
    return _.replace(txt, new RegExp(pattern, 'g'), replacement)
  }

  static extractAll(txt, rePattern) {
    return rePattern.exec(txt)
  }

  static extractFirst(txt, rePattern) {
    const groups = this.extractAll(txt, rePattern)
    return groups ? groups[1] : null
  }

  static getValueByLocale(txt, locale) {
    try {
      return _.get(JSON.parse(txt), locale, '')
    } catch (err) {
      return txt
    }
  }

  static removeNodeInHTMLDOMText(htmlText, nodeSelector) {
    try {
      const root = parse(htmlText)
      root.removeChild(root.querySelector(nodeSelector))
      return root.toString()
    } catch (e) {
      return htmlText
    }
  }

  static removeQuotedContentInForumPost(content) {
    return this.removeNodeInHTMLDOMText(content, '.custom-blockquote-section')
  }

  static stripRootParagraphHTMLTag(txt) {
    let content = txt
    if (content.startsWith('<p>')) content = content.slice(3, content.length)
    if (content.endsWith('</p>')) content = content.slice(0, content.length - 4)
    return content
  }
}
