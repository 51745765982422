import React from 'react'
import styles from './style.module.scss'
import WhiteBackground from '../WhiteBackground'

export default ({ leftLogo, rightLogo }) => (
  <WhiteBackground>
    <div className={styles['banner-content']}>
      <div className={styles.left}>
        <img src={leftLogo} alt="" />
      </div>
      <div className={styles.right}>
        <img src={rightLogo} alt="" />
      </div>
    </div>
  </WhiteBackground>
)
