import React from 'react'
import classnames from 'classnames'
import closeIcon from 'assets/image/icon/close.svg'
import styles from './style.module.scss'

export default ({ news, action, onClose, style }) => (
  <div className={classnames('full-width', styles['highlight-notification'])} style={style}>
    <div className={classnames('center-content', styles.news)}>
      <div className={styles.newsText}>{news}</div>
      <div className="display-flex">
        {action}
        <div className={styles.close} onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </div>
      </div>
    </div>
  </div>
)
