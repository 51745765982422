import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class LeaderboardService {
  static getAnnuals() {
    const url = URLUtils.buildBeURL(BE_ROUTE.LEADERBOARD.BASE, BE_ROUTE.LEADERBOARD.PATH.ANNUAL)
    return HttpService.get(url)
  }

  static getAnnualDetail(data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.LEADERBOARD.BASE,
      BE_ROUTE.LEADERBOARD.PATH.ANNUAL_DETAIL,
    )
    return HttpService.get(url, data)
  }

  static getCumulative(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.LEADERBOARD.BASE, BE_ROUTE.LEADERBOARD.PATH.CUMULATIVE)
    return HttpService.get(url, data)
  }
}
