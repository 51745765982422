import React from 'react'
import { Card } from 'antd'
import styles from './style.module.scss'

export default ({ children }) => (
  <div className="full-width">
    <div className="center-content-body">
      <Card className={styles.content}>{children}</Card>
    </div>
  </div>
)
