import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { useCurrentBreakpointName } from 'react-socks'
import { Row, Col, Layout as AntLayout } from 'antd'
import { DesktopOnly, MobileOrTabletOnly } from 'components'
import logoCodeTour from 'assets/image/logo-footer.svg'
import logoVNG from 'assets/image/logo-brand/logo-vng.png'
import iconFacebook from 'assets/image/logo-brand/logo-facebook.png'
import iconInstagram from 'assets/image/logo-brand/logo-instagram.png'
import iconLinkedin from 'assets/image/logo-brand/logo-linkedin-3.png'
import youtubeIcon from 'assets/image/logo-brand/logo-youtube.png'
import { TEXT_UI_LAYOUT, FE_ROUTE, APP_MODULES } from 'config'
import { DisplayUtils, MiscUtils } from 'utils'
import styles from './style.module.scss'

const AntFooter = AntLayout.Footer
const { FACEBOOK, INSTAGRAM, LINKEDIN, YOUTUBE } = FE_ROUTE.MISC.VNG_CHANNEL

const linkConfig = [
  {
    type: 'title',
    label: TEXT_UI_LAYOUT.FOOTER.LINKS,
  },
]
APP_MODULES.forEach((module) => {
  linkConfig.push({
    type: 'body',
    ...module,
  })
})

const infoConfig = [
  {
    type: 'title',
    label: TEXT_UI_LAYOUT.FOOTER.INFORMATION,
  },
  {
    type: 'body',
    label: TEXT_UI_LAYOUT.FOOTER.ABOUT_US,
    link: FE_ROUTE.MISC.ABOUT_US,
  },
  {
    type: 'body',
    label: TEXT_UI_LAYOUT.FOOTER.FAQ,
    link: FE_ROUTE.MISC.FAQ,
  },
  {
    type: 'body',
    label: TEXT_UI_LAYOUT.FOOTER.TOS,
    link: FE_ROUTE.MISC.TOS,
  },
  {
    type: 'body',
    label: TEXT_UI_LAYOUT.FOOTER.PRIVACY,
    link: FE_ROUTE.MISC.PRIVACY,
  },
]

const generateFooterContent = (content) =>
  content.map((item) => {
    if (item.type === 'title') {
      return (
        <p
          key={MiscUtils.generateId()}
          className={classnames(styles['row-title'], 'typography-small')}
        >
          {item.label}
        </p>
      )
    }
    return (
      <a key={MiscUtils.generateId()} href={item.link}>
        <p className={classnames(styles['row-link'], 'typography-small')}>{item.label}</p>
      </a>
    )
  })

export default () => {
  const isMobileOrTablet = DisplayUtils.isMobileOrTablet(useCurrentBreakpointName())
  return (
    <AntFooter className={classnames(styles.footer, 'full-width text-center')}>
      <Row id="footerContentId" className={classnames(styles.content, 'center-content')}>
        <Col span={24} lg={5} className={styles['col-logo']}>
          <div>
            <Link to={FE_ROUTE.DEFAULT_ROUTE} className="display-block">
              <img src={logoCodeTour} alt="..." className={styles.logo} />
            </Link>
          </div>
          <div className={styles['list-social-button']}>
            <a href={FACEBOOK} target="_blank" rel="noreferrer">
              <img src={iconFacebook} alt="Facebook" />
            </a>
            <a href={INSTAGRAM} target="_blank" rel="noreferrer">
              <img src={iconInstagram} alt="Instagram" />
            </a>
            <a href={LINKEDIN} target="_blank" rel="noreferrer">
              <img src={iconLinkedin} alt="LinkedIn" />
            </a>
            <a href={YOUTUBE} target="_blank" rel="noreferrer">
              <img src={youtubeIcon} alt="Youtube" />
            </a>
          </div>
        </Col>

        <Col span={6} lg={8} className={styles.linkList}>
          <DesktopOnly>{generateFooterContent(linkConfig)}</DesktopOnly>
        </Col>
        <Col span={6} lg={7} className={styles.linkList}>
          <DesktopOnly>{generateFooterContent(infoConfig)}</DesktopOnly>
        </Col>

        <Col
          span={24}
          lg={4}
          className={classnames(styles.copyright, { 'm-t-15': isMobileOrTablet })}
        >
          <DesktopOnly>
            <div>
              <img src={logoVNG} alt="VNG" />
            </div>
            <div className={classnames(styles['row-description'], 'typography-small-regular')}>
              {TEXT_UI_LAYOUT.FOOTER.COPYRIGHT}
            </div>
          </DesktopOnly>
          <MobileOrTabletOnly>
            <span
              className={classnames(styles['row-description'], 'typography-small-regular m-r-5')}
            >
              {TEXT_UI_LAYOUT.FOOTER.COPYRIGHT}
            </span>
            <img src={logoVNG} alt="VNG" />
          </MobileOrTabletOnly>
        </Col>
      </Row>
    </AntFooter>
  )
}
