import { all, put, takeEvery } from 'redux-saga/effects'
import { ACTION } from './actions'

function* SET_COUNT_UNREAD_NOTIFICATION({ data: { countUnread } }) {
  yield put({
    type: ACTION.SET_COUNT_UNREAD_NOTIFICATION,
    data: { countUnread },
  })
}

export default function* LocaleSaga() {
  yield all([
    takeEvery(ACTION.SET_COUNT_UNREAD_NOTIFICATION_TRIGGER, SET_COUNT_UNREAD_NOTIFICATION),
  ])
}
