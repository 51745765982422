import React from 'react'
import iconNext from 'assets/image/icon/arrow-next.svg'
import iconPrevious from 'assets/image/icon/arrow-previous.svg'
import styles from './style.module.scss'

export default ({ totalPage, currentPage, fetchDataFunction, callback }) => {
  const previousClick = () => {
    if (fetchDataFunction && currentPage > 1) fetchDataFunction(currentPage - 1)
    if (callback) callback()
  }

  const nextClick = () => {
    if (fetchDataFunction && currentPage < totalPage) fetchDataFunction(currentPage + 1)
    if (callback) callback()
  }

  return totalPage && totalPage > 1 ? (
    <div className={styles.content}>
      <span onClick={previousClick}>
        <img alt="previous" src={iconPrevious} />
      </span>
      <span>{currentPage}</span>/<span>{totalPage}</span>
      <span onClick={nextClick}>
        <img alt="next" src={iconNext} />
      </span>
    </div>
  ) : null
}
