export default class FileUtils {
  static saveFile(data, filename, options = {}) {
    const url = window.URL.createObjectURL(new Blob([data]), options)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()

    a.remove()
    window.URL.revokeObjectURL(url)
  }
}
