import React from 'react'
import classnames from 'classnames'
import { Breadcrumb } from 'antd'
import { MiscUtils } from 'utils'
import styles from './style.module.scss'

const { Item } = Breadcrumb
export default ({ items }) => (
  <div className="full-width">
    <Breadcrumb className={classnames('center-content', styles.breadcrumb)} separator=">">
      {[...items, { title: ' ' }].map(
        (
          { url, title }, // in order to have the last ">"
        ) =>
          url ? (
            <Item href={url} key={MiscUtils.generateId()}>
              {title}
            </Item>
          ) : (
            <Item key={MiscUtils.generateId()}>{title}</Item>
          ),
      )}
    </Breadcrumb>
  </div>
)
