import React from 'react'
import { useCurrentBreakpointName } from 'react-socks'
import { SNS_LOGIN_CONFIG, SNS_NAME } from 'config'
import { DisplayUtils } from 'utils'
import logoFacebook from 'assets/image/logo-brand/logo-facebook.png'
import SocialButton from '../index'

export default ({ handleSocialLogin, handleSocialLoginFailure, disabled, isShowName }) => {
  const isMobile = DisplayUtils.isMobile(useCurrentBreakpointName())
  return (
    <SocialButton
      provider={SNS_NAME.FACEBOOK.toLowerCase()}
      appId={SNS_LOGIN_CONFIG.FACEBOOK_APP_ID}
      onLoginSuccess={(data) => handleSocialLogin(SNS_NAME.FACEBOOK, data)}
      onLoginFailure={handleSocialLoginFailure}
      providerName={isShowName || !isMobile ? SNS_NAME.FACEBOOK : ''}
      logo={logoFacebook}
      disabled={disabled}
    />
  )
}
