import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import classnames from 'classnames'
import { Input } from 'antd'
import { CustomModal, LoadingIndicator } from 'components'
import {
  I18NFormatMessage,
  RECAPTCHA_SITE_KEY,
  TEXT_BUTTON,
  TEXT_NOTIFICATION_SUCCESS,
  TEXT_UI_AUTH,
  TEXT_UI_USER_PROFILE,
  TEXT_VALIDATOR,
} from 'config'
import { DisplayUtils, ErrorHandlerUtils, ReCaptchaUtils } from 'utils'

export default ({ isShown, onClose, currentUserEmail, ModelService, locale }) => {
  const intl = useIntl()
  const [modalVisible, setModalVisible] = useState(isShown)
  const [usernameEmail, setUsernameEmail] = useState(currentUserEmail)
  const [usernameEmailValidation, setUsernameEmailValidation] = useState(null)
  const [isLoadingShown, setIsLoadingShown] = useState(false)
  const recaptchaRef = useRef()

  const okButtonProps = {
    disabled: !usernameEmail,
    className: classnames({ 'ant-btn-primary-disabled': !usernameEmail }),
  }

  useEffect(() => {
    setModalVisible(isShown)
  }, [isShown])

  const closeModal = () => {
    setModalVisible(false)
    onClose()
  }

  const onOK = async () => {
    if (usernameEmail) {
      const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
      setIsLoadingShown(true)
      ModelService.resendRegistrationEmail({
        username_email: usernameEmail,
        locale,
        recaptchaToken,
      })
        .then(() => {
          setIsLoadingShown(false)
          ReCaptchaUtils.reset(recaptchaRef)
          setUsernameEmail('')
          setUsernameEmailValidation(null)
          closeModal()
          DisplayUtils.showNotification(
            I18NFormatMessage(intl, TEXT_NOTIFICATION_SUCCESS.EMAIL_SENT_SUCCESS),
          )
        })
        .catch((error) => {
          setIsLoadingShown(false)
          ReCaptchaUtils.reset(recaptchaRef)
          ErrorHandlerUtils.http(error, intl)
        })
    } else setUsernameEmailValidation(I18NFormatMessage(intl, TEXT_VALIDATOR.REQUIRED))
  }

  return (
    <CustomModal
      title={TEXT_UI_AUTH.REGISTER.RESEND_EMAIL}
      visible={modalVisible}
      disableClosable
      onCancel={closeModal}
      onOK={onOK}
      okText={TEXT_BUTTON.RESEND}
      okButtonProps={okButtonProps}
    >
      <LoadingIndicator isHidden={!isLoadingShown}>
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size="invisible" ref={recaptchaRef} />
        <div className="m-t-20 m-b-20 typography-small">
          <div>{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_TITLE}</div>
          <div>1.&nbsp;{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_1}</div>
          <div>2.&nbsp;{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_2}</div>
          <div>3.&nbsp;{TEXT_UI_USER_PROFILE.CHANGE_PASSWORD.GUIDELINE_3}</div>
        </div>
        <div className="mb-30">
          <div>
            <span className="color-red">*</span>{' '}
            <span className="typography-menu-subtext">{TEXT_UI_AUTH.LOGIN.USERNAME_EMAIL}</span>
          </div>
          <Input
            type="text"
            name="username"
            value={usernameEmail}
            onChange={(e) => setUsernameEmail(e.target.value)}
            disabled={currentUserEmail}
          />
          {usernameEmailValidation && <div className="color-red">{usernameEmailValidation}</div>}
        </div>
      </LoadingIndicator>
    </CustomModal>
  )
}
