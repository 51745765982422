import { URLUtils } from 'utils'
import { BE_ROUTE } from 'config'
import HttpService from './HttpService'

export default class NotificationService {
  static list(data) {
    const url = URLUtils.buildBeURL(BE_ROUTE.NOTIFICATION.BASE)
    return HttpService.get(url, data)
  }

  static view(id, data) {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NOTIFICATION.BASE,
      BE_ROUTE.NOTIFICATION.PATH.VIEW.replace(':id', id),
    )
    return HttpService.post(url, data)
  }

  static countUnread() {
    const url = URLUtils.buildBeURL(
      BE_ROUTE.NOTIFICATION.BASE,
      BE_ROUTE.NOTIFICATION.PATH.COUNT_UNREAD,
    )
    return HttpService.get(url)
  }
}
