import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Popover, Tooltip, Comment, Divider, Skeleton, Typography, Badge } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import classnames from 'classnames'
import { BsBell } from 'react-icons/bs'
import {
  APP_TYPE_CODE,
  MAX_BADGE_COUNT_IN_BELL_NOTIFICATION,
  MAX_SHOWN_NOTIFICATIONS,
  INTERVAL_SYNC_COUNT_UNREAD_NOTIFICATION,
  TEXT_BUTTON,
  TEXT_UI_LAYOUT,
  FE_ROUTE,
} from 'config'
import { setCountUnreadNotificationAction } from 'redux/notification/actions'
import { LoadingAtBottomIndicator } from 'components'
import {
  DateTimeUtils,
  DisplayUtils,
  ErrorHandlerUtils,
  MiscUtils,
  StringUtils,
  URLUtils,
} from 'utils'
import { NotificationService } from 'services'
import iconComment from 'assets/image/icon/comment-filled.svg'
import iconLike from 'assets/image/icon/like.png'
import iconDisLike from 'assets/image/icon/dislike.png'
import styles from './style.module.scss'
import './style.overwrite.scss'

const { Paragraph } = Typography
const ACTION_ICON = {
  [APP_TYPE_CODE.ACTION_LIKE]: iconLike,
  [APP_TYPE_CODE.ACTION_DISLIKE]: iconDisLike,
  [APP_TYPE_CODE.ACTION_POST]: iconComment,
  [APP_TYPE_CODE.ACTION_REPLY]: iconComment,
}
const FILTERING = {
  ALL: 'all',
  UNREAD: 'unread',
}
const BUTTONS = [
  { key: FILTERING.ALL, text: TEXT_UI_LAYOUT.NOTIFICATION.ALL },
  { key: FILTERING.UNREAD, text: TEXT_UI_LAYOUT.NOTIFICATION.UNREAD },
]

const ComponentView = ({ currentUser, locale, countUnread }) => {
  const intl = useIntl()
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const [isLoadingShown, setIsLoadingShown] = useState(false)
  const [notifications, setNotifications] = useState(null)
  const [filter, setFilter] = useState(FILTERING.ALL)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (currentUser && URLUtils.getPathnameInURL() !== FE_ROUTE.ERROR.FORBIDDEN_LOGOUT) {
      const jobCountUnread = () => {
        NotificationService.countUnread()
          .then(({ count }) => {
            MiscUtils.dispatchReduxAction(setCountUnreadNotificationAction(count))
          })
          .catch((error) => {
            ErrorHandlerUtils.http(error, intl)
          })
      }
      jobCountUnread()
      setInterval(() => {
        jobCountUnread()
      }, INTERVAL_SYNC_COUNT_UNREAD_NOTIFICATION)
    }
  }, [currentUser])

  const bellClick = () => {
    setIsShowTooltip(false)
    if (!notifications) fetchData()
  }

  const fetchData = (filterValue = filter, currentPage = page) => {
    // setFilter(filterValue)
    const filters = { page: currentPage }
    if (filterValue === FILTERING.UNREAD) filters.isUnread = true
    setIsLoadingShown(true)
    NotificationService.list(filters)
      .then(({ currPage, pageSize, nextPage, results }) => {
        setIsLoadingShown(false)
        setPage(currPage)
        // setHasMore(nextPage !== null)
        setHasMore(nextPage !== null && currPage * pageSize < MAX_SHOWN_NOTIFICATIONS)
        setNotifications(currPage === 1 ? results : [...notifications, ...results])
      })
      .catch((error) => {
        setIsLoadingShown(false)
        ErrorHandlerUtils.http(error)
      })
  }

  const selectFilter = (value) => {
    setFilter(value)
    setPage(1)
    setNotifications([])
    fetchData(value, 1)
  }

  const scrollToNextPage = () => {
    setPage(page + 1)
    fetchData(filter, page + 1)
  }

  return (
    <Popover
      id="notificationsPopover"
      trigger="click"
      placement="bottom"
      getPopupContainer={() => document.getElementById('headerContentId')}
      title={<div className="typography-main-regular">{TEXT_UI_LAYOUT.NOTIFICATION.TITLE}</div>}
      content={
        <div className={styles['popover-content']}>
          <div className={styles.buttons}>
            {BUTTONS.map(({ key, text }) => (
              <span
                key={MiscUtils.generateId()}
                className={classnames({ 'text-button-current-selected': filter === key })}
                onClick={() => selectFilter(key)}
              >
                {text}
              </span>
            ))}
            {/* <span className="float-right"> */}
            {/*  <a href={FE_ROUTE.USER.INFORMATION} className="color-black text-as-hyperlink"> */}
            {/*    {TEXT_BUTTON.VIEW_MORE} */}
            {/*  </a> */}
            {/* </span> */}
          </div>
          <Divider className={styles.divider} />
          <LoadingAtBottomIndicator isHidden={!isLoadingShown}>
            {!notifications && <div></div>}
            {notifications && notifications.length > 0 && (
              <div className={styles.notifications} id="scrollableNotificationsDiv">
                <InfiniteScroll
                  next={scrollToNextPage}
                  dataLength={notifications.length}
                  hasMore={hasMore}
                  loader={
                    <div>
                      <Skeleton avatar paragraph={{ rows: 1 }} active />
                      <Skeleton avatar paragraph={{ rows: 1 }} active />
                    </div>
                  }
                  // endMessage={
                  //   <div className="ta-center">
                  //     <Divider className={styles.divider} />
                  //     <a href={FE_ROUTE.USER.INFORMATION} className="color-black text-as-hyperlink">
                  //       {TEXT_BUTTON.VIEW_MORE}
                  //     </a>
                  //   </div>
                  // }
                  scrollableTarget="scrollableNotificationsDiv"
                >
                  {notifications.map(
                    ({
                      createdAt,
                      creator: {
                        username,
                        userProfile: { avatar },
                      },
                      content,
                      actionTypeCode,
                      targetTypeCode,
                      isRead,
                      url,
                    }) => (
                      <span
                        onClick={() => URLUtils.moveToURL(url)}
                        key={MiscUtils.generateId()}
                        className={styles['notification-link']}
                      >
                        <Comment
                          actions={[
                            <span className={classnames({ 'color-primary bold': !isRead })}>
                              {DateTimeUtils.fromNow(createdAt, locale)}
                            </span>,
                          ]}
                          content={
                            <div className={styles['content-with-status']}>
                              {/* suffix: `"` : always append to content even if it is short */}
                              <Paragraph
                                className="typography-menu-subtext m-b-0"
                                ellipsis={{ rows: 3 }}
                              >
                                {[
                                  APP_TYPE_CODE.NEWS,
                                  APP_TYPE_CODE.NEWS_COMMENT,
                                  APP_TYPE_CODE.NEWS_REPLY_TO_COMMENT,
                                ].includes(targetTypeCode) && (
                                  <strong>[{TEXT_UI_LAYOUT.HEADER.NEWS}]&nbsp;</strong>
                                )}
                                {[APP_TYPE_CODE.FORUM_TOPIC, APP_TYPE_CODE.FORUM_POST].includes(
                                  targetTypeCode,
                                ) && <strong>[{TEXT_UI_LAYOUT.HEADER.FORUM}]&nbsp;</strong>}
                                <strong>{username}</strong>&nbsp;
                                {DisplayUtils.formatNotificationContent(intl, {
                                  actionTypeCode,
                                  targetTypeCode,
                                })}
                                {content && (
                                  <span>
                                    :{' '}
                                    <i>
                                      {StringUtils.stripCharsAtBeginning(
                                        content,
                                        `${currentUser.username}:`,
                                      )}
                                    </i>
                                  </span>
                                )}
                              </Paragraph>
                              {!isRead && <div className={styles['unread-status']} />}
                            </div>
                          }
                          avatar={
                            <div className={styles['avatar-with-action']}>
                              <img
                                src={URLUtils.buildAvatarURL(avatar)}
                                className={styles.avatar}
                                alt=""
                              />
                              {actionTypeCode in ACTION_ICON && (
                                <img
                                  src={ACTION_ICON[actionTypeCode]}
                                  className={styles.action}
                                  alt=""
                                />
                              )}
                            </div>
                          }
                        />
                      </span>
                    ),
                  )}
                </InfiniteScroll>
              </div>
            )}
            {!isLoadingShown && notifications && notifications.length === 0 && (
              <div className="p-l-15">{TEXT_UI_LAYOUT.NOTIFICATION.NO_DATA}</div>
            )}
          </LoadingAtBottomIndicator>
        </div>
      }
    >
      <Tooltip visible={isShowTooltip} placement="bottom" title={TEXT_UI_LAYOUT.NOTIFICATION.TITLE}>
        <div className={styles.bell}>
          <BsBell
            onMouseEnter={() => setIsShowTooltip(true)}
            onMouseLeave={() => setIsShowTooltip(false)}
            onClick={bellClick}
          />
          <Badge
            className={classnames({
              [styles['badge-one-digit']]: countUnread <= 10,
              [styles['badge-medium']]:
                countUnread > 10 && countUnread <= MAX_BADGE_COUNT_IN_BELL_NOTIFICATION,
              [styles['badge-over-maximum']]: countUnread > MAX_BADGE_COUNT_IN_BELL_NOTIFICATION,
            })}
            count={countUnread}
            overflowCount={MAX_BADGE_COUNT_IN_BELL_NOTIFICATION}
          />
        </div>
      </Tooltip>
    </Popover>
  )
}

const mapStateToProps = ({
  authReducer: { currentUser },
  localeReducer: { locale },
  notificationReducer: { countUnread },
}) => ({
  currentUser,
  locale,
  countUnread,
})
export default connect(mapStateToProps)(ComponentView)
