import React from 'react'
import iconFacebook from 'assets/image/logo-brand/logo-facebook.svg'
import iconTwitter from 'assets/image/logo-brand/logo-twitter.svg'
import iconLinkedin from 'assets/image/logo-brand/logo-linkedin-2.svg'
import styles from './style.module.scss'

const SOCIAL = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
}
export default ({ url, title }) => {
  const shareSocial = (type) => {
    const width = 570
    const height = 680

    let sharedURL = null
    if (type === SOCIAL.FACEBOOK) sharedURL = `https://www.facebook.com/sharer/sharer.php?u=${url}`
    else if (type === SOCIAL.LINKEDIN)
      sharedURL = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
    else if (type === SOCIAL.TWITTER) {
      const twitterTitle = `${title}: `
      sharedURL = `https://twitter.com/intent/tweet?text=${twitterTitle}&url=${url}`
    }
    if (sharedURL) {
      const x = window.innerWidth / 2 - width / 2
      const y = window.innerHeight / 2 - height / 2
      const params = `menubar=yes,toolbar=yes,resizable=yes,scrollbars=yes,height=${height},width=${width},left=${x},top=${y}`
      window.open(sharedURL, '', params)
    }
  }

  return (
    <div className={styles.content}>
      <span onClick={() => shareSocial(SOCIAL.FACEBOOK)}>
        <img alt="facebook" src={iconFacebook} />
      </span>
      <span onClick={() => shareSocial(SOCIAL.TWITTER)}>
        <img alt="twitter" src={iconTwitter} />
      </span>
      <span onClick={() => shareSocial(SOCIAL.LINKEDIN)}>
        <img alt="linkedin" src={iconLinkedin} />
      </span>
    </div>
  )
}
