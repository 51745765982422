import React from 'react'
import classnames from 'classnames'
import { Button } from 'antd'
import { TEXT_BUTTON } from 'config'

export default ({ onClick, text = TEXT_BUTTON.SAVE, isDisabled, className }) =>
  onClick ? (
    <Button
      type="primary"
      disabled={isDisabled}
      className={classnames(className, { 'ant-btn-primary-disabled': isDisabled })}
      onClick={onClick}
    >
      {text}
    </Button>
  ) : (
    <Button type="primary" htmlType="submit" className={className}>
      {text}
    </Button>
  )
