import React, { useState, useRef } from 'react'
import { injectIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import { useCurrentBreakpointName } from 'react-socks'
import { Upload, Image, Button } from 'antd'
import ImgCrop from 'antd-img-crop'
import { CameraOutlined, CloseCircleTwoTone } from '@ant-design/icons'
import { connect } from 'react-redux'
import { URLUtils, ErrorHandlerUtils, MiscUtils, DisplayUtils, ReCaptchaUtils } from 'utils'
import { updateCurrentUserAction } from 'redux/auth/actions'
import { UserService } from 'services'
import {
  DEFAULT_AVT,
  RECAPTCHA_SITE_KEY,
  TEXT_BUTTON,
  TEXT_UI_USER_PROFILE,
  UPLOAD_ACCEPTED_TYPE,
} from 'config'
import { CustomModal, DesktopOnly, LoadingIndicator } from 'components'
import styles from './style.module.scss'
import './style.overwrite.scss'

const CustomEditAvatar = ({ currentUser, locale, intl }) => {
  const [avatarChanging, setAvatarChanging] = useState(false)
  // const [fileList, setFileList] = useState([])
  const [uploadedAvatars, setUploadedAvatars] = useState([])
  const [loadingUploadedAvatars, setLoadingUploadedAvatars] = useState(false)
  const recaptchaRef = useRef()
  const isMobileOrTablet = DisplayUtils.isMobileOrTablet(useCurrentBreakpointName())

  const openChangeAvatarModal = () => {
    getUploadedAvatars()
    setAvatarChanging(true)
  }

  // after uploaded a new avatar, fetch uploaded avatars from server and update file list
  const onUploadNewAvatar = () => {
    getUploadedAvatars()
    // setFileList(newFileList)
  }

  // get uploaded avatars from server
  const getUploadedAvatars = () => {
    setLoadingUploadedAvatars(true)
    UserService.getUserAvatar()
      .then((resp) => {
        generateUploadedImg(resp)
        setLoadingUploadedAvatars(false)
      })
      .catch((error) => {
        ErrorHandlerUtils.http(error)
        setLoadingUploadedAvatars(false)
      })
  }

  // generate list uploaded avatar
  const generateUploadedImg = (data) => {
    data = data.map((item) => (
      <div className={styles['badge-avatar']} key={MiscUtils.generateId()}>
        <img
          className={styles['img-uploaded']}
          src={URLUtils.buildAvatarURL(item)}
          alt={item}
          onClick={() => handleSelectAvatar(item)}
          role="presentation"
        />
        {item !== DEFAULT_AVT && item !== currentUser.avatar && (
          <CloseCircleTwoTone twoToneColor="red" onClick={() => handleDeleteAvatar(item)} />
        )}
      </div>
    ))
    setUploadedAvatars(data)
  }

  // send name of avatar to server to delete
  const handleDeleteAvatar = async (name) => {
    const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
    setLoadingUploadedAvatars(true)
    UserService.deleteUserAvatar({ filename: name, recaptchaToken })
      .then((resp) => {
        setLoadingUploadedAvatars(false)
        ReCaptchaUtils.reset(recaptchaRef)
        getUploadedAvatars()
      })
      .catch((error) => {
        ReCaptchaUtils.reset(recaptchaRef)
        ErrorHandlerUtils.http(error)
        setLoadingUploadedAvatars(false)
      })
  }

  // set current selected image to be user avatar
  const handleSelectAvatar = async (name) => {
    const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
    setLoadingUploadedAvatars(true)
    UserService.setUserAvatar({ filename: name, recaptchaToken })
      .then(({ user }) => {
        ReCaptchaUtils.reset(recaptchaRef)
        MiscUtils.dispatchReduxAction(updateCurrentUserAction(user))
        setLoadingUploadedAvatars(false)
        setAvatarChanging(false)
      })
      .catch((error) => {
        ReCaptchaUtils.reset(recaptchaRef)
        ErrorHandlerUtils.http(error)
        setLoadingUploadedAvatars(false)
      })
  }

  // https://ant.design/components/upload/#components-upload-demo-upload-manually
  const processUpload = async (file) => {
    const recaptchaToken = await ReCaptchaUtils.getValue(recaptchaRef)
    setLoadingUploadedAvatars(true)
    const formData = new FormData()
    formData.append('file', file)
    await UserService.uploadUserAvatar(recaptchaToken, formData)
      .then((resp) => {
        onUploadNewAvatar()
        ReCaptchaUtils.reset(recaptchaRef)
        setLoadingUploadedAvatars(false)
      })
      .catch((error) => {
        ReCaptchaUtils.reset(recaptchaRef)
        setLoadingUploadedAvatars(false)
        ErrorHandlerUtils.http(error)
      })
    // always return false to prevent built-in func
    // because of above "async" to use recaptcha, we cannot return false here
    // we should inline-return
    // return false
  }

  return (
    <div className={styles.content}>
      {currentUser.avatar && (
        <div className={styles.avatar}>
          <Image
            alt="user avatar"
            className={styles['edit-avatar']}
            size="massive"
            src={URLUtils.buildAvatarURL(currentUser.avatar)}
            onClick={openChangeAvatarModal}
            preview={{
              visible: false,
              maskClassName: styles['masked-edit-avatar'],
              mask: <CameraOutlined />,
            }}
          />
          {isMobileOrTablet && (
            <>
              <span className="typography-main-regular color-primary">{currentUser.username}</span>
            </>
          )}
        </div>
      )}
      <DesktopOnly>
        <div className={styles.username}>
          <span className="typography-main-regular color-primary">{currentUser.username}</span>
        </div>
      </DesktopOnly>

      {/* change avatar modal */}
      <CustomModal
        visible={avatarChanging}
        onCancel={() => {
          setAvatarChanging(false)
        }}
        title={TEXT_UI_USER_PROFILE.AVATAR_MODAL.CHANGE}
        width={700}
        footer={null}
      >
        <LoadingIndicator isHidden={!loadingUploadedAvatars}>
          <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size="invisible" ref={recaptchaRef} />
          <div className={styles['upload-avatar-modal-content']}>
            <div className="ta-center">
              <ImgCrop rotate shape="round">
                <Upload
                  accept={UPLOAD_ACCEPTED_TYPE.PHOTO}
                  // must has 'action', if not it will make the request to current url in browser
                  // action={null}
                  withCredentials="true"
                  // fileList={fileList}
                  // onChange={onUploadNewAvatar}
                  maxCount={1}
                  multiple={false}
                  showUploadList={false}
                  // customize upload function with recaptcha
                  beforeUpload={(file) => {
                    processUpload(file)
                    return false
                  }}
                >
                  <Button type="primary">{TEXT_BUTTON.UPLOAD}</Button>
                </Upload>
              </ImgCrop>
            </div>
            <div className="typography-menu m-t-10">
              {TEXT_UI_USER_PROFILE.AVATAR_MODAL.UPLOADED_PHOTOS}
            </div>
            <div className={styles['avatar-list']}>{uploadedAvatars.map((item) => item)}</div>
            {/* todo: will enable after implementing "load more" paging */}
            {/* <Button id="load-more" disabled block> */}
            {/*  {TEXT_BUTTON.VIEW_MORE} */}
            {/* </Button> */}
          </div>
        </LoadingIndicator>
      </CustomModal>
    </div>
  )
}

const mapStateToProps = ({ authReducer: { currentUser }, localeReducer: { locale } }) => ({
  currentUser,
  locale,
})
export default injectIntl(connect(mapStateToProps)(CustomEditAvatar))
