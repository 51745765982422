import React from 'react'
import { useCurrentBreakpointName } from 'react-socks'
import classnames from 'classnames'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { DisplayUtils } from 'utils'
import { SNS_LOGIN_CONFIG, SNS_NAME } from 'config'
import logoLinkedin from 'assets/image/logo-brand/logo-linkedin.png'
import '../style.overwrite.scss'

export default ({ handleSocialLogin, handleSocialLoginFailure, disabled, isShowName }) => {
  const isMobile = DisplayUtils.isMobile(useCurrentBreakpointName())
  return (
    <LinkedIn
      clientId={SNS_LOGIN_CONFIG.LINKEDIN_APP_ID}
      onSuccess={(data) => handleSocialLogin(SNS_NAME.LINKEDIN, data)}
      onFailure={handleSocialLoginFailure}
      redirectUri={SNS_LOGIN_CONFIG.LINKEDIN_REDIRECT_URL}
      scope="r_emailaddress,r_liteprofile"
      className={classnames('ant-btn', 'linkedin', { 'social-disabled': disabled })}
      disabled={disabled}
    >
      <img src={logoLinkedin} alt="" />
      <span className="typography-small">{isShowName || !isMobile ? SNS_NAME.LINKEDIN : ''}</span>
    </LinkedIn>
  )
}
